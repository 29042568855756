import React from "react";
import { baseCDNUrl } from "../../utils/endpoints";

export default function History() {
  return (
    <section className="bg-pink-50 px-5 py-16 mx-auto">
      <div className="flex flex-col text-center w-full mb-10 md:mb-20">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          रामलीला का इतिहास
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
          पाटोन्दा की ऐतिहासिक 160 साल पुरानी धरोहर अंतर्राष्ट्रीय ढाई कड़ी की
          रामलीला
        </p>
      </div>
      <div className="relative md:grid md:grid-cols-2 my-[3%] px-4  md:px-32">
        <div className="flex justify-center">
          <img
            className="shadow-lg mb-5 md:mb-0 rounded-xl"
            src={baseCDNUrl + "imgs/img3.jpg"}
            width="400"
            height="600"
            alt="img"
          />
          <img
            className="hidden md:block absolute -bottom-10 left-[180px] rounded-xl shadow-lg"
            src={baseCDNUrl + "imgs/img2.jpg"}
            width="300"
            height="400"
            alt="img"
          />
        </div>
        <div className="">
          <h1 className="text-3xl md:text-5xl text-blue-900 font-medium mb-8">
            ढाई कड़ी की रामलीला
          </h1>
          <p className="text-gray-600 text-justify text-lg mb-8">
            लगभग 4000 जनसंख्या वाले पाटौन्दा गांव कोटा से 65 km की दूरी पर चम्बल
            की सहायक नदी कालीसिंध के किनारे पर ऊबड़ खाबड़ चट्टानों वाली जमीन पर
            स्थित है। आज से लगभग 160 वर्ष पूर्व एक दाधिच ब्राम्हण गुरु गणपतलाल
            जी दाधिच ने इस सांगीतिक रामलीला को प्रारंभ किया था। जिसे उन्होनें
            वाल्मीकि रामायण व तुलसीकृत रामचरिमानस के आधार पर लिखा था। यह रामलीला
            राजस्थानी भाषा की हाड़ौती उपभाषा में लिखी गई है। हाड़ौती पर ऊंचे स्वर
            वाली प्राचीन डिंगल भाषा का प्रभाव है। लगभग दो दर्जन हस्तलिखित
            पांडुलिपियों में लिपिबद्ध इस रामलीला की समस्त विषयवस्तु निहित है।
            संगीत इस गायन रामलीला का महत्वपूर्ण पक्ष है। लौक शैली ढाई कड़ी में
            लिखी गई चौपाइयां प्रमुख खड़ी बोली और रैला तर्ज में संगीतबद्ध किया गया
            है। यह रामलीला चैत्र शुक्ल पक्ष की पंचमी से वैशाख पंचमी तक पन्द्रह
            दिनों तक आयोजित की जाती है। गांव के सामान्य नागरिक ही इसमें गाने,
            बजाने, और अभिनय का कार्य करते है। यह कलाकर गांव के ही कृषक वर्ग के
            सामान्य नागरिक है, जो बिना किसी वेतन के भगवान के प्रति श्रद्धाभाव से
            अपना समय देते है। और इस रामलीला में अभिनय करते है। यह कलाकार कोई
            पेशेवर अभिनेता नही होते, सब अपनी मेहनत और अभ्यास तथा पूर्व कलाकारों
            से प्राप्त प्रशिक्षण से ही अभिनय करते है। पहले गायन के साथ संगीत के
            लिये सारंगी से मिलता जुलता एक वाद्ययंत्र चितारा का प्रयोग करते थे।अब
            चितारा की जगह वायलिन व हारमोनियम का उपयोग किया जाता है। प्रकाश के
            लिये पहले अलसी के तेल की मशालों का प्रयोग होता था। फिर बाद में
            मिट्टी के तेल के पेट्रोमैक्स उपयोग में लाने लगे, आजकल अब बिजली का
            प्रयोग होने लगा है। यह रामलीला गांव के अंदर बने लगभग 11 वी शताब्दी
            के प्राचीन लक्ष्मीनाथ जी के मंदिर के सामने खुले प्रांगण में होती है,
            अभिनय स्थल पर पक्की छत वाला रंगमंच बनाया गया है। जिसमें एक स्थायी
            पक्का सिंहासन, दरबार, वन, महल इत्यादि दिखाने के लिए परदों का प्रयोग
            किया गया है। पन्द्रह दिनों तक चलने वाली इस रामलीला में तीन सवारियां
            निकाली जाती है, इसमें रामलीला के पहले दिन श्रीगणेश जी को लीला में
            आमंत्रित करने के लिए, लीला के चौथे दिन अर्थात स्वयंवर वाले दिन
            रामचंद्र जी की बारात और लीला के छठे दिन अर्थात दशमी वाले दिन तीसरी
            सवारी निकाली जाती है। इस दिन रावण दहन किया जाता है।
          </p>
        </div>
      </div>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-20 mx-auto">
          <h1 className="text-3xl font-medium title-font text-gray-900 mb-12 text-center">
            रामलीला की ख्याति
          </h1>
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="block w-5 h-5 text-gray-400 mb-4"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-6">
                  क्वीसलेंड विश्वविद्यालय ( ऑस्ट्रेलिया ) की &quot; रुटलेज
                  हैंडबुक ऑफ फेस्टिवल ( 2018 ) &quot; पुस्तक में एक अध्याय
                  पाटौन्दा की ढाई कड़ी की रामलीला पर आधारित है । इस रामलीला पर
                  शोध डॉ. अनुकृति शर्मा द्वारा किया गया , उन्ही के प्रयासों से
                  इस रामलीला को कवीसलेंड विश्वविद्यालय ऑस्ट्रेलिया की पुस्तक में
                  स्थान मिल पाया।
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="block w-5 h-5 text-gray-400 mb-4"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-6">
                  सन 1950 में कोटा में आयोजित अखिल भारतीय साहित्य सम्मेलन में इस
                  रामलीला में प्रथम स्थान प्राप्त किया था । जिसमे पारितोषिक
                  स्वरूप दो किलो का चांदी का छत्र कोटा दरबार द्वारा प्रदान किया
                  गया।
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="block w-5 h-5 text-gray-400 mb-4"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-6">
                  इस रामलीला ने भारतीय रामायण मेला भोपाल , अंतरराष्ट्रीय रामायण
                  मेला चित्रकूट में दो बार प्रदर्शन किया था।
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="block w-5 h-5 text-gray-400 mb-4"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-6">
                  इस रामलीला ने अंतर्राष्ट्रीय रामायण मेला अयोध्या में एक बार
                  भारत सरकार के खर्च पर प्रदर्शन किया था।
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="block w-5 h-5 text-gray-400 mb-4"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-6">
                  यह रामलीला भारतवर्ष की उन तीन हिंदीभाषी अंतर्राष्ट्रीय
                  रामलीलाओं में से एक है जो खासतौर से गाकर की जाती है।
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="block w-5 h-5 text-gray-400 mb-4"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p className="leading-relaxed mb-6">
                  देश के कई राज्यों में इस ढाई कड़ी की रामलीला का मंचन किया जा
                  चुका है।
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container md:px-16 py-16 mx-auto">
        <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
          <img
            alt="team"
            className="flex-shrink-0 rounded-full w-48 h-48 object-cover object-center sm:mb-0 mb-4"
            src={baseCDNUrl + "persons/avatarplaceholder.webp"}
          />
          <div className="flex-grow sm:pl-8">
            <h2 className="title-font font-medium text-2xl text-gray-900 mb-1">
              श्री गुरु गणपतलाल जी दाधिच
            </h2>
            <h3 className="text-gray-500 mb-2">ढाई कड़ी की रामलीला के रचियता</h3>
            <p className="mb-4 text-justify">
              इस विश्वविख्यात ढाई कड़ी की रामलीला के रचयिता परम् पूजनीय
              साकेतधामवासी आदरणीय ग्राम गुरु श्री गणपतलाल जी दाधिच है। इनका जन्म
              पाटौन्दा ग्राम में हुआ। तथा काशी में इन्होंने शिक्षा प्राप्त की
              इनके द्वारा वाल्मीकि रामायण व तुलसीदास जी द्वारा रचित रामचरिमानस
              के आधार पर शुद्ध हाड़ौती भाषा मे यह रामलीला लिखी। जिसके अनुसार इस
              रामलीला का मंचन किया जाता है ।
            </p>
          </div>
        </div>
      </div>

      <div className="relative md:grid md:grid-cols-2 py-16 px-4 md:px-32">
        <div className="flex justify-center">
          <img
            className="mb-5 md:mb-0 rounded-xl"
            src={baseCDNUrl + "assets/silver_carved_metal_chatra.webp"}
            alt="img"
          />
        </div>
        <div className="flex flex-col justify-center md:pl-8">
          <h1 className="flex text-3xl md:text-5xl text-blue-900 font-medium mb-8">
            पारितोषिक स्वरूप दो किलो का चांदी का छत्र
          </h1>
          <p className="flex text-gray-600 text-justify text-lg mb-8">
            सन 1950 में कोटा में आयोजित अखिल भारतीय साहित्य सम्मेलन में इस
            रामलीला में प्रथम स्थान प्राप्त किया था। जिसमे पारितोषिक स्वरूप दो
            किलो का चांदी का छत्र कोटा दरबार द्वारा प्रदान किया गया।
          </p>
        </div>
      </div>

      <div className="relative md:grid md:grid-cols-2 py-16 px-4 md:px-32">
        <div className="flex flex-col justify-center pr-8">
          <h1 className="flex text-3xl md:text-5xl text-blue-900 font-medium mb-8">
            रामलीला का रंगमंच
          </h1>
          <p className="flex text-gray-600 text-justify text-lg mb-8">
            इस रामलीला का मंचन पाटोन्दा स्थित भगवान लक्ष्मीनाथ मंदिर के सामने
            बने पक्के रंगमंच पर रामलीला का मंचन किया जाता है। जिसमें एक स्थायी
            पक्का सिंहासन, दरबार, वन, महल इत्यादि दिखाने के लिए परदों का प्रयोग
            किया गया है।
          </p>
        </div>
        <div className="flex justify-center">
          <img
            className="mb-5 md:mb-0 rounded-xl"
            src={baseCDNUrl + "assets/rangmanch.webp"}
            alt="img"
          />
        </div>
      </div>

      <div className="relative md:grid md:grid-cols-2 my-[3%] px-4 md:px-32">
        <div className="flex justify-center">
          <img
            className="mb-5 md:mb-0 rounded-xl"
            src={baseCDNUrl + "assets/lakshminath-mandir.webp"}
            alt="img"
          />
        </div>
        <div className="flex flex-col justify-center md:pl-8">
          <h1 className="flex text-3xl md:text-5xl text-blue-900 font-medium mb-8">
            लक्ष्मीनाथ जी का मंदिर
          </h1>
          <p className="flex text-gray-600 text-justify text-lg mb-8">
            सन 1950 में कोटा में आयोजित अखिल भारतीय साहित्य सम्मेलन में इस
            रामलीला में प्रथम स्थान प्राप्त किया था। जिसमे पारितोषिक स्वरूप दो
            किलो का चांदी का छत्र कोटा दरबार द्वारा प्रदान किया गया।
          </p>
        </div>
      </div>
    </section>
  );
}
