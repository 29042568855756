export const artistTypes = [
  "कलाकार",
  "सहकलाकार",
  "हारमोनियम वादक",
  "तबला वादक",
  "मंजीरा वादक",
  "चिकारा / वायलिन वादक",
  "कीलालेट वादक",
  "नगाड़ा वादक",
];

export const photoEventsNames = [
  "हिन्दू कॉलेज 125th वर्षगाँठ समारोह",
  "तिसाया",
  "दसलानां",
  "पाटोन्दा रामलीला 2023",
  "पाटोन्दा रामलीला 2022",
];
