import React from "react";
// import "./NoticeBoardItem.css";

const NoticeBoardItem = ({ date, month, title, description }) => {
  return (
    <div className="container mx-auto bg-white rounded-lg overflow-hidden shadow-sm px-2 py-2 mb-5">
      <div className="flex flex-wrap -mx-4 -my-8">
        <div className="py-8 px-4 lg:w-full">
          <div className="h-full flex items-start justify-center">
            <div className="bg-orange-400 border border-solid border-white py-1 px-2 rounded-md w-18 flex-shrink-0 flex flex-col text-center leading-none mt-4 shadow-md">
              <span className="text-white pb-2 mb-2 border-b-2 border-gray-500">
                {month}
              </span>
              <span className="font-medium text-lg text-gray-800 title-font leading-none">
                {date}
              </span>
            </div>
            <div className="flex-grow pl-6">
              {/* <h2 className="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1">
                  CATEGORY
                </h2> */}
              <h1 className="title-font text-xl font-medium text-gray-900">
                {title}
              </h1>
              <p className="leading-relaxed mb-5 text-sm">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeBoardItem;
