import React from "react";

const SliderItem = ({ imgSrc, date, title, text, url }) => {
  return (
    <div className="blog-slider__item swiper-slide">
      <div className="blog-slider__img">
        <img src={imgSrc} alt="" />
      </div>
      <div className="blog-slider__content">
        <span className="blog-slider__code">{date}</span>
        <div className="blog-slider__title">{title}</div>
        <div
          className="blog-slider__text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <a
          href={url}
          className="blog-slider__button"
          target="_blank"
          rel="noreferrer"
        >
          READ MORE
        </a>
      </div>
    </div>
  );
};

export default SliderItem;
