import React from "react";
import { baseCDNUrl } from "../../utils/endpoints";
import "./InfinitePolaroidGallery.css";

function InfinitePolaroidGallery() {

const images =[
  "ramleela/2k24/hindu-college-delhi/DSC00797.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00800.webp",
  "ramleela/2k24/hindu-college-delhi/DSC00804.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00809.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00814.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00833.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00835.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00837.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00822.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00829.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00838.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00840.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00841.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00842.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00843.JPG",
  "ramleela/2k24/hindu-college-delhi/DSC00850.JPG"
]

  return (
    <>
      <figure className="gallery-item gallery-item--1">
        <div className="gallery-item__inner">
          <img
            className="gallery-item__image"
            src={baseCDNUrl + "ramleela/2k24/hindu-college-delhi/DSC00757.JPG"}
            alt=""
          />
          {/* <figcaption className="gallery-item__caption">Cat 1</figcaption> */}
        </div>
      </figure>
      <figure className="gallery-item gallery-item--2">
        <div className="gallery-item__inner">
          <img
            className="gallery-item__image"
            src={baseCDNUrl + "ramleela/2k24/hindu-college-delhi/DSC00767.JPG"}
            alt=""
          />
          {/* <figcaption className="gallery-item__caption">Cat 2</figcaption> */}
        </div>
      </figure>
      <figure className="gallery-item gallery-item--3">
        <div className="gallery-item__inner">
          <img
            className="gallery-item__image"
            src={baseCDNUrl + "ramleela/2k24/hindu-college-delhi/DSC00768.JPG"}
            alt=""
          />
          {/* <figcaption className="gallery-item__caption">Cat 3</figcaption> */}
        </div>
      </figure>
      <figure className="gallery-item gallery-item--4">
        <div className="gallery-item__inner">
          <img
            className="gallery-item__image"
            src={baseCDNUrl + "ramleela/2k24/hindu-college-delhi/DSC00786.JPG"}
            alt=""
          />
          {/* <figcaption className="gallery-item__caption">Cat 4</figcaption> */}
        </div>
      </figure>
      <figure className="gallery-item gallery-item--5">
        <div className="gallery-item__inner">
          <img
            className="gallery-item__image"
            src={baseCDNUrl + "ramleela/2k24/hindu-college-delhi/DSC00792.JPG"}
            alt=""
          />
          {/* <figcaption className="gallery-item__caption">Cat 5</figcaption> */}
        </div>
      </figure>
      <figure className="gallery-item gallery-item--6">
        <div className="gallery-item__inner">
          <img
            className="gallery-item__image"
            src={baseCDNUrl + "ramleela/2k24/hindu-college-delhi/DSC00796.JPG"}
            alt=""
          />
          {/* <figcaption className="gallery-item__caption">Cat 6</figcaption> */}
        </div>
      </figure>
    </>
  );
}

export default InfinitePolaroidGallery;
