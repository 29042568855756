import React from "react";
import Lottie from "react-lottie";
import * as newAnimation from "./new.json";

function Drawer({ open, handleToggle }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: newAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className={`fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] w-full cursor-pointer ${
        open ? `block` : `hidden`
      }`}
      style={{ zIndex: 300 }}
    >
      <div
        className={`relative w-3/4 px-4 py-4 h-full bg-white cursor-default transition-all
          duration-500 ${open ? "left-0 opacity-1" : "opacity-0"}`}
        style={{ zIndex: 1000 }}
      >
        <div className="text-gray-100">
          <div className="flex items-center gap-3 w-full">
            <a href="/" title="Ramleela Official Logo">
              <img
                src="/assets/imgs/logo.jpg"
                height="35"
                width="35"
                alt="Ramleela Official Logo"
              />
            </a>
            <div className="flex items-center justify-between w-full">
              <p className="font-semibold text-gray-700 text-[15px]">
                ढाई कड़ी की रामलीला पाटोन्दा
              </p>
              <i
                onClick={handleToggle}
                className="fa-solid fa-xmark border-[1px] cursor-pointer border-gray-200 rounded-md text-red-700 px-2 py-1"
              ></i>
            </div>
          </div>
          <div className="my-2 bg-gray-200 h-[0.5px]"></div>
        </div>
        <a href="/">
          <div className="p-2.5 mt-3 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-house "></i>
            <span className="text-[15px] font-medium">होम</span>
          </div>
        </a>
        <div className="p-2.5 relative rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
          <details>
            <summary className="gap-4 flex items-center">
              <i className="fa-solid fa-circle-info"></i>
              <p>हमारे बारे में जाने</p>
            </summary>
            <div className="text-left text-sm mt-2 ml-12 w-4/5 mx-auto font-medium">
              <a href="/history-of-ramleela">
                <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                  रामलीला का इतिहास
                </h1>
              </a>
              {/* <a href="/committee">
                <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                  कमिटी मेंबर्स
                </h1>
              </a> */}
              <a href="/artists">
                <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200 rounded-md mt-1">
                  कलाकार
                </h1>
              </a>
              <a href="contact-us">
                <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                  संपर्क करें
                </h1>
              </a>
            </div>
          </details>
        </div>
        {/* <div className="p-2.5 relative rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <details>
              <summary className="gap-4 flex items-center">
                <i className="fa-solid fa-diagram-project"></i>
                <p>Our Projects</p>
              </summary>
              <div className="text-left text-sm mt-2 ml-12 w-4/5 mx-auto font-medium">
                <a href="/">
                  <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                    Dhaikadi Ki Ramleela
                  </h1>
                </a>
              </div>
            </details>
          </div> */}
        <div className="p-2.5 relative rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
          <details>
            <summary className="gap-4 flex items-center">
              <i className="fa-solid fa-image"></i>
              <p>गेलरी</p>
            </summary>
            <div className="text-left text-sm mt-2 ml-12 w-4/5 mx-auto font-medium">
              <a href="/photo-gallery">
                <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                  फ़ोटो गेलरी
                </h1>
              </a>
              <a href="/video-gallery">
                <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                  वीडियो गेलरी
                </h1>
              </a>
            </div>
          </details>
        </div>
        {/* <div className="p-2.5 relative rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <details>
              <summary className="gap-4 flex items-center">
                <i className="fa-solid fa-image"></i>
                <p>रामलीला मंचन</p>
              </summary>
              <div className="text-left text-sm mt-2 ml-12 w-4/5 mx-auto font-medium">
                <a href="/photo-gallery">
                  <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                    तिसाया, सीसवाली
                  </h1>
                </a>
                <a href="/video-gallery">
                  <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                    दसलानां, कोटा
                  </h1>
                </a>
                <a href="/photo-gallery">
                  <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                    अयोध्या, उत्तरप्रदेश
                  </h1>
                </a>
                <a href="/video-gallery">
                  <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                    चित्रकूट, मध्य प्रदेश
                  </h1>
                </a>
                <a href="/photo-gallery">
                  <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                    भोपाल, मध्य प्रदेश
                  </h1>
                </a>
                <a href="/video-gallery">
                  <h1 className="cursor-pointer p-2  text-gray-400 hover:text-gray-800 hover:bg-gray-200  rounded-md mt-1">
                    कोटा, राजस्थान
                  </h1>
                </a>
              </div>
            </details>
          </div> */}
        <a href="/programs">
          <div className="p-2.5 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-calendar-days"></i>
            <span className="text-[15px] font-medium">वार्षिक कार्यक्रम</span>
          </div>
        </a>
        <a href="/news-and-articles">
          <div className="p-2.5 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-newspaper"></i>
            <span className="text-[15px] font-medium">समाचार और लेख</span>
          </div>
        </a>
        <a href="/contact-us">
          <div className="p-2.5 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-address-book"></i>
            <span className="text-[15px] font-medium">संपर्क करें</span>
          </div>
        </a>

        <a href="/faqs">
          <div className="p-2.5 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-question"></i>
            <span className="text-[15px] font-medium">
              पूछे जाने वाले प्रश्न
            </span>
          </div>
        </a>
        <a href="/assetstore">
          <div className="p-2.5 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-copyright"></i>
            <span className="text-[15px] font-medium">परिसंपत्ति भंडार</span>
          </div>
        </a>
        <a href="/manuscript">
          <div className="p-2.5 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-list"></i>
            <span className="text-[15px] font-medium">
              रामलीला की पांडूलिपिया
            </span>
          </div>
        </a>
        <a href="/patoonda-ramleela-2024">
          <div className="p-2.5 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-masks-theater"></i>
            <span className="text-[15px] font-medium">
              पाटोन्दा रामलीला #2024
            </span>
            <Lottie options={defaultOptions} height={50} width={100} />
          </div>
        </a>
        <a href="/125-years-celebration-mahotsav-hindu-college">
          <div className="p-2.5 gap-4 flex items-center rounded-md px-4 duration-300 cursor-pointer text-gray-600 hover:text-gray-800 hover:bg-gray-200 ">
            <i className="fa-solid fa-masks-theater"></i>
            <span className="text-[15px] font-medium">
              हिन्दू कॉलेज 125<sup>th</sup> वर्षगाँठ समारोह
            </span>
            {/* <Lottie options={defaultOptions} height={50} width={150} /> */}
          </div>
        </a>
      </div>
    </div>
  );
}

export default Drawer;
