/*
    TODO :: YouTube LiveTV Video URL
*/
export const LiveTVVideoSrc = "https://www.youtube.com/embed/pjDcKkIlnnM";

/*
    TODO :: Latest Updates ::) Notice Board
*/
export const latestUpdates = [];

/*
    TODO :: Ramleela 2k24 Program List
*/

export const ramleela2k24Programs = [
  {
    _id: "3452aa87-45a4-4d5d-9472-a29da347b7b5",
    cover: "https://dummyimage.com/720x400",
    day: "शनिवार",
    date: "13",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल पंचमी",
    title:
      "देवी देवता पूजन (दिन में 12 :15 से 4 :30 तक)<br>गणेश जी की सवारी (रात्रि में 9 बजे से)",
    description: "",
    time: "",
  },
  {
    _id: "44060140-0ff1-4ef4-90c0-64375c33af75",
    cover: "https://dummyimage.com/720x400",
    day: "रविवार",
    date: "14",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल षष्ठी",
    title: "पृथ्वी की पुकार, सकल देवताओं की पुकार, श्री राम जन्म लीला मंचन",
    description: "",
    time: "",
  },
  {
    _id: "cd576089-09b3-47a6-a317-6241a21449fe",
    cover: "https://dummyimage.com/720x400",
    day: "सोमवार",
    date: "15",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल सप्तमी",
    title: "विश्वामित्र जी द्वारा यज्ञ से धनुष यज्ञ, अहिल्या उद्धार",
    description:
      "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    time: "",
  },
  {
    _id: "5cb235e6-6cb8-4e61-ad0d-c7456530b449",
    cover: "https://dummyimage.com/720x400",
    day: "मंगलवार",
    date: "16",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल अष्टमी",
    title: "श्री राम बारात (अष्टमी की सवारी)",
    description: "",
    time: "",
  },
  {
    _id: "c5fa7573-163c-497e-bb0e-a7d2aab5ce29",
    cover: "https://dummyimage.com/720x400",
    day: "बुधवार",
    date: "17",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल नवमी",
    title:
      "श्री राम जन्म उत्सव (दिन में 2 बजे से)</br>लीला मंचन - सीता स्वयंवर, लक्ष्मण - परशुराम संवाद ",
    description: "",
    time: "",
  },
  {
    _id: "c0c73493-d1f6-4a31-a4b6-76e8a22674b2",
    cover: "https://dummyimage.com/720x400",
    day: "गुरुवार",
    date: "18",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल दशमी",
    title: "रावण वध (दशमी की सवारी)",
    description: "",
    time: "",
  },
  {
    _id: "1b624e0b-5066-481a-a534-205f04d4e981",
    cover: "https://dummyimage.com/720x400",
    day: "शुक्रवार",
    date: "19",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल एकादशी",
    title: "सीता स्वयंवर, लक्ष्मण - परशुराम संवाद",
    description: "",
    time: "",
  },
  {
    _id: "1b624e0b-5066-481a-a534-205f04d4e981",
    cover: "https://dummyimage.com/720x400",
    day: "शनिवार",
    date: "20",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल द्वादशी",
    title: "राज तिलक की तैयारी, राम वनवास",
    description: "",
    time: "",
  },
  {
    _id: "1b624e0b-5066-481a-a534-205f04d4e981",
    cover: "https://dummyimage.com/720x400",
    day: "रविवार",
    date: "21",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल त्रयोदशी",
    title:
      "केवट प्रसंग, दशरथ मरण, श्रीराम - निषादराज मित्रता, श्रीराम - भरत मिलाप",
    description: "",
    time: "",
  },
  {
    _id: "3e1f73ef-7661-487a-ba50-01b9fe9e7463",
    cover: "https://dummyimage.com/720x400",
    day: "सोमवार",
    date: "22",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल चतुर्दशी",
    title: "विराध राक्षस वध, शूर्पणखा संवाद, सीता हरण, जटायु उद्धार",
    description: "",
    time: "",
  },
  {
    _id: "ab88b89a-a4a7-4c4f-ac57-63cc7a35dbd9",
    cover: "https://dummyimage.com/720x400",
    day: "मंगलवार",
    date: "23",
    month: "अप्रैल",
    tithi: "चैत्र पूर्णिमा",
    title:
      "बाली द्वारा दुंदुभी वध, शबरी प्रसंग, राम - सुग्रीव मित्रता, बाली वध",
    description: "",
    time: "",
  },
  {
    _id: "ad721c4e-a493-4057-ab18-168f8667465a",
    cover: "https://dummyimage.com/720x400",
    day: "बुधवार",
    date: "24",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण प्रतिपदा",
    title:
      "सुग्रीव राज्याभिषेक, सुग्रीव द्वारा सीता की खोज, हनुमान - लंकिनी संवाद",
    description: "",
    time: "",
  },
  {
    _id: "ad721c4e-a493-4057-ab18-168f8667465c",
    cover: "https://dummyimage.com/720x400",
    day: "गुरुवार",
    date: "25",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण द्वितीया",
    title:
      "हनुमान जी का लंका में प्रवेश, हनुमान - सीता संवाद, अशोक वाटिका उजाड़ना, जंबूमाली वध, अक्षय कुमार वध, लंका दहन",
    description: "",
    time: "",
  },
  {
    _id: "ad721c4e-a493-4057-ab18-168f8667465d",
    cover: "https://dummyimage.com/720x400",
    day: "शुक्रवार",
    date: "26",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण तृतीया",
    title:
      "लंका की बढ़ाई, विभीषण शरणागत, लंका की चढाई, सेतु निर्माण, अंगद - रावण संवाद",
    description: "",
    time: "",
  },
  {
    cover: "https://dummyimage.com/720x400",
    day: "शनिवार",
    date: "27",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण चतुर्थी",
    title: "लक्ष्मण शक्ति, कुम्भकरण वध, मेघनाथ वध, सुलोचना सती",
    description: "",
    time: "",
  },
  {
    cover: "https://dummyimage.com/720x400",
    day: "रविवार",
    date: "28",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण पंचमी",
    title:
      "अहिरावण वध, नारंतक वध, रावण वध, राज्य अभिषेक, महाआरती, रामलीला समापन",
    description: "",
    time: "",
  },
];

export const manchanPrograms = [
  {
    _id: "1b624e0b-5066-481a-a534-205f04d4e981",
    cover:
      "https://resize.indiatv.in/resize/newbucket/1200_-/2024/01/ram-ji-1704695023.jpg",
    day: "शुक्रवार",
    date: "19",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल एकादशी",
    title: "राज तिलक की तैयारी, राम वनवास",
    description: "",
    time: "",
  },
  {
    _id: "1b624e0b-5066-481a-a534-205f04d4e981",
    cover:
      "https://hindi.cdn.zeenews.com/hindi/sites/default/files/styles/zm_700x400/public/2022/06/07/1173044-ram-bharat-milap.jpg?itok=TDgk-16J",
    day: "शनिवार",
    date: "20",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल द्वादशी",
    title:
      "केवट प्रसंग, दशरथ मरण, श्रीराम - निषादराज मित्रता, श्रीराम - भरत मिलाप",
    description: "",
    time: "",
  },
  {
    _id: "3e1f73ef-7661-487a-ba50-01b9fe9e7463",
    cover:
      "https://www.jagranimages.com/images/newimg/02102022/02_10_2022-moradabad_ki_ramlila_23113699.webp",
    day: "रविवार",
    date: "21",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल त्रयोदशी",
    title: "विराध राक्षस वध, शूर्पणखा संवाद, सीता हरण, जटायु उद्धार",
    description: "",
    time: "",
  },
  {
    _id: "ab88b89a-a4a7-4c4f-ac57-63cc7a35dbd9",
    cover:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiNCKbUA51jAT8120WMnL0Pb88wJdMDXvaOqBGI5g3XBCCSX3hk8Mh5toMkpxr6KQAm6NWHWUJ3_vQOj-5sBQWKPOd0StuGbb2aMdJuzBwtLANkpSAA4XxVXXfverKToNKKftPfWXfiQjS0SAAkiSKL1UkDYdlCEeanyFj6dqNFx8dlVovlwdd04SYTIw/s320/aaaaffff.jpg",
    day: "सोमवार",
    date: "22",
    month: "अप्रैल",
    tithi: "चैत्र शुक्ल चतुर्दशी",
    title:
      "बाली द्वारा दुंदुभी वध, शबरी प्रसंग, राम - सुग्रीव मित्रता, बाली वध",
    description: "",
    time: "",
  },
  {
    _id: "ad721c4e-a493-4057-ab18-168f8667465a",
    cover:
      "https://www.thepublic.in/wp-content/uploads/2020/09/hanuman-sita-bhent.jpg",
    day: "मंगलवार",
    date: "23",
    month: "अप्रैल",
    tithi: "चैत्र पूर्णिमा",
    title:
      "सुग्रीव राज्याभिषेक, सुग्रीव द्वारा सीता की खोज, हनुमान - लंकिनी संवाद",
    description: "",
    time: "",
  },
  {
    _id: "ad721c4e-a493-4057-ab18-168f8667465c",
    cover: "https://socialsamvad.com/wp-content/uploads/2023/10/81-4.jpg",
    day: "बुधवार",
    date: "24",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण प्रतिपदा",
    title:
      "हनुमान जी का लंका में प्रवेश, हनुमान - सीता संवाद, अशोक वाटिका उजाड़ना, जंबूमाली वध, अक्षय कुमार वध, लंका दहन",
    description: "",
    time: "",
  },
  {
    _id: "ad721c4e-a493-4057-ab18-168f8667465d",
    cover:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbfUtgW2TtoAFtQZrkrmqHswMdEmdMdFVjlvEvOEAcrg&s",
    day: "गुरुवार",
    date: "25",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण द्वितीया",
    title: "लंका की बढ़ाई, विभीषण शरणागत, लंका की चढाई",
    description: "",
    time: "",
  },
  {
    _id: "ad721c4e-a493-4057-ab18-168f8667465w",
    cover:
      "https://akm-img-a-in.tosshub.com/aajtak/images/story/202402/65dc842d26fd3-ramcharitmanas-262931859-16x9.jpg?size=948:533",
    day: "शुक्रवार",
    date: "26",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण तृतीया",
    title: "सेतु निर्माण, अंगद - रावण संवाद",
    description: "",
    time: "",
  },
  {
    cover:
      "https://staticimg.amarujala.com/image/original/2014/10/01/kumbhkarn-542becaf02d26_exlst.jpg",
    day: "शनिवार",
    date: "27",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण चतुर्थी",
    title: "लक्ष्मण शक्ति, कुम्भकरण वध, मेघनाथ वध, सुलोचना सती",
    description: "",
    time: "",
  },
  {
    cover:
      "https://www.agniban.com/wp-content/uploads/2020/10/000000000000000000-1.jpg",
    day: "रविवार",
    date: "28",
    month: "अप्रैल",
    tithi: "वैशाख कृष्ण पंचमी",
    title:
      "अहिरावण वध, नारंतक वध, रावण वध, राज्य अभिषेक, महाआरती, रामलीला समापन",
    description: "",
    time: "",
  },
];

/*
    TODO :: What is Frequently Asked Questions
*/
export const whatIsFaqs = [
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "ढाई कड़ी की रामलीला के रचियता कौन थे?",
    answer:
      "इस विश्वविख्यात ढाई कड़ी की रामलीला के रचयिता परम् पूजनीय साकेतधामवासी आदरणीय ग्राम गुरु <mark><b>श्री गणपतलाल जी दाधिच</b></mark> है। इनका जन्म पाटौन्दा ग्राम में हुआ। तथा काशी में इन्होंने शिक्षा प्राप्त की इनके द्वारा वाल्मीकि रामायण व तुलसीदास जी द्वारा रचित रामचरिमानस के आधार पर शुद्ध हाड़ौती भाषा मे यह रामलीला लिखी। जिसके अनुसार इस रामलीला का मंचन पाटौन्दा ग्राम मे किया जाता है।",
  },
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "&quot;ढाई कड़ी&quot; का मतलब क्या होता है?",
    answer:
      "संगीत में समान स्वरों को उच्च स्वरों में गया जाता हैं उसी को तान कहते हैं। हमारी ढाई कड़ी में जो गायन होता हैं। उसकी प्रथम पंक्ति तान कहलाती हैं।",
  },
  {
    _id: "baaef363-b5e7-4f5a-9a96-bc4f6744da48",
    question: "&quot;तान&quot; का होती है?",
    answer:
      "संगीत में समान स्वरों को उच्च स्वरों में गया जाता हैं उसी को तान कहते हैं। हमारी ढाई कड़ी में जो गायन होता हैं। उसकी प्रथम पंक्ति तान कहलाती हैं।<br>उदाहरण : अजी सवरू लक्ष्मीनाथ अवतार <br/>हमारा बेड़ा लगाओ प्रभू पार।।<br>इसको प्रत्येक छंद के बाद दोहराया जाता हैं ।",
  },
  {
    _id: "31183a6a-fdf9-441c-845b-be40638afd05",
    question: "इस रामलीला मैं अखाडा क्यों बांधा जाता है।",
    answer: "kjhkjhkj kjhjkhjkh  jkhkjkj jkhkjh",
  },
  {
    _id: "31183a6a-fdf9-441c-845b-be40638afd05",
    question: "यह रामलीला कोनसी बोली में लिखी गयी हैं।",
    answer:
      "यह रामलीला राजस्थानी भाषा की हाड़ौती उपभाषा में लिखी गई है। हाड़ौती पर ऊंचे स्वर वाली प्राचीन डिंगल भाषा का प्रभाव है।इसमें हिंदी के शुद्ध शब्दों को लोक भाषा में गाया जाता हैं।",
  },
];

export const mostAskedFaqs = [
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "इस रामलीला की शुरुआत किसने और कब की थी।",
    answer:
      "आज से लगभग 160 वर्ष पूर्व एक दाधिच ब्राम्हण गुरु गणपतलाल जी दाधिच ने इस सांगीतिक रामलीला को प्रारंभ किया था।",
  },
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "यह रामलीला किस भाषा मैं लिखी गयी है।",
    answer:
      "यह रामलीला राजस्थानी भाषा की हाड़ौती उपभाषा में लिखी गई है। हाड़ौती पर ऊंचे स्वर वाली प्राचीन डिंगल भाषा का प्रभाव है।",
  },
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "",
    answer: "",
  },
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "",
    answer: "",
  },
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "",
    answer: "",
  },
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "",
    answer: "",
  },
  {
    _id: "327d0f81-f66c-4b7d-a3b1-93b625710064",
    question: "",
    answer: "",
  },
];
