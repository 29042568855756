import React from "react";
import useAnalyticsEventTracker from "./../../useAnalyticsEventTracker";

export default function ContactUs() {
  const gaEventTracker = useAnalyticsEventTracker("Contact Us");

  return (
    <section className="text-gray-600 body-font relative py-10 md:py-14">
      <div className="flex flex-col text-center w-full md:mb-18">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          संपर्क करे
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
          रामलीला कार्यकारणी से संपर्क करे
        </p>
      </div>
      <div className="container px-5 py-0 md:py-12 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="w-full h-[250px] md:h-screen lg:w-2/3 md:w-1/2 bg-gray-300 rounded-tl-lg rounded-tr-lg md:rounded-lg overflow-hidden mr-0 md:mr-10 p-10 flex items-end justify-start relative shadow-md md:shadow-none">
          <iframe
            width="100%"
            height="100%"
            className="absolute inset-0"
            frameborder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.537206925505!2d76.30203901744385!3d25.286149099999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396fe56334ab8bbf%3A0x1645fcda92641ee9!2sShree%20Lok%20Ramleela%20Mandal%20Patoonda!5e0!3m2!1sen!2sin!4v1678948092224!5m2!1sen!2sin&amp;disableDefaultUI=true"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="bg-white w-full relative hidden md:flex flex-wrap py-6 rounded shadow-md">
            <div className="lg:w-2/5 px-6">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                संपर्क पता
              </h2>
              <address className="mt-1">
                श्री लोक रामलीला मंडल <br />
                पाटोन्दा, अंता, बारां, राजस्थान - 325206 <br />
              </address>
              <a
                href="https://www.dhaikadikiramleela.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.dhaikadikiramleela.com
              </a>
            </div>
            <div className="lg:w-3/5 px-6 mt-4 text-right lg:mt-0">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                ई-मेल पता
              </h2>
              <a
                href="mailto:shreelokramleelamandalpatoonda@gmail.com"
                className="text-red-500 leading-relaxed"
                onClick={() => gaEventTracker("email")}
              >
                shreelokramleelamandalpatoonda@gmail.com
              </a>
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                कॉल करे
              </h2>
              <p className="leading-relaxed">
                <a
                  href="tel:+919929324083"
                  onClick={() => gaEventTracker("call")}
                >
                  +9199293 24083
                </a>{" "}
                (श्री राम प्रसाद सेन) [कोषाध्यक्ष]
              </p>
              <p className="leading-relaxed">
                <a href="tel:+919829122761">+9198291 22761</a> (श्री लोकेश
                दाधीच) [सयोजक]
              </p>
              <p className="leading-relaxed">
                <a href="tel:+918209446178">+9182094 46178</a> (श्री चेतन नागर)
                [मीडिया प्रभारी]
              </p>
            </div>
          </div>
        </div>
        <div className="sm:full lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full py-8 md:py-8 px-8 mt-8 md:mt-0 mb-8 md:mb-0 order-first md:order-last">
          <h2 className="text-gray-900 text-lg md:text-3xl mb-1 font-medium title-font">
            संपर्क करे
          </h2>
          <p className="leading-relaxed mb-5 text-gray-600">
            रामलीला कार्यकारणी से संपर्क करने के लिए आप हमें मैसेज भेज सकते है।
          </p>
          <div className="relative mb-4">
            <label for="name" className="leading-7 text-sm text-gray-600">
              पूरा नाम <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              autocomplete="off"
              className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label for="email" className="leading-7 text-sm text-gray-600">
              मोबाइल नंबर <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              autocomplete="off"
              className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label for="email" className="leading-7 text-sm text-gray-600">
              ईमेल पता <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              autocomplete="off"
              className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label for="message" className="leading-7 text-sm text-gray-600">
              संदेश <span className="text-red-500">*</span>
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              data-gramm="false"
              wt-ignore-input="true"
            ></textarea>
          </div>
          <button className="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">
            संदेश भेजें
          </button>
          <p className="text-xs text-gray-500 mt-3">
            <span className="text-red-500">*</span>आपकी सभी जानकारी 100%
            सुरक्षित है।
          </p>
        </div>
      </div>

      {/* 
          TODO :: Mobile Version UI 
      */}

      <div className="md:hidden bg-white relative flex flex-wrap py-6 mx-5 rounded-bl-lg rounded-br-lg md:rounded shadow-md">
        <div className="lg:w-1/2 px-6">
          <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
            संपर्क पता
          </h2>
          <address className="mt-1">
            श्री लोक रामलीला मंडल <br />
            पाटोन्दा, अंता, बारां, राजस्थान - 325206
          </address>
        </div>
        <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
          <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
            ई-मेल पता
          </h2>
          <a
            href="mailto:shreelokramleelamandalpatoonda@gmail.com"
            className="text-red-500 leading-relaxed"
            onClick={() => gaEventTracker("email")}
          >
            shreelokramleelamandalpatoonda@gmail.com
          </a>
          <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
            कॉल करे
          </h2>
          <p className="leading-relaxed">
            <a href="tel:+919929324083">+9199293 24083</a> (श्री राम प्रसाद सेन)
            [कोषाध्यक्ष]
          </p>
          <p className="leading-relaxed">
            <a href="tel:+919829122761">+9198291 22761</a> (श्री लोकेश दाधीच)
            [सयोजक]
          </p>
          <p className="leading-relaxed">
            <a href="tel:+918209446178">+9182094 46178</a> (श्री चेतन नागर)
            [मीडिया प्रभारी]
          </p>
        </div>
      </div>
    </section>
  );
}
