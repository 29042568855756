import React from 'react'
import CustomTabs from '../../CustomTabs/CustomTabs'
import NoticeBoard from '../NoticeBoard/NoticeBoard'

function Main() {
  return (
    <section className="bg-white">
        <div className="md:px-32">
          <div className="container mx-auto md:py-3">
            <div className="grid grid-cols-3 gap-2">
              <div className="col-span-3 md:col-span-2">
                <CustomTabs />
              </div>
              <div className="col-span-3 md:col-span-1 hidden md:block">
                <NoticeBoard />
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Main
