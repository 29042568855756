import React from "react";

export default function Cookies() {
  return (
    <section className="text-gray-600 body-font relative py-10 md:py-14">
      <div className="flex flex-col text-center w-full mb-10 md:mb-18">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          कुकीज़
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
          ढाई कड़ी की रामलीला वेबसाइट को उपयोग करने के लिए कुकीज़ नीति
        </p>
      </div>
      <div className="container px-5 py-0 md:py-12 mx-auto">
        <h1>ढाई कड़ी की रामलीला वेबसाइट को उपयोग करने के लिए कुकीज़ नीति</h1>

        <p className="text-justify">
          यह ढाई कड़ी की रामलीला पाटोन्दा के लिए कुकी नीति है,
          https://www.dhaikadikiramleela.com/ से पहुंच योग्य
        </p>

        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          <strong>कुकीज़ क्या हैं</strong>
        </h1>

        <p className="text-justify">
          जैसा कि लगभग सभी पेशेवर वेबसाइटों में आम बात है, यह साइट आपके अनुभव को
          बेहतर बनाने के लिए कुकीज़ का उपयोग करती है, जो छोटी फ़ाइलें होती हैं
          जो आपके कंप्यूटर पर डाउनलोड की जाती हैं। यह पृष्ठ बताता है कि वे कौन
          सी जानकारी एकत्र करते हैं, हम इसका उपयोग कैसे करते हैं, और हमें
          कभी-कभी इन कुकीज़ को संग्रहीत करने की आवश्यकता क्यों होती है। हम यह भी
          साझा करेंगे कि आप इन कुकीज़ को संग्रहीत होने से कैसे रोक सकते हैं;
          हालाँकि, यह साइट की कार्यक्षमता के कुछ तत्वों को डाउनग्रेड या 'ब्रेक'
          कर सकता है।
        </p>

        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          <strong>हम कुकीज़ का उपयोग कैसे करते हैं</strong>
        </h1>

        <p className="text-justify">
          हम विभिन्न कारणों से कुकीज़ का उपयोग करते हैं, जैसा कि नीचे बताया गया
          है। दुर्भाग्य से, ज्यादातर मामलों में, इस साइट पर जोड़ी जाने वाली
          कार्यक्षमता और सुविधाओं को पूरी तरह से अक्षम किए बिना कुकीज़ को अक्षम
          करने के लिए कोई उद्योग-मानक विकल्प नहीं हैं। यह अनुशंसा की जाती है कि
          आप सभी कुकीज़ को छोड़ दें यदि आप सुनिश्चित नहीं हैं कि आपको उनकी
          आवश्यकता है या नहीं, यदि उनका उपयोग आपके द्वारा उपयोग की जाने वाली
          सेवा प्रदान करने के लिए किया जाता है।
        </p>

        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          <strong>कुकीज़ अक्षम करना</strong>
        </h1>

        <p className="text-justify">
          आप अपने ब्राउज़र पर सेटिंग्स समायोजित करके कुकीज़ की सेटिंग को रोक
          सकते हैं (ऐसा कैसे करें इसके लिए अपने ब्राउज़र की सहायता देखें)। ध्यान
          रखें कि कुकीज़ को अक्षम करने से इसकी और आपके द्वारा देखी जाने वाली कई
          अन्य वेबसाइटों की कार्यक्षमता प्रभावित होगी। कुकीज़ को अक्षम करने से
          आमतौर पर इस साइट की कुछ कार्यक्षमताएं और सुविधाएं भी अक्षम हो जाएंगी।
          इसलिए यह अनुशंसा की जाती है कि आप कुकीज़ को अक्षम न करें।
        </p>

        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          <strong>हमारे द्वारा सेट की गई कुकीज़</strong>
        </h1>

        <ul>
          <li>
            <p>ईमेल न्यूज़लेटर्स से संबंधित कुकीज़</p>
            <p>
              यह साइट न्यूज़लेटर या ईमेल सदस्यता सेवाएँ प्रदान करती है, और
              कुकीज़ का उपयोग यह याद रखने के लिए किया जा सकता है कि क्या आप पहले
              से पंजीकृत हैं और क्या कुछ सूचनाएं दिखानी हैं, जो केवल सदस्यता
              लेने वाले या सदस्यता समाप्त करने वाले उपयोगकर्ताओं के लिए मान्य हो
              सकती हैं।
            </p>
          </li>

          <li>
            <p>साइट प्राथमिकताएँ कुकीज़</p>
            <p>
              इस साइट पर आपको बेहतरीन अनुभव प्रदान करने के लिए हम आपकी
              प्राथमिकताएँ निर्धारित करने की कार्यक्षमता प्रदान करते हैं कि जब
              आप इसका उपयोग करते हैं तो यह साइट कैसे चलती है। आपकी प्राथमिकताओं
              को याद रखने के लिए हमें कुकीज़ सेट करने की आवश्यकता है ताकि जब भी
              आप किसी पेज के साथ इंटरैक्ट करें जो आपकी प्राथमिकताओं से प्रभावित
              हो तो यह जानकारी कॉल की जा सके।
            </p>
          </li>
        </ul>

        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          <strong>तीसरे पक्ष की कुकीज़</strong>
        </h1>

        <p>
          कुछ विशेष मामलों में, हम विश्वसनीय तृतीय पक्षों द्वारा प्रदान की गई
          कुकीज़ का भी उपयोग करते हैं। निम्नलिखित अनुभाग विवरण देता है कि आपको
          इस साइट के माध्यम से किन तृतीय-पक्ष कुकीज़ का सामना करना पड़ सकता है।
        </p>

        <ul>
          <li>
            <p>
              यह साइट Google Analytics का उपयोग करती है, जो वेब पर सबसे व्यापक
              और विश्वसनीय एनालिटिक्स समाधानों में से एक है, जिससे हमें यह समझने
              में मदद मिलती है कि आप साइट का उपयोग कैसे करते हैं और हम आपके
              अनुभव को कैसे बेहतर बना सकते हैं। ये कुकीज़ चीजों को ट्रैक कर सकती
              हैं जैसे कि आप साइट पर कितना समय बिताते हैं और आपके द्वारा देखे
              जाने वाले पेज, ताकि हम आकर्षक सामग्री का उत्पादन जारी रख सकें।
            </p>
            <p>
              Google Analytics कुकीज़ पर अधिक जानकारी के लिए, आधिकारिक Google
              Analytics पृष्ठ देखें।
            </p>
          </li>
        </ul>

        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          <strong>अधिक जानकारी</strong>
        </h1>

        <p>
          उम्मीद है कि इससे आपके लिए चीजें स्पष्ट हो गई हैं, और जैसा कि पहले
          उल्लेख किया गया था, यदि कोई ऐसी चीज़ है जिसके बारे में आप निश्चित नहीं
          हैं कि आपको इसकी आवश्यकता है या नहीं, तो आमतौर पर कुकीज़ को सक्षम
          छोड़ना अधिक सुरक्षित होता है, यदि यह आपके द्वारा उपयोग की जाने वाली
          सुविधाओं में से किसी एक के साथ इंटरैक्ट करती है। हमारी साइट।
        </p>

        <p>
          हालाँकि यदि आप अभी भी अधिक जानकारी की तलाश में हैं तो आप हमारे पसंदीदा
          संपर्क तरीकों में से किसी एक के माध्यम से हमसे संपर्क कर सकते हैं:
        </p>

        <ul className="py-2 md:my-4">
          <li>
            ईमेल :{" "}
            <a
              href="mailto:contact@dhaikadikiramleela.com"
              className="text-red-500 leading-relaxed underline"
            >
              contact@dhaikadikiramleela.com
            </a>
          </li>
          <li>
            इस लिंक पर जाकर :{" "}
            <a
              href="https://www.dhaikadikiramleela.com/contact-us"
              className="text-red-500 leading-relaxed underline"
            >
              https://www.dhaikadikiramleela.com/contact-us
            </a>
          </li>
          <li>
            फ़ोन :{" "}
            <a
              className="text-red-500 leading-relaxed underline"
              href="tel:+918209446178"
            >
              +9182094 46178
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}
