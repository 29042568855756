import React from "react";

function GaneshVandana() {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="block w-5 h-5 text-gray-400 mb-4"
        viewBox="0 0 975.036 975.036"
      >
        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
      </svg> */}
      <h2 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900 rozha-one-regular text-center">
        गणेश वंदना
      </h2>
      <p class="leading-relaxed mb-6 text-center font-semibold">|| दोहा ||</p>
      <p class="leading-relaxed mb-6 text-center">
        श्री गुरु चरण सरोज रज, निज मन मुकुर सुधार <br />
        बरनऊं रघुवर बिमल जसु, जो दायकु फल चारि ।
      </p>
      <p class="leading-relaxed mb-6 text-right">
        सिया वर राम चंद्र की जय
        <br />
        उमापति महादेव की जय
        <br />
        पवन सूत हनुमान की जय
        <br />
        बोलो रे भाई सब सन्तन की जय
      </p>
      <p class="leading-relaxed mb-6 text-center font-semibold">|| चौपाई ||</p>
      <p class="leading-relaxed mb-6 text-center">
        प्रथम ही तो गणराज मनाऊ । <br />
        मात सरस्वती जी की आज्ञा पाऊ ॥ <br />
        गुरु चरनन मम शिश नवाऊ । <br />
        राम चंद्र जी का हरि गुण गाऊ ॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        चढ़ाव - नमो गुरु गणपत सारद धाऊ हरि जस रामचंद्र गाउ <br />
        नमो गुरु गणपत न ढोकु अष्ट पहर दिन रेन गजानंद रणत भवर महाराज । <br />
        उतार - शिर पर सोहे मोर मुकुट बाक पाँव घुँघरा बाज ।। <br />
      </p>

      <p class="leading-relaxed mb-6 text-center">
        नमो सदा शिव शंकर ढोकु गवर पति न महादेव हे नाडे असवार । <br />
        शीश गंग हर गंग विराजे गले शेष फनहार ॥ <br />
      </p>

      <p class="leading-relaxed mb-6 text-center">
        नमो सारदा ब्रहम सुता न नमन करू प्रणाम ईशरी माजी हंसा रुद । <br />
        मम वाणी तुम सफल करो न मति हमारी मूद ॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        सावित्री जी का पति डोकु आद ब्रहम अवतार सकल का ब्रहमा हे करतार । <br />
        सकल श्रष्टि ब्रहमा न रच दीनी पंथ चलावन हार ॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        नमो हरि भगवान राम न सवरू बारम्बार सदा चरनन म शिश नवाऊ । <br />
        जिनके दूत महा बल योद्धा हनुमत वीर मनाऊ ॥
      </p>
    </>
  );
}
export default GaneshVandana;
