import React from "react";
import { manchanPrograms } from "../../core/globalProvider";

function Programs() {
  const programs = [
    {
      cover:
        "https://images.hindustantimes.com/img/2022/08/29/1600x900/pexels-chait-goli-5174348_1661753603153_1661753621255_1661753621255.jpg",
      day: "रविवार",
      hindu: "पंचमी",
      date: "26",
      month: "March",
      tithi: "चैत्र शुक्ल पंचमी",
      title: "देवी देवता पूजन व गणेश जी की सवारी",
      description:
        "चेत्र पंचमी को भगवान श्री लक्ष्मीनाथ जी के मंदिर से गणेश जी की छतरी तक लक्ष्मीनाथ जी के विमान के साथ सभी देवी देवता श्री गणेश जी को निमंत्रण पत्रिका देने जाते है और रामलीला में पधारने का आग्रह करते है।",
      time: "",
    },
    {
      cover:
        "https://d3pc1xvrcw35tl.cloudfront.net/sm/images/1103x827/raam-birth_20180316968.jpg",
      day: "सोमवार",
      hindu: "षष्ठी",
      date: "27",
      month: "March",
      tithi: "चैत्र शुक्ल षष्ठी",
      title: "पृथ्वी की पुकार, सकल देवताओं की पुकार, श्री राम जन्म लीला मंचन",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
      time: "",
    },
    {
      cover: "https://arungovil.in/wp-content/uploads/2021/02/5PM-02-1.jpg",
      day: "मंगलवार",
      hindu: "सप्तमी",
      date: "28",
      month: "March",
      tithi: "चैत्र शुक्ल सप्तमी",
      title: "विश्वामित्र जी द्वारा यज्ञ से धनुष यज्ञ, अहिल्या उद्धार",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
      time: "",
    },
    {
      cover:
        "https://images1.livehindustan.com/uploadimage/library/2019/09/26/16_9/16_9_1/janakpuri_agra_2019_agra_ki_ram_barat_ram_barat_agra__1569467066.jpg",
      day: "बुधवार",
      hindu: "अष्टमी",
      date: "29",
      month: "March",
      tithi: "चैत्र शुक्ल अष्टमी",
      title: "श्री राम बारात (अष्टमी की सवारी)",
      description:
        "अष्टमी को श्री राम जी की बारात भगवान श्री लक्ष्मीनाथ जी के प्रांगण से (रामलीला रंगमंच से) चलकर जानकी राय जी के मन्दिर (जनकपुरी) (मठ) तक जाती है ये परम्परा जो कि सालो से चली आ रही है ... तत्पश्चात श्री राम, लक्ष्मण, भरत एवम् शत्रुघ्न जी का विधि - विधान से विवाह संपन्न होता है।",
      time: "",
    },
    {
      cover:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhlb87ymiry7WTXn39LQ06-vIefzFxouvL_WU8fg1uHX_MikYZhcYryd2tLztxPvdeld5LqAOJdWl_IdsIfVR8MtYB9AdSbMM7WyzCOweXfOaKjrTUBGIoZI4qXqRcGgY4yBD9UzPg6hU8/s1600/%25E0%25A4%25B6%25E0%25A5%258D%25E0%25A4%25B0%25E0%25A5%2580+%25E0%25A4%25B0%25E0%25A4%25BE%25E0%25A4%25AE+%25E0%25A4%259C%25E0%25A4%25A8%25E0%25A5%258D%25E0%25A4%25AE+%25E0%25A4%25AE%25E0%25A4%25B9%25E0%25A5%258B%25E0%25A4%25A4%25E0%25A5%258D%25E0%25A4%25B8%25E0%25A4%25B5+2019.jpg",
      day: "गुरुवार",
      hindu: "नवमी",
      date: "30",
      month: "March",
      tithi: "चैत्र शुक्ल नवमी",
      title: "रामजन्म उत्सव",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
      time: "",
    },
    {
      cover:
        "https://www.jagranimages.com/images/newimg/05102022/05_10_2022-ravan_dahan_1_23120718.jpg",
      day: "शुक्रवार",
      hindu: "दशमी",
      date: "31",
      month: "March",
      tithi: "चैत्र शुक्ल दशमी",
      title: "रावण वध (दशमी की सवारी)",
      description:
        "दशमी को श्री राम जी की सेना भगवान श्री लक्ष्मीनाथ जी के प्रांगण से (रामलीला रंगमंच से) चलकर लंका तक जाती है ये परम्परा जो कि सालो से चली आ रही है ... तत्पश्चात श्री राम द्वारा रावण दहन किया जाता है।",
      time: "",
    },
  ];

  // const manchanPrograms = [
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "शनिवार",
  //     date: "2",
  //     month: "अप्रैल",
  //     tithi: "चैत्र शुक्ल एकादशी",
  //     title: "राम जन्म उत्सव, धनुष यज्ञ राम विवाह",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "शनिवार",
  //     date: "3",
  //     month: "अप्रैल",
  //     tithi: "चैत्र शुक्ल द्वादशी",
  //     title: "लक्ष्मण व परशुराम जी संवाद, राज तिलक तथा वनवास",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "रविवार",
  //     date: "4",
  //     month: "अप्रैल",
  //     tithi: "चैत्र शुक्ल त्रयोदशी",
  //     title: "केवट प्रसंग, श्रीराम - निषादराज मित्रता, श्रीराम भरत मिलाप",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "सोमवार",
  //     date: "5",
  //     month: "अप्रैल",
  //     tithi: "चैत्र शुक्ल चतुर्दशी",
  //     title: "विराट राक्षश वध, शूर्पणखा संवाद, सीता हरण, जटायु उद्धार",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "मंगलवार",
  //     date: "6",
  //     month: "अप्रैल",
  //     tithi: "चैत्र शुक्ल पूर्णिमा",
  //     title:
  //       "बाली द्वारा दुंदुभी वध, शबरी प्रसंग, सुग्रीव - श्री राम मित्रता, बाली - सुग्रीव युद्ध, बाली वध",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "बुधवार",
  //     date: "7",
  //     month: "अप्रैल",
  //     tithi: "वैशाख कृष्ण प्रतिपदा",
  //     title:
  //       "सुग्रीव राज्याभिषेक, सुग्रीव द्वारा सीता की खोज, हनुमान जी लंका प्रवेश",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "गुरुवार",
  //     date: "8",
  //     month: "अप्रैल",
  //     tithi: "वैशाख कृष्ण द्वितीया",
  //     title:
  //       "सीता खोज, अशोक वाटिका उजाड़ना, लंका दहन, जंबूमाली वध, अक्षय कुमार वध",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "शुक्रवार",
  //     date: "9",
  //     month: "अप्रैल",
  //     tithi: "वैशाख कृष्ण तृतीया",
  //     title:
  //       "लंका की बढ़ाई व चढाई, समुन्द्र पर रामेश्वर महादेव की स्थापना, सेतु निर्माण, विभीषण शरणागत",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "शनिवार",
  //     date: "10",
  //     month: "अप्रैल",
  //     tithi: "वैशाख कृष्ण चतुर्थी",
  //     title: "लक्ष्मण शक्ति, मेघनाथ वध, कुम्भकरण वध, सुलोचना सती",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  //   {
  //     cover: "https://dummyimage.com/720x400",
  //     day: "सोमवार",
  //     date: "11",
  //     month: "अप्रैल",
  //     tithi: "वैशाख कृष्ण पंचमी",
  //     title: "नारंतक वध, रावण वध, राज्य अभिषेक, महाआरती, रामलीला समापन",
  //     description:
  //       "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
  //     time: "",
  //   },
  // ];

  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-10 md:py-16 mx-auto">
        <div class="flex flex-col text-center w-full mb-10 md:mb-18">
          <h1 class="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            वार्षिक कार्यक्रम
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            ढाई कड़ी की रामलीला के वार्षिक आयोजित कार्यक्रम
          </p>
        </div>
        <div class="flex flex-wrap -m-4">
          {programs.map((program) => {
            return (
              <div class="p-5 md:w-1/3">
                <a href="">
                  <div class="relative bg-white h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                    <div class="absolute top-0 left-0">
                      <div class="w-44 h-8 absolute left-[-34px] top-[32px]">
                        <div class="h-full w-full bg-[#ff5528] text-white text-center leading-8 font-semibold transform -rotate-45">
                          Day {programs.indexOf(program) + 1}
                        </div>
                      </div>
                    </div>
                    <img
                      class="lg:h-48 md:h-36 w-full object-cover object-center"
                      src={program.cover}
                      alt="blog"
                    />
                    <div class="p-6">
                      <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                        {program.tithi}
                      </h2>
                      <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                        {program["title"]}
                      </h1>
                      <p class="leading-relaxed mb-3">
                        {program["description"]}
                      </p>
                      <div class="flex items-center flex-wrap hidden">
                        <a class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                          View more
                          <svg
                            class="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M5 12h14"></path>
                            <path d="M12 5l7 7-7 7"></path>
                          </svg>
                        </a>
                        <span class="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                          <svg
                            class="w-4 h-4 mr-1"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            viewBox="0 0 24 24"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                          {/* {program["time"]} */}
                          6k
                        </span>
                        <span class="text-gray-400 inline-flex items-center leading-none text-sm">
                          <svg
                            class="w-4 h-4 mr-1"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            viewBox="0 0 24 24"
                          >
                            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                          </svg>
                          6
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>

        <div class="flex flex-col text-center w-full mt-14 mb-8 md:mb-8">
          <h1 class="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            रामलीला मंचन
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            एकादशी से मुख्य रूप से रामलीला का मंचन रामलीला के रंगमंच पर किया
            जाता है।
          </p>
        </div>

        <div class="flex flex-wrap -m-4">
          {manchanPrograms.map((program) => {
            return (
              <div class="p-5 md:w-1/3">
                <a href="">
                  <div class="relative bg-white h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                    <div class="absolute top-0 left-0">
                      <div class="w-44 h-8 absolute left-[-34px] top-[32px]">
                        <div class="h-full w-full bg-[#ff5528] text-white text-center leading-8 font-semibold transform -rotate-45">
                          Day {7 + manchanPrograms.indexOf(program)}
                        </div>
                      </div>
                    </div>
                    <img
                      class="lg:h-48 md:h-36 w-full object-cover object-center"
                      src={program.cover}
                      alt="blog"
                    />
                    <div class="p-6">
                      <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                        {program.tithi}
                      </h2>
                      <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                        {program["title"]}
                      </h1>
                      <p class="leading-relaxed mb-3">
                        {program["description"]}
                      </p>
                      <div class="flex items-center flex-wrap hidden">
                        <a class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                          View more
                          <svg
                            class="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M5 12h14"></path>
                            <path d="M12 5l7 7-7 7"></path>
                          </svg>
                        </a>
                        <span class="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                          <svg
                            class="w-4 h-4 mr-1"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            viewBox="0 0 24 24"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                          {/* {program["time"]} */}
                          6k
                        </span>
                        <span class="text-gray-400 inline-flex items-center leading-none text-sm">
                          <svg
                            class="w-4 h-4 mr-1"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            viewBox="0 0 24 24"
                          >
                            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                          </svg>
                          6
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Programs;
