import React from "react";

export default function VideoGallery() {
  const youtubeVideos = [
    {
      youtubeVideoId: "pjDcKkIlnnM",
      title:
        "अहिरावण वध, नारंतक वध, रावण वध, राज्य अभिषेक, महाआरती, रामलीला समापन | ढाई कड़ी की रामलीला | पाटोन्दा रामलीला 2024 | Day #10",
      release: "28 अप्रैल 2024 (रविवार) [वैशाख कृष्ण पंचमी]",
    },
    {
      youtubeVideoId: "8JZeBt58Px4",
      title:
        "लक्ष्मण शक्ति, कुम्भकरण वध, मेघनाथ वध, सुलोचना सती | ढाई कड़ी की रामलीला | पाटोन्दा रामलीला 2024 | Day #9",
      release: "27 अप्रैल 2024 (शनिवार) [वैशाख कृष्ण चतुर्थी]",
    },
    {
      youtubeVideoId: "4-qD5psiE4c",
      title:
        "लंका की बढ़ाई, विभीषण शरणागत, लंका की चढाई | पाटोन्दा रामलीला 2024 | Day #8 | Part 2",
      release: "26 अप्रैल 2024 (शुक्रवार) [वैशाख कृष्ण तृतीया]",
    },
    {
      youtubeVideoId: "--Pq11_Hczk",
      title:
        "लंका की बढ़ाई, विभीषण शरणागत, लंका की चढाई| पाटोन्दा रामलीला 2024 | Day #8 | Part 1",
      release: "26 अप्रैल 2024 (शुक्रवार) [वैशाख कृष्ण तृतीया]",
    },
    {
      youtubeVideoId: "nF58gu-UvwM",
      title:
        "हनुमान - सीता संवाद, अशोक वाटिका उजाड़ना, अक्षय कुमार वध, लंका दहन | पाटोन्दा रामलीला 2024 | Day #7",
      release: "25 अप्रैल 2024 (गुरुवार) [वैशाख कृष्ण द्वितीया]",
    },
    {
      youtubeVideoId: "zYh6tLakkLs",
      title:
        "सुग्रीव राज्याभिषेक,सुग्रीव द्वारा सीता की खोज,हनुमान-लंकिनी संवाद | पाटोन्दा रामलीला 2024 | Day #6",
      release: "24 अप्रैल 2024 (बुधवार) [वैशाख कृष्ण प्रतिपदा]",
    },
    {
      youtubeVideoId: "l9Gu-IKmXLo",
      title:
        "बाली द्वारा दुंदुभी वध, शबरी प्रसंग, राम - सुग्रीव मित्रता, बाली वध | पाटोन्दा रामलीला 2024 | Day #5",
      release: "23 अप्रैल 2024 (मंगलवार) [चैत्र शुक्ल पूर्णिमा]",
    },
    {
      youtubeVideoId: "iJ4lusTkxxQ",
      title:
        "सीता हरण,जटायु उद्धार | पाटोन्दा रामलीला 2024 | ढाई कड़ी की रामलीला पाटोन्दा | Day #4 | Part 2",
      release: "22 अप्रैल 2024 (सोमवार) [चैत्र शुक्ल चतुर्दशी]",
    },
    {
      youtubeVideoId: "DxD6GrAD9hw",
      title:
        "सीता हरण,जटायु उद्धार | पाटोन्दा रामलीला 2024 | ढाई कड़ी की रामलीला पाटोन्दा | Day #4 | Part 1",
      release: "22 अप्रैल 2024 (सोमवार) [चैत्र शुक्ल चतुर्दशी]",
    },
    {
      youtubeVideoId: "ni-A_fRYJxk",
      title: "राज तिलक की तैयारी, राम वनवास | पाटोन्दा रामलीला 2024 | Day #2",
      release: "20 अप्रैल 2024 (शनिवार) [चैत्र शुक्ल द्वादशी]",
    },
    {
      youtubeVideoId: "5_rPgmO9dio",
      title:
        "सीता स्वयंवर, लक्ष्मण - परशुराम संवाद | पाटोन्दा रामलीला 2024 | Day #1",
      release: "19 अप्रैल 2024 (शुक्रवार) [चैत्र शुक्ल एकादशी]",
    },
    {
      youtubeVideoId: "1Ip_O4NNN-A",
      title:
        "अहिरावण वध, नरांतक वध, रावण वध, श्री राम राज तिलक, महाआरती, रामलीला समापन - Day 10",
      release: "09 अप्रैल 2023 (रविवार)",
    },
    {
      youtubeVideoId: "Ys36zAF004I",
      title: "अंगद रावण संवाद, लक्ष्मण शक्ति, मेघनाद वध, कुम्भकर्ण वध - Day 9",
      release: "09 अप्रैल 2023 (रविवार)",
    },
    {
      youtubeVideoId: "1ETzRYDuMrE",
      title:
        "सुग्रीव लंका पर चढाई हेतु वानर दल रवाना,समुन्द्र पर रामेश्वर महादेव की स्थापना, सेतु निर्माण - Day 8",
      release: "09 अप्रैल 2023 (रविवार)",
    },
    {
      youtubeVideoId: "LOt_KblzXNs",
      title:
        "हनुमान जी माता सीता वार्तालाप, अशोक वाटिका उजाड़ना, लंका दहन, जंबूमाली वध, अक्षय कुमार वध - Day 7 (Part 2)",
      release: "08 अप्रैल 2023 (शनिवार)",
    },
    {
      youtubeVideoId: "2KdFdTqcN9Q",
      title:
        "हनुमान जी माता सीता वार्तालाप, अशोक वाटिका उजाड़ना, लंका दहन, जंबूमाली वध, अक्षय कुमार वध - Day 7 (Part 1)",
      release: "08 अप्रैल 2023 (शनिवार)",
    },
    {
      youtubeVideoId: "EdTQFB3Si-c",
      title:
        "सुग्रीव राज्याभिषेक, सुग्रीव द्वारा सीता की खोज, हनुमान जी लंका प्रवेश - Day 6",
      release: "07 अप्रैल 2023 (शुक्रवार)",
    },
    {
      youtubeVideoId: "qJTzSOgYnbY",
      title:
        "बाली द्वारा दुंदुभी वध, शबरी प्रसंग, सुग्रीव - राम मित्रता, बाली सुग्रीव युद्ध, बाली वध - Day 5",
      release: "06 अप्रैल 2023 (गुरुवार)",
    },
    {
      youtubeVideoId: "hD3bNBNL6b8",
      title:
        "विराट राक्षश वध, शूर्पणखा संवाद, खर-दूषण वध व चौदह हजार राक्षस वध, सीता हरण, जटायु उद्धार - Day 4",
      release: "05 अप्रैल 2023 (बुधवार)",
    },
    {
      youtubeVideoId: "hHoohfYy40o",
      title:
        "प्रजा द्वारा श्रीराम को मनाना, दशरथ का देहवासन, केवट प्रसंग, राम भरत मिलाप - Day 3",
      release: "04 अप्रैल 2023 (मंगलवार)",
    },
    {
      youtubeVideoId: "htWIH5oFBDc",
      title:
        "लक्ष्मण व परशुराम जी संवाद, राज तिलक तथा वनवास - ढाई कड़ी की रामलीला पाटोन्दा - Day 2",
      release: "03 अप्रैल 2023 (सोमवार)",
    },
    {
      youtubeVideoId: "jVqKfWxnxZ4",
      title:
        "राम जन्म उत्सव (लीला मंचन धनुष यज्ञ राम विवाह) - ढाई कड़ी की रामलीला पाटोन्दा - Day 1",
      release: "02 अप्रैल 2023 (रविवार)",
    },
    {
      youtubeVideoId: "SsJ8xT7BT5w",
      title:
        "श्री राम बारात - ढाई कड़ी की रामलीला पाटोन्दा / 29 मार्च 2023 (बुधवार) (चैत्र शुक्ल अष्टमी)",
      release: "29 मार्च 2023 (बुधवार)",
    },
    {
      youtubeVideoId: "_szh7rROf9o",
      title: "रावण और इंद्रजीत संवाद",
      release: "ढाई कड़ी की रामलीला पाटोन्दा",
    },
  ];

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 md:py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-10 md:mb-18">
          <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            वीडियो गेलरी
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            ढाई कड़ी की रामलीला मंचन के वीडियो
          </p>
        </div>

        <div className="flex flex-wrap -m-4">
          {youtubeVideos.map((video, idx) => {
            return (
              <div className="p-5 md:w-1/3">
                <div
                  className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300"
                  key={idx}
                >
                  <iframe
                    className="w-full rounded-lg h-[225px] md:h-[250px]"
                    src={
                      "https://www.youtube.com/embed/" + video["youtubeVideoId"]
                    }
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                  <div className="mt-3 px-1">
                    <h1 className="font-bold text-black text-lg">
                      {video["title"]}
                    </h1>
                    <p className="text-sm font-medium text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis">
                      {video["release"]}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
