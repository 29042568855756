import React, { useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { baseCDNUrl } from "../../../utils/endpoints";

function HomePageSlider(props) {
  const { setHeight } = props;
  const elementRef = useRef(null);

  const sliders = [
    "ramleela/2k24/hindu-college-delhi/DSC00797.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00800.webp",
    "ramleela/2k24/hindu-college-delhi/DSC00804.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00809.JPG",
    // "ramleela/2k24/hindu-college-delhi/DSC00814.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00833.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00835.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00837.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00822.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00829.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00838.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00840.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00841.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00842.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00843.JPG",
    "ramleela/2k24/hindu-college-delhi/DSC00850.JPG",
  ];

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  return (
    <section className="relative" ref={elementRef}>
      <Carousel
        showArrows={true}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
      >
        {/* <div>
          <img
            src={baseCDNUrl + "slider/images/pic1.webp"}
            alt="हिन्दू कॉलेज 125th वर्षगाँठ समारोह में पाटोन्दा की अंतर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन"
          />
          <p className="legend">
            ढाई कड़ी की रामलीला @हिंदू कॉलेज दिल्ली #12/02/2024 at 3:00 PM
          </p>
        </div> */}
        <div>
          <img
            src={baseCDNUrl + "slider/images/img20230122wa0040.jpg"}
            alt="पाटोन्दा की ढाई कड़ी की रामलीला में भरत और कौशल्या का अभिनय"
          />
          <p className="legend">भरत और कौशल्या</p>
        </div>
        <div>
          <img
            src={baseCDNUrl + "slider/images/img20230126214240.jpg"}
            alt="पाटोन्दा की ढाई कड़ी की रामलीला में श्री राम और हनुमान जी का अभिनय"
          />
          <p className="legend">श्री राम और हनुमान जी</p>
        </div>
        <div>
          <img
            src={baseCDNUrl + "slider/images/photo20230204232542.jpg"}
            alt="पाटोन्दा की ढाई कड़ी की रामलीला में श्री राम, लक्ष्मण, सीता और भरत का अभिनय"
          />
          <p className="legend">श्री राम, लक्ष्मण, सीता और भरत</p>
        </div>
        <div>
          <img
            src={baseCDNUrl + "slider/images/photo20230204232544.jpg"}
            alt="पाटोन्दा की ढाई कड़ी की रामलीला में श्री राम, लक्ष्मण और हनुमान जी का अभिनय"
          />
          <p className="legend">श्री राम, लक्ष्मण और हनुमान जी</p>
        </div>
        <div>
          <img
            src={baseCDNUrl + "slider/images/received_692040148465587.jpg"}
            alt="पाटोन्दा की ढाई कड़ी की रामलीला में श्री राम, लक्ष्मण और शबरी का अभिनय"
          />
          <p className="legend">श्री राम, लक्ष्मण और शबरी</p>
        </div>
        {/* {sliders.map((slide) => {
          return (
            <div>
              <img
                src={baseCDNUrl + slide}
                alt="ढाई कड़ी की रामलीला @हिंदू कॉलेज दिल्ली #12/02/2024"
              />
              <p className="legend">
                ढाई कड़ी की रामलीला @हिंदू कॉलेज दिल्ली #12/02/2024
              </p>
            </div>
          );
        })} */}
      </Carousel>
    </section>
  );
}

export default HomePageSlider;
