import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function Modal(props) {
  const { isOpen, closeModal } = props;
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="relative w-full flex flex-col justify-center items-center max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                style={{ zIndex: "500 !important" }}
              >
                {/* <div className="absolute w-48">
                  <img src="/assets/imgs/donate.png" />
                </div> */}

                <Dialog.Title
                  as="h2"
                  className="text-2xl font-medium leading-6 text-gray-900"
                >
                  Donate Now
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-md text-gray-500 text-center">
                    श्री लोक रामलीला मंडल पाटोन्दा के सयोजक (श्री लोकेश दाधीच) व
                    कोषाध्यक्ष (श्री रामप्रसाद सेन) से सम्पर्क कर मोबाइल न. पर
                    विशेष जानकारी प्राप्त कर भामाशाह राशि की रसीद प्राप्त करे।
                  </p>
                </div>

                <div className="my-4">
                  <p>
                    श्री लोकेश दाधीच [सयोजक] :{" "}
                    <a href="tel:919829122761">+91 98291 22761</a>
                  </p>
                  <p>
                    श्री रामप्रसाद सेन [कोषाध्यक्ष] :{" "}
                    <a href="tel:919929324083">+91 99293 24083</a>
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    🙏🙏 धन्यवाद 🙏🙏
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
