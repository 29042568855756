import React from "react";
import confetti from "canvas-confetti";
import InfinitePolaroidGallery from "../../components/InfinitePolaroidGallery/InfinitePolaroidGallery";
import "./style.css";

function MahotsavHinduCollege() {
  var duration = 15 * 1000;
  var animationEnd = Date.now() + duration;
  var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  var interval = setInterval(function () {
    var timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    var particleCount = 25 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    });
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);

  const programs = [
    "रावण - मंदोदरी संवाद",
    "लक्षमण - मेघनाथ युद्ध ( शक्ति)",
    "हनुमान जी का सुषेन वैध को लाना।",
    "हनुमान जी का संजीवनी बूटी हेतु द्रोणागिरी पर्वत पर जाना।",
    "हनुमान जी और कालनेमि युद्ध",
    "हनुमान जी द्वारा मकरी का उद्धार",
    "हनुमान जी द्वारा कालनेमि वध",
    "हनुमान जी और भरत जी का मिलान",
    "लक्ष्मण जी को संजीवनी पिलाना ओर स्वस्थ हो जाना।",
  ];

  const youtubeVideos = [
    {
      youtubeVideoId: "acKJhZ-dJDU",
      title:
        "महोत्सव - हिंदू कॉलेज की 125वीं वर्षगांठ समारोह | हिंदू कॉलेज दिल्ली",
      release: "12 फरवरी 2024 (सोमवार)",
    },
    {
      youtubeVideoId: "Ys36zAF004I",
      title: "अंगद रावण संवाद, लक्ष्मण शक्ति, मेघनाद वध, कुम्भकर्ण वध - Day 9",
      release: "12 फरवरी 2024 (सोमवार)",
    },
  ];

  return (
    <section className="bg-pink-50 px-5 py-16 mx-auto">
      <div className="flex flex-col text-center w-full mb-10 md:mb-20">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          हिन्दू कॉलेज 125<sup>th</sup> वर्षगाँठ समारोह
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px] mb-4 md:mb-20">
          हिंदू कॉलेज में पाटोन्दा की अंतर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन
        </p>

        <h1 className="ramleelaTitle text-5xl md:text-8xl text-blue-900 font-medium mb-1 md:mb-2">
          Mahotsav
        </h1>
      </div>
      <div className="md:grid md:grid-cols-2 my-[3%] px-4 md:px-32">
        <div className="flex justify-center">
          <div className="relative w-full h-[300px] md:h-full">
            <InfinitePolaroidGallery />
          </div>
        </div>
        <div className="">
          <p className="text-gray-600 text-justify text-lg mb-5">
            <b>दिल्ली।</b> हिंदू कॉलेज के 125 वें स्थापना दिवस पर आयोजित महोत्सव
            शृंखला में दिनांक: 12 फरवरी 2024, सोमवार को राजस्थान के पाटोंदा की
            प्रसिद्ध अन्तर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन हुआ।
          </p>
          <p className="text-gray-600 text-justify text-lg mb-5">
            रामलीला का आयोजन विभिन्न दृश्यों में किया गया जिसकी शुरुआत गणेश
            वंदना के साथ हुई। “धन धन भारत शोभा धाम” शीर्षक भारत भूमि के गुणगान
            के बाद क्रमशः रावण मंदोदरी संवाद, लक्ष्मण मेघनाद युद्ध एवं लक्षण को
            शक्ति बाण लगने पर राम के विलाप और अंत में हनुमान जी द्वारा संजीवनी
            बूटी लाने के प्रसंग का भावपूर्ण मंचन किया गया। इस रामलीला मंचन में
            लोकेश दाधीच ने गणेश वंदना, धर्मवीर गोचर ने भारतमाता वंदना के बाद
            अभिनेताओं में बृजसुन्दर ने राम, दीनबंधु ने लक्ष्मण, वेदप्रकाश ने
            हनुमान, सुशील कुमार ने लंकापति रावण, रामेश्वर कटारिया ने मंदोदरी,
            रामप्रसाद ने मेघनाथ, चौथमल ने सुषेण वैद्य, संदीप ने कालनेमि, मुकुट
            ने मकरी, धर्मवीर ने भरत का अभिनय किया। अन्य सहायक कलाकारों और नेपथ्य
            सहयोगियों में बृजराज,अमरलाल, श्रवण, सज्जन, देवकीनंदन, इंद्रजीत, चेतन
            नागर, भारत नागर और सुरेश कुमार थे।
          </p>
          <p className="text-gray-600 text-justify text-lg mb-5">
            प्रारंभ में हिंदू कॉलेज की प्राचार्य डॉ. अंजू श्रीवास्तव, उप
            प्राचार्य डॉ. रीना जैन, महोत्सव शृंखला के संयोजक डॉ. रामेश्वर राय
            एवं कॉलेज की प्रधानमंत्री मधुलिका ने दीप प्रज्वलन किया।
          </p>
          <p className="text-gray-600 text-justify text-lg mb-5">
            रामलीला के संयोजक डा पल्लव ने कलाकार परिचय दिया। उन्होंने बताया कि
            पाटोंदा की इस लोक रामलीला का आयोजन विगत 160 वर्षों से अनवरत हो रहा
            है। इसकी खास बात यह है कि इसमें मंचन कर रहे सभी कलाकार खेतों में हल
            चलाने वाले मिट्टी की सुगंध से जुड़े अनगढ़ कलाकार हैं।
          </p>
          <p className="text-gray-600 text-justify text-lg mb-5">
            हाड़ौती और डिंगल की मिश्रित बोली में गाए छंदों और लोक संगीत की इस
            प्रस्तुति को अनूठा बनाते हैं। अयोजन के अंत में प्राचार्य डॉ. अंजू
            श्रीवास्तव द्वारा सभी कलाकारों को स्नेहिल भेंट प्रदान कर कृतज्ञता
            ज्ञापन के साथ कार्यक्रम का समापन हुआ।
          </p>
        </div>
      </div>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-20 mx-auto">
          <h1 className="text-3xl font-medium title-font text-gray-900 mb-12 text-center">
            रामलीला के कार्यक्रम (लीला मंचन)
          </h1>
          <div className="flex flex-wrap -m-4">
            {programs.map((program) => {
              return (
                <div className="p-4 md:w-1/3 w-full">
                  <div className="borderAnimation h-full bg-orange-100 p-8 rounded">
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="block w-5 h-5 text-gray-400 mb-4"
                      viewBox="0 0 975.036 975.036"
                    >
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                    </svg>
                    <p className="leading-relaxed mb-6">{program}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-20 mx-auto">
          <h1 className="text-3xl font-medium title-font text-gray-900 mb-12 text-center">
            रामलीला के कलाकार
          </h1>
          <p className="text-center text-lg md:mx-[275px]">
            लोकेश दाधीच, धर्मवीर गोचर, बृजसुन्दर केवट, दीनबंधु केवट, वेदप्रकाश
            शर्मा, सुशील कुमार, रामेश्वर कटारिया, रामप्रसाद सेन, चौथमल गोचर,
            संदीप शर्मा, मुकुट गोचर, बृजराज नंदवाना, अमरलाल मालव, श्रवण
            प्रजापति, सज्जन रावल, देवकीनंदन शर्मा, इंद्रजीत केवट, चेतन नागर,
            भारत नागर
          </p>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-20 mx-auto">
          <h1 className="text-3xl font-medium title-font text-gray-900 mb-12 text-center">
            फ़ोटो और वीडियो गेलरी
          </h1>
          <div class="flex flex-wrap justify-center w-full">
            {youtubeVideos.map((video, idx) => {
              return (
                <div class="py-5 md:p-5">
                  <div
                    className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300"
                    key={idx}
                  >
                    <iframe
                      className="w-full rounded-lg h-[225px] md:h-[250px]"
                      src={
                        "https://www.youtube.com/embed/" +
                        video["youtubeVideoId"]
                      }
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    <div className="mt-3 px-1">
                      <h1 className="font-bold text-black text-lg">
                        {video["title"]}
                      </h1>
                      <p class="text-sm font-medium text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis">
                        {video["release"]}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </section>
  );
}

export default MahotsavHinduCollege;
