import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { baseCDNUrl } from "../../utils/endpoints";
import FAQSection from "../../components/FAQSection/FAQSection";
import Accordion from "../../components/Accordion/Accordion";

function Faqs() {
  return (
    <section className="bg-pink-50 px-5 py-16 mx-auto">
      <div className="flex flex-col text-center w-full mb-10 md:mb-20">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          पूछे जाने वाले प्रश्न
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
          रामलीला के बारे में पूछे जाने वाले प्रश्न
        </p>
      </div>
      <div className="relative my-[3%] md:px-32">
        <div className="accordion">
          <Accordion
            title="ढाई कड़ी की रामलीला के रचियता कौन थे?"
            description={
              <p>
                इस विश्वविख्यात ढाई कड़ी की रामलीला के रचयिता परम् पूजनीय
                साकेतधामवासी आदरणीय ग्राम गुरु{" "}
                <mark>
                  <b>श्री गणपतलाल जी दाधिच</b>
                </mark>{" "}
                है। इनका जन्म पाटौन्दा ग्राम में हुआ। तथा काशी में इन्होंने
                शिक्षा प्राप्त की इनके द्वारा वाल्मीकि रामायण व तुलसीदास जी
                द्वारा रचित रामचरितमानस के आधार पर शुद्ध हाड़ौती भाषा मे यह ढाई
                कड़ी में लिखी चौपाइयां खड़ी बोली एवं रीला तर्ज में संगीतबद्ध की
                गई हैं। जिसके अनुसार इस रामलीला का मंचन पाटौन्दा ग्राम मे लगभग
                160 वर्षो से किया जाता है।
              </p>
            }
          />

          <Accordion title='"ढाई कड़ी" का मतलब क्या होता है?' />

          <Accordion
            title='"तान" क्या होती है?'
            description={
              <p>
                संगीत में समान स्वरों को उच्च स्वरों में गया जाता हैं उसी को तान
                कहते हैं। ढाई कड़ी में लिखी चौपाइयां खड़ी बोली एवं रीला तर्ज में
                संगीतबद्ध की गई हैं। इसकी प्रथम पंक्ति तान कहलाती हैं।
                <br />
                उदाहरण : अजी सवरू लक्ष्मीनाथ अवतार <br />
                हमारा बेड़ा लगाओ प्रभू पार।।
                <br />
                इसको प्रत्येक छंद के बाद दोहराया जाता हैं ।
              </p>
            }
          />

          <Accordion
            title="इस रामलीला मैं अखाडा क्यों बांधा जाता है।"
            description={
              <>
                <p>
                  एक बार अकस्मात रामलीला का मंचन करते समय लक्ष्मण शक्ति का संवाद
                  था मेघनाथ द्वारा लक्ष्मण जी के ऊपर ब्रह्म शक्ति प्रहार किया
                  गया लक्ष्मण के पात्र पर किसी जादुई शक्ति के प्रहार करने से
                  लक्ष्मण के पात्र की उसी समय मृत्यु हो गयी ऐसी स्थिति में भी
                  कथा प्रेमियों ने रामलीला मंचन बंद नहीं किया गया सुबह रावण वध
                  के पश्चात ही अंतिम संस्कार किया गया हैं।
                </p>
                <br />
                <p>
                  यह रामलीला निर्विघ्न रूप में चलती रहे किसी बुरे विचारो या
                  नकारात्मक शक्तियो, क्रियाओ नष्ट करने के लिए मंत्रस्वरूप हमारी
                  रामलीला में अखाड़ा बांधने की परंपरा हैं। इसीलिए इसमें अखाड़े की
                  तान गायी जाती हैं।
                </p>
                <br />

                <blockquote cite="https://www.dhaikadikiramleela.com/manuscript/akhara-taan">
                  एक खूंट प दुर्गा बिराज दूजा भवानी माई खूंट तीजां प शारदा माई ॥
                  <br />
                  ईर अखड़ा मं कर बखड़ो जीन खाव कालक्या माई ॥ ३ ॥
                </blockquote>
                <a
                  className="mt-1 text-blue-400 underline"
                  href="https://www.dhaikadikiramleela.com/manuscript/akhara-taan"
                >
                  Read more..
                </a>
              </>
            }
          />

          <Accordion
            title="यह रामलीला कोनसी बोली में लिखी गयी हैं।"
            description={
              <p>
                यह रामलीला राजस्थानी भाषा की हाड़ौती उपभाषा में लिखी गई है।
                हाड़ौती पर ऊंचे स्वर वाली प्राचीन डिंगल भाषा का प्रभाव है।इसमें
                हिंदी के शुद्ध शब्दों को लोक भाषा में गाया जाता हैं।
              </p>
            }
          />

          <Accordion title="रामलीला में कहा कहा से संदर्भ लेकर रामलीला लिखी गयी है।" />

          <Accordion title="रामलीला में कौन कोनसे वाध यन्त्र उपयोग में आते थे और आते है" />

          <Accordion title="ढाई कड़ी की रामलीला में भारत माता की वंदना के रचियता कौन थे?" />

          <Accordion title="ढाई कड़ी की रामलीला में अखाड़ा की तान के रचियता कौन थे?" />
        </div>
      </div>
    </section>
  );
}

export default Faqs;
