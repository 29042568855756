import React from "react";
import { baseCDNUrl } from "../../utils/endpoints";
import ReactGA from "react-ga4";

function Footer(props) {
  const { openModal } = props;

  return (
    <footer
      aria-label="Site Footer"
      className="relative bg-white lg:grid lg:grid-cols-5"
    >
      <div className="relative hidden md:block h-32 lg:col-span-2 lg:h-full">
        <img
          src={baseCDNUrl + "imgs/ram.webp"}
          alt="Ram Darbar"
          className="absolute inset-0 object-contain w-full h-full z-100"
        />
      </div>

      <div className="px-4 py-10 md:py-16 sm:px-6 lg:col-span-3 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div>
            <p>
              <span className="text-xs tracking-wide text-gray-500 uppercase">
                कॉल करे
              </span>

              <a
                href="tel:+918209446178"
                className="block text-2xl font-medium text-gray-900 hover:opacity-75 sm:text-3xl"
              >
                +9182094 46178{" "}
                <span className="text-[13px]">
                  (श्री चेतन नागर) [मीडिया प्रभारी]
                </span>
              </a>
            </p>

            <ul className="mt-8 space-y-1 text-sm text-gray-700">
              <li>Monday to Friday: 10am - 7pm</li>
              <li>Weekend: 10am - 5pm</li>
            </ul>

            <ul className="flex gap-6 mt-8">
              <li>
                <a
                  href="https://www.facebook.com/dhaikadikiramleela"
                  target="_blank"
                  title="फेसबुक पर ढाई कड़ी की रामलीला पाटोन्दा"
                  rel="noreferrer"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Facebook</span>

                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://whatsapp.com/channel/0029VaFD42d0gcfD57VtGg3x"
                  target="_blank"
                  title="वाट्सऐप पर ढाई कड़ी की रामलीला पाटोन्दा"
                  rel="noreferrer"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">WhatsApp</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 50 50"
                  >
                    <path d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"></path>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/dhaikadikiramleela/"
                  title="इंस्टाग्राम पर ढाई कड़ी की रामलीला पाटोन्दा"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Instagram</span>

                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.threads.net/@dhaikadikiramleela"
                  title="थ्रेड्स पर ढाई कड़ी की रामलीला पाटोन्दा"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Threads</span>

                  <svg
                    aria-label="Threads"
                    className="w-6 h-6"
                    viewBox="0 0 192 192"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="x19hqcy"
                      d="M141.537 88.9883C140.71 88.5919 139.87 88.2104 139.019 87.8451C137.537 60.5382 122.616 44.905 97.5619 44.745C97.4484 44.7443 97.3355 44.7443 97.222 44.7443C82.2364 44.7443 69.7731 51.1409 62.102 62.7807L75.881 72.2328C81.6116 63.5383 90.6052 61.6848 97.2286 61.6848C97.3051 61.6848 97.3819 61.6848 97.4576 61.6855C105.707 61.7381 111.932 64.1366 115.961 68.814C118.893 72.2193 120.854 76.925 121.825 82.8638C114.511 81.6207 106.601 81.2385 98.145 81.7233C74.3247 83.0954 59.0111 96.9879 60.0396 116.292C60.5615 126.084 65.4397 134.508 73.775 140.011C80.8224 144.663 89.899 146.938 99.3323 146.423C111.79 145.74 121.563 140.987 128.381 132.296C133.559 125.696 136.834 117.143 138.28 106.366C144.217 109.949 148.617 114.664 151.047 120.332C155.179 129.967 155.42 145.8 142.501 158.708C131.182 170.016 117.576 174.908 97.0135 175.059C74.2042 174.89 56.9538 167.575 45.7381 153.317C35.2355 139.966 29.8077 120.682 29.6052 96C29.8077 71.3178 35.2355 52.0336 45.7381 38.6827C56.9538 24.4249 74.2039 17.11 97.0132 16.9405C119.988 17.1113 137.539 24.4614 149.184 38.788C154.894 45.8136 159.199 54.6488 162.037 64.9503L178.184 60.6422C174.744 47.9622 169.331 37.0357 161.965 27.974C147.036 9.60668 125.202 0.195148 97.0695 0H96.9569C68.8816 0.19447 47.2921 9.6418 32.7883 28.0793C19.8819 44.4864 13.2244 67.3157 13.0007 95.9325L13 96L13.0007 96.0675C13.2244 124.684 19.8819 147.514 32.7883 163.921C47.2921 182.358 68.8816 191.806 96.9569 192H97.0695C122.03 191.827 139.624 185.292 154.118 170.811C173.081 151.866 172.51 128.119 166.26 113.541C161.776 103.087 153.227 94.5962 141.537 88.9883ZM98.4405 129.507C88.0005 130.095 77.1544 125.409 76.6196 115.372C76.2232 107.93 81.9158 99.626 99.0812 98.6368C101.047 98.5234 102.976 98.468 104.871 98.468C111.106 98.468 116.939 99.0737 122.242 100.233C120.264 124.935 108.662 128.946 98.4405 129.507Z"
                    ></path>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://twitter.com/dhaikdiramleela"
                  title="ट्विटर पर ढाई कड़ी की रामलीला पाटोन्दा"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Twitter</span>

                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.youtube.com/@dhaikadikiramleela"
                  title="यूट्यूब पर ढाई कड़ी की रामलीला पाटोन्दा"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">YouTube</span>

                  <svg
                    height="24px"
                    width="24px"
                    viewBox="0 0 461.001 461.001"
                    className="w-6 h-6"
                    fill="currentColor"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <g>
                        <path
                          style={{ fill: "#374151" }}
                          d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://hi.wikipedia.org/wiki/ढाई_कड़ी_की_रामलीला_पाटोन्दा"
                  title="विकिपीडिया पर ढाई कड़ी की रामलीला पाटोन्दा"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Wikipedia</span>

                  <svg
                    height="24px"
                    width="24px"
                    viewBox="0 0 458.723 458.723"
                    className="w-6 h-6"
                    fill="currentColor"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        style={{ fill: "#374151" }}
                        d="M455.724,93.489H367.32h-3v3v9.613v3h3h6.143c7.145,0,13.588,3.667,17.237,9.81 c3.648,6.143,3.786,13.555,0.368,19.829l-98.3,180.432l-44.769-106.727l42.169-77.382c8.727-16.014,25.477-25.962,43.714-25.962 h1.992h3v-3v-9.613v-3h-3H247.47h-3v3v9.613v3h3h6.143c7.145,0,13.588,3.667,17.237,9.81c3.648,6.143,3.786,13.555,0.368,19.829 l-30.587,56.143L213.372,129.9c-1.976-4.71-1.487-9.852,1.341-14.105s7.38-6.693,12.488-6.693h6.988h3v-3v-9.613v-3h-3H128.46h-3v3 v9.613v3h3h1.454c20.857,0,39.546,12.428,47.615,31.661l40.277,96.018l-44.887,82.392L93.523,129.9 c-1.976-4.71-1.487-9.852,1.341-14.105s7.38-6.693,12.488-6.693h10.737h3v-3v-9.613v-3h-3H3H0v3v9.613v3h3h7.064 c20.857,0,39.547,12.428,47.615,31.661l91.526,218.191c1.601,3.816,5.313,6.282,9.458,6.282c3.804,0,7.163-1.998,8.986-5.344 l11.939-21.91l45.582-83.646l43.884,104.617c1.601,3.816,5.313,6.282,9.458,6.282c3.804,0,7.163-1.998,8.986-5.344l11.939-21.91 l110.58-202.919c8.727-16.014,25.477-25.962,43.714-25.962h1.992h3v-3v-9.613v-3h-2.999V93.489z"
                      ></path>
                    </g>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://goo.gl/maps/h6cSytm5sNsgoHnK7"
                  title="गूगल मैप पर ढाई कड़ी की रामलीला पाटोन्दा"
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <span className="sr-only">Maps</span>

                  <svg
                    height="24px"
                    width="24px"
                    viewBox="-55.5 0 367 367"
                    preserveAspectRatio="xMidYMid"
                    className="w-6 h-6"
                    fill="currentColor"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <g>
                        <path
                          d="M70.5853976,271.865254 C81.1995596,285.391378 90.8598594,299.639537 99.4963338,314.50654 C106.870174,328.489419 109.94381,337.97007 115.333495,354.817346 C118.638014,364.124835 121.625069,366.902652 128.046515,366.902652 C135.045169,366.902652 138.219816,362.176756 140.672953,354.867852 C145.766819,338.95854 149.763988,326.815514 156.069992,315.343493 C168.443902,293.193112 183.819296,273.510299 198.927732,254.592287 C203.018698,249.238677 229.462067,218.047767 241.366994,193.437035 C241.366994,193.437035 255.999233,166.402027 255.999233,128.645368 C255.999233,93.3274168 241.569017,68.8321265 241.569017,68.8321265 L200.024428,79.9578224 L174.793197,146.408963 L168.552129,155.57215 L167.303915,157.231625 L165.64444,159.309576 L162.729537,162.628525 L158.56642,166.791642 L136.098575,185.09637 L79.928962,217.528279 L70.5853976,271.865254 Z"
                          fill="#374151"
                        >
                          {" "}
                        </path>
                        <path
                          d="M12.6120081,188.891517 C26.3207125,220.205084 52.7568668,247.730719 70.6431185,271.8869 L165.64444,159.352866 C165.64444,159.352866 152.260416,176.856717 127.981579,176.856717 C100.939355,176.856717 79.0920095,155.2619 79.0920095,128.032084 C79.0920095,109.359386 90.325932,96.5309245 90.325932,96.5309245 L25.8373003,113.811107 L12.6120081,188.891517 Z"
                          fill="#374151"
                        >
                          {" "}
                        </path>
                        <path
                          d="M166.705061,5.78651629 C198.256727,15.959818 225.262874,37.3165365 241.597878,68.8104812 L165.673301,159.28793 C165.673301,159.28793 176.907223,146.228586 176.907223,127.671329 C176.907223,99.8065834 153.443693,78.990998 128.09702,78.990998 C104.128433,78.990998 90.3620076,96.4659886 90.3620076,96.4659886 L90.3620076,39.4666386 L166.705061,5.78651629 Z"
                          fill="#374151"
                        >
                          {" "}
                        </path>
                        <path
                          d="M30.0148476,45.7654275 C48.8607087,23.2182162 82.0213432,0 127.736265,0 C149.915506,0 166.625695,5.82259183 166.625695,5.82259183 L90.2898565,96.5164943 L36.2054099,96.5164943 L30.0148476,45.7654275 Z"
                          fill="#374151"
                        >
                          {" "}
                        </path>
                        <path
                          d="M12.6120081,188.891517 C12.6120081,188.891517 0,164.194204 0,128.414485 C0,94.5972757 13.145926,65.0369799 30.0148476,45.7654275 L90.3331471,96.5237094 L12.6120081,188.891517 Z"
                          fill="#374151"
                        >
                          {" "}
                        </path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div className="grid gap-4 grid-cols-3 sm:grid-cols-2">
            <div>
              <p className="font-semibold md:font-medium text-gray-900">
                बाहरी कड़िया
              </p>

              <nav aria-label="Footer Navigation - Services" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a
                      href="/photo-gallery"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      फ़ोटो गेलरी
                    </a>
                  </li>

                  <li>
                    <a
                      href="/programs"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      वार्षिक कार्यक्रम
                    </a>
                  </li>

                  <li>
                    <a
                      href="/video-gallery"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      वीडियो गेलरी
                    </a>
                  </li>

                  <li>
                    <a
                      href="/news-and-articles"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      समाचार और लेख
                    </a>
                  </li>

                  <li>
                    <div
                      onClick={() => {
                        openModal();
                        // ReactGA.event({
                        //   event_name: "donate_now",
                        //   event_source: "footer",
                        //   action: "click",
                        // });
                        ReactGA.send({
                          hitType: "pageview",
                          page: "/history-of-ramleela",
                          title: "रामलीला का इतिहास",
                        });
                      }}
                      className="text-gray-700 transition hover:opacity-75 cursor-pointer"
                    >
                      दान करे
                    </div>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="block md:hidden">
              <p className="font-semibold md:font-medium text-gray-900">
                रामलीला कड़िया
              </p>

              <nav aria-label="Footer Navigation - Company" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a
                      href="/patoonda-ramleela-2024"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      पाटोन्दा रामलीला #2024
                    </a>
                  </li>
                  <li>
                    <a
                      href="/manuscript"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      रामलीला की पांडूलिपिया
                    </a>
                  </li>
                  <li>
                    <a
                      href="/assetstore"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      परिसंपत्ति भंडार
                    </a>
                  </li>
                  <li>
                    <a
                      href="/125-years-celebration-mahotsav-hindu-college"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      हिन्दू कॉलेज 125th वर्षगाँठ
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/faqs"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      पूछे जाने वाले प्रश्न
                    </a>
                  </li> */}
                </ul>
              </nav>
            </div>

            <div>
              <p className="font-semibold md:font-medium text-gray-900">
                संगठन
              </p>

              <nav aria-label="Footer Navigation - Company" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a
                      href="/history-of-ramleela"
                      className="text-gray-700 transition hover:opacity-75"
                      onClick={() =>
                        ReactGA.send({
                          hitType: "pageview",
                          page: "/history-of-ramleela",
                          title: "रामलीला का इतिहास",
                        })
                      }
                    >
                      रामलीला का इतिहास
                    </a>
                  </li>

                  <li hidden>
                    <a
                      href="/constitution"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      संविधान
                    </a>
                  </li>

                  <li>
                    <a
                      href="/faqs"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      पूछे जाने वाले प्रश्न
                    </a>
                  </li>

                  <li hidden>
                    <a
                      href="/committee"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      कमिटी मेंबर्स
                    </a>
                  </li>

                  <li>
                    <a
                      href="/artists"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      कलाकार
                    </a>
                  </li>

                  <li>
                    <a
                      href="/contact-us"
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      संपर्क करें
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="pt-6 md:pt-12 mt-6 md:mt-12 border-t border-gray-100">
          <div className="sm:flex sm:items-center sm:justify-between">
            <nav aria-label="Footer Navigation - Support">
              <ul className="flex flex-wrap gap-4 text-xs">
                <li>
                  <a
                    href="/terms-and-conditions"
                    className="text-gray-500 transition hover:opacity-75"
                  >
                    नियम और शर्तें
                  </a>
                </li>

                <li>
                  <a
                    href="/privacy-policy"
                    className="text-gray-500 transition hover:opacity-75"
                  >
                    गोपनीयता नीति
                  </a>
                </li>

                <li>
                  <a
                    href="/disclamer"
                    className="text-gray-500 transition hover:opacity-75"
                  >
                    अस्वीकरण
                  </a>
                </li>

                <li>
                  <a
                    href="/cookies"
                    className="text-gray-500 transition hover:opacity-75"
                  >
                    कुकीज़
                  </a>
                </li>
              </ul>
            </nav>

            <div>
              <div>
                <p className="mt-8 text-xs text-gray-500 sm:mt-0 mt-4">
                  &copy; 1864 - 2024 Dhai Kadi Ki Ramleela. All rights reserved.
                </p>
                <p
                  className="mt-2 text-xs text-gray-500 sm:mt-0 mt-1"
                  style={{ fontFamily: "'Roboto', sans-serif" }}
                >
                  Design &amp; Developed with{" "}
                  <i className="fa fa-heart text-[#f00]"></i> by{" "}
                  {/* Created with */}
                  <a
                    href="https://www.instagram.com/chetan.nager/"
                    target="_blank"
                    style={{ color: "blue", textDecoration: "underline" }}
                    rel="noreferrer"
                  >
                    Chetan Nagar
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
