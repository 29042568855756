import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { baseCDNUrl } from "../../utils/endpoints";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function AssetStore() {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        display: {
          right: [
          "iterateZoom",
          "slideshow",
          "download",
          "fullscreen",
          "thumbs",
          "close",],
          
        },
      },
    });
  }, []);

  return (
    <section className="bg-pink-50 px-5 py-16 mx-auto">
      <div class="flex flex-col text-center w-full mb-10 md:mb-20">
        <h1 class="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          परिसंपत्ति भंडार
        </h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
          यह छवियाँ ढाई कड़ी की रामलीला की कॉपीराइट के अधीन हैं।
        </p>
      </div>

      <div className="flex flex-wrap my-[3%] px-4  md:px-32">
        <div className="py-5 md:p-5 md:w-1/3">
          <div className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300">
            <LazyLoadImage
              className="object-contain w-full h-80 2xl:h-96 rounded-lg shadow-md aspect-auto cursor-pointer"
              src={baseCDNUrl + "assets/Ramleela-Logo.png"}
              alt="रामलीला का आधिकारिक लोगो"
              data-fancybox="gallery"
              data-src={baseCDNUrl + "assets/Ramleela-Logo.png"}
              data-caption="रामलीला का आधिकारिक लोगो"
              data-download-src={baseCDNUrl + "assets/Ramleela-Logo.png"}
            />
            <div className="mt-3 px-1">
              <h1 className="font-bold text-black text-lg">
                ढाई कड़ी की रामलीला का आधिकारिक प्रतीक चिन्ह
              </h1>
              <p className="text-sm font-medium text-gray-400">
                ढाई कड़ी की रामलीला पाटोन्दा का आधिकारिक प्रतीक चिन्ह। यह प्रतीक
                चिन्ह ढाई कड़ी की रामलीला पाटोन्दा का रजिस्टर्ड ट्रेडमार्क है।
              </p>
            </div>
          </div>
        </div>
        <div className="py-5 md:p-5 md:w-1/3">
          <div className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300">
            <LazyLoadImage
              className="object-contain w-full h-80 2xl:h-96 rounded-lg shadow-md aspect-auto cursor-pointer"
              src={baseCDNUrl + "assets/Ramleela-Pumplate.JPG"}
              alt="रामलीला का आधिकारिक लोगो"
              data-fancybox="gallery"
              data-src={baseCDNUrl + "assets/Ramleela-Pumplate.JPG"}
              data-caption="रामलीला का आधिकारिक लोगो"
              data-download-src={baseCDNUrl + "assets/Ramleela-Pumplate.JPG"}
            />
            <div className="mt-3 px-1">
              <h1 className="font-bold text-black text-lg">
                ढाई कड़ी की रामलीला का पैम्फेलेट
              </h1>
              <p className="text-sm font-medium text-gray-400">
                ढाई कड़ी की रामलीला पाटोन्दा का आधिकारिक प्रतीक चिन्ह। यह प्रतीक
                चिन्ह ढाई कड़ी की रामलीला पाटोन्दा का रजिस्टर्ड ट्रेडमार्क है।
              </p>
            </div>
          </div>
        </div>
        <div className="py-5 md:p-5 md:w-1/3">
          <div className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300">
            <LazyLoadImage
              className="object-contain w-full h-80 2xl:h-96 rounded-lg shadow-md aspect-auto cursor-pointer"
              src={baseCDNUrl + "assets/Ramleela-Pumplate.JPG"}
              alt="रामलीला का आधिकारिक लोगो"
              data-fancybox="gallery"
              data-src={baseCDNUrl + "assets/Ramleela-Pumplate.JPG"}
              data-caption="रामलीला का आधिकारिक लोगो"
              data-download-src={baseCDNUrl + "assets/Ramleela-Pumplate.JPG"}
            />
            <div className="mt-3 px-1">
              <h1 className="font-bold text-black text-lg">
                ढाई कड़ी की रामलीला का बैनर
              </h1>
              <p className="text-sm font-medium text-gray-400">
                ढाई कड़ी की रामलीला पाटोन्दा का आधिकारिक प्रतीक चिन्ह। यह प्रतीक
                चिन्ह ढाई कड़ी की रामलीला पाटोन्दा का रजिस्टर्ड ट्रेडमार्क है।
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AssetStore;
