import React from "react";
import Intro from "../../components/Home/Intro/Intro";
import NewsAndArticles from "../../components/Home/NewsAndArticles/NewsAndArticles";
import PhotoGallery from "../../components/Home/PhotoGallery/PhotoGallery";
import VideoGallery from "../../components/Home/VideoGallery/VideoGallery";
import Members from "../../components/Home/Members/Members";
import MarqueeArtistsWall from "../../components/Home/MarqueeArtistsWall/MarqueeArtistsWall";
import InquiryForRamleela from "../../components/InquiryForRamleela/InquiryForRamleela";
import Main from "../../components/Home/Main/Main";
import TagScroller from "../../components/TagScroller/TagScroller";

export default function Home() {
  return (
    <>
      <section className="bg-white">
        <div className="md:px-32">
          <div className="container mx-auto py-4 md:py-2">
            <TagScroller />
          </div>
        </div>
      </section>
      <Main />
      <Intro />
      <PhotoGallery />
      <VideoGallery />
      <NewsAndArticles />
      <MarqueeArtistsWall />
      {/* <Members /> */}
      <InquiryForRamleela />
    </>
  );
}
