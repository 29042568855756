import React from "react";
import { useEffect } from "react";
import { LiveTVVideoSrc } from "../../../core/globalProvider";

export default function LiveTV(props) {
  const { height } = props;

  useEffect(() => {
    console.log("LiveTV" + height.toString());
  }, []);

  return (
    <div>
      <iframe
        className="w-full"
        src={LiveTVVideoSrc}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        loading="lazy"
        style={{
          height: height + "px",
        }}
      ></iframe>
    </div>
  );
}
