import React from "react";
import { baseCDNUrl } from "../../../utils/endpoints";
import "./MarqueeArtistsWall.css";
import {
  artists,
  coArtists,
  harmoniumArtists,
  tablaArtists,
  manjiraArtists,
  violinArtists,
} from "../../../core/artistsProvider";

export default function MarqueeArtistsWall() {
  return (
    <section className="artistPanel text-gray-600 bg-[#ffedd5] body-font">
      <div className="container px-5 py-12 mx-auto">
        <div className="flex flex-col text-center w-full mb-10 md:mb-20">
          <h1 className="glow text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            कलाकार
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            रामलीला कार्यकारणी के कलाकार, वाद्य यंत्र वादक और सहयोगी सदस्य गण
          </p>
        </div>
        <div className="marqueeArtistsContainer mb-10">
          <article className="wrapper">
            <div className="marquee">
              <div className="marquee__group">
                {[
                  ...artists,
                  ...coArtists,
                  ...harmoniumArtists,
                  ...tablaArtists,
                  ...manjiraArtists,
                  ...violinArtists,
                ].map((artist) => {
                  return (
                    <div
                      className="portrait"
                      style={{
                        backgroundImage:
                          "url(" + baseCDNUrl + artist.avatar + ")",
                      }}
                      key={artist._id}
                    ></div>
                  );
                })}
              </div>

              <div aria-hidden="true" className="marquee__group">
                {[
                  ...artists,
                  ...coArtists,
                  ...harmoniumArtists,
                  ...tablaArtists,
                  ...manjiraArtists,
                  ...violinArtists,
                ].map((artist) => {
                  return (
                    <div
                      className="portrait"
                      style={{
                        backgroundImage:
                          "url(" + baseCDNUrl + artist.avatar + ")",
                      }}
                      key={artist._id}
                    ></div>
                  );
                })}
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
}
