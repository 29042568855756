import React, { useEffect } from "react";
import SliderItem from "./SliderItem";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "./Slider.css";

const Slider = ({ slides }) => {
  useEffect(() => {
    const swiper = new Swiper(".blog-slider", {
      direction: "vertical",
      spaceBetween: 30,
      effect: "fade",
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      mousewheel: {
        invert: false,
      },
      //autoHeight: true,
      pagination: {
        el: ".blog-slider__pagination",
        clickable: true,
      },
    });

    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <div className="blog-slider">
      <div className="blog-slider__wrp swiper-wrapper">
        {slides.map((slide, index) => (
          <SliderItem key={index} {...slide} />
        ))}
      </div>
      <div className="blog-slider__pagination z-10"></div>
    </div>
  );
};

export default Slider;
