import React from "react";
import NoticeBoardItem from "./NoticeBoardItem";
import VerticalSlider from "./VerticalSlider";
import "./NoticeBoard.css";

function NoticeBoard() {
  const notices = [
    {
      date: "26",
      month: "March",
      title: "देवी पूजन व गणेश जी की सवारी",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "27",
      month: "March",
      title: "राम का जन्म लीला का मंचन",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "28",
      month: "March",
      title: "विश्वामित्र जी के साथ राम वन गमन, ताड़का वध",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "29",
      month: "March",
      title: "अष्टमी की सवारी, श्री राम विवाह",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "30",
      month: "March",
      title: "रामजन्म उत्सव (लीला मंचन धनुष यज्ञ राम विवाह)",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "31",
      month: "March",
      title: "दशमी की सवारी",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "2",
      month: "April",
      title: "रामजन्म उत्सव (लीला मंचन धनुष यज्ञ राम विवाह)",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "3",
      month: "April",
      title: "राज तिलक व बनवास",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "4",
      month: "April",
      title: "केवट प्रसंग, भरत मिलाप",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "5",
      month: "April",
      title: "सीता हरण",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "6",
      month: "April",
      title: "सीता खोज, शबरी प्रसंग, बाली वध",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "7",
      month: "April",
      title: "सीता खोज हेतु हनुमान जी भेजना",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "8",
      month: "April",
      title: "लंका दहन",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "9",
      month: "April",
      title: "लंका की बढ़ाई व चढाई",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "10",
      month: "April",
      title: "विभीषण शरणागत व सेतु बन्धन",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "11",
      month: "April",
      title: "लक्ष्मण शक्ति, मेघनाथ वध, कुम्भकरण वध, सुलोचना सती",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "12",
      month: "April",
      title: "नारंतक वध, रावण वध",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
    {
      date: "13",
      month: "April",
      title: "राज्य अभिषेक, महाआरती, रामलीला समापन",
      description:
        "राजा जनक को चिंतित देख गुरु विश्वामित्र ने राम को आज्ञा दी कि वह धनुष को उठाकर राजा जनक की चिन्ता और शंसय को दूर करें।",
    },
  ];

  return (
    <section className="relative text-gray-600 body-font h-full bg-gray-100 bg-opacity-75 overflow-hidden border-[2px] border-orange-400 shadow-md">
      {/* <Tabs data={data} /> */}
      <h1 className="sticky notice-board bg-orange-400 text-white title-font text-xl font-medium pl-4 py-1 shadow-md">
        सूचना पट्ट
      </h1>
      <VerticalSlider>
        {notices.map((notice, index) => (
          <NoticeBoardItem
            key={index}
            date={notice.date}
            month={notice.month}
            title={notice.title}
            description={notice.description}
            className="-z-1"
          />
        ))}
      </VerticalSlider>
    </section>
  );
}

export default NoticeBoard;
