import React, { useState, useEffect } from "react";
import "./InquiryForRamleela.css";
import classNames from "classnames";

function InquiryForRamleela() {
  const [isSidebarActive, setSidebarActive] = useState(false);

  const [show, setShow] = useState(true);
  const [y, setY] = useState(0);

  const toggleForm = () => {
    setSidebarActive(!isSidebarActive);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setShow(true);
      } else if (y < window.scrollY) {
        setShow(false);
      }
      setY(window.scrollY);
    });
  }, [y]);

  return (
    <>
      <div className="hidden md:block sidebar-inquiry-form-container">
        <div
          className={classNames(
            "hidden md:block sidebar-contact-form",
            isSidebarActive ? "active" : ""
          )}
        >
          <div className="toggle shadow-2xl" onClick={toggleForm}>
            <div className="title">रामलीला के लिए पूछताछ करे</div>
          </div>
          <h1 className="text-center text-3xl font-medium title-font">
            संपर्क करे
          </h1>
          <p className="text-center mb-3">रामलीला कार्यकारणी से संपर्क करे</p>
          <div className="scroll">
            <form autoComplete="off" onSubmit={onSubmit}>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="पूरा नाम"
                className="w-full bg-white border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <input
                type="text"
                id="email"
                name="email"
                placeholder="ई-मेल पता"
                className="w-full bg-white border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <input
                type="text"
                id="mobile"
                name="mobile"
                placeholder="मोबाइल नंबर"
                className="w-full bg-white border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <textarea
                placeholder="संदेश"
                className="w-full bg-white border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              ></textarea>
              <input
                type="submit"
                name=""
                className="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg"
                value="संदेश भेजें"
              />
            </form>
          </div>
        </div>
      </div>

      {/* 
          TODO :: Mobile Version UI
      */}

      <div
        className={classNames(
          "block md:hidden fixed left-[27%] -bottom-10 transition-all duration-400 ease-linear z-50",
          { "block bottom-2": show }
        )}
      >
        <button
          onClick={() => {
            window.location.href = "/contact-us";
          }}
          className="rounded-full text-xl flex gap-3 bg-[#ff5528] px-[15px] py-[9px] text-white font-semibold shadow-xl"
        >
          <p className="text-sm">रामलीला के लिए पूछताछ करे</p>
        </button>
      </div>
    </>
  );
}

export default InquiryForRamleela;
