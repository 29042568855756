import React from "react";

function BharatMataVandana() {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="block w-5 h-5 text-gray-400 mb-4"
        viewBox="0 0 975.036 975.036"
      >
        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
      </svg> */}
      <h2 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900 rozha-one-regular text-center">
        भारत माता की वंदना
      </h2>
      <p class="leading-relaxed mb-6 text-center font-semibold">
        धन धन भारत शोभा धाम प्रथम जननी न करू प्रणाम ॥
      </p>
      <p class="leading-relaxed mb-6 text-center">
        मुकुट हिमालय सोये शिर पर गंगा यमुना हार चरणन धोवे सिंधु पखार ॥<br />
        राम कृष्ण गौतम गाँधी सा होया यहाँ अवतार ॥१॥
      </p>
      <p class="leading-relaxed mb-6 text-center">
        गाँधी वीर शुभाष भगत सिंह राज गुरु सुख देव जिन्होंने अपनी जान गुमाई ॥
        <br />
        लाखो हीरा भेंट होया जब या आज़ादी आई ॥२॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        वीर धरा हल्दी घाटी राणा प्रताप सा वीर शत्रुदल तड़प रयो बिन पाणी ॥<br />
        ले तलवार लडी दुश्मन सु वा झाँसी की राणी ॥३॥
      </p>
      <p class="leading-relaxed mb-6 text-center">
        अलादीन जब चढ़कर आयो करयो पद्मिनी हरण सात सौ सनी पालकी बाकी ॥<br />
        धन धन गोरा बादल न माँकी लज्जा राखी ॥४॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        सेवर जेठ विमान अमेरिका छा व पेंटन टैंक तोड़ दिया गाजर मूली जाण ॥<br />
        माता हित बलिदान हो गया व भारत का जवान ॥५॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        मोर ध्वज राजा राणी न आरी लिनी हाथ चिर दियो रतन कुवर सो घात ॥<br />
        भुखा सिंग ने भोजन दिनो या सब जाण बात ॥६॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        सतवादी हरिश्चंद्र सती सेव्या रोहित ने लेर जभी आया गंगा को तीर ॥<br />
        धन धन सतवादी राजा भरयो नीच धर नीर ॥७॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        बूंदी को इतिहास वीर कुम्भा न त्याग दिया प्राण मॉंग ली चूडावत सलाणी ॥
        <br />
        शीश काट सलाणी भेजी वा दी हाड़ी राणी ॥८॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        लक्ष्मी पति की कृपा छत्र न लेकर के उपहार जा गिरया कॉई बात की रवावो ॥
        <br />
        लेकर इ सतसंग मण्डली न राजस्थान आवो ॥९ ॥
      </p>
    </>
  );
}

export default BharatMataVandana;
