import React from "react";
import "./RibbonTitle.css";
import classNames from "classnames";

const RibbonTitle = ({ classes, children }) => {
  return (
    <div className={classNames("ribbonContainer", classes)}>
      <div className="ribbon">{children}</div>
    </div>
  );
};

export default RibbonTitle;
