import React from "react";

export default function PrivacyPolicy() {
  return (
    <section className="text-gray-600 body-font relative py-10 md:py-14">
      <div className="flex flex-col text-center w-full mb-10 md:mb-18">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          गोपनीयता नीति
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
          ढाई कड़ी की रामलीला वेबसाइट को उपयोग करने के लिए गोपनीयता नीति
        </p>
      </div>
      <div className="container px-5 py-0 md:py-12 mx-auto">
        <p className="text-justify">
          हम हमारी वेबसाइट पर आने वाले लोगों की गोपनीयता का सम्मान करते हैं।
          इसके अनुसार, हमने अपने उपयोगकर्ताओं के प्रति हमारी गोपनीयता
          प्रतिबद्धता प्रदर्शित करने के लिए यह नीति बनाई है:
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          यह गोपनीयता नीति क्या शामिल करती है?
        </h1>
        <p className="text-justify">
          यह गोपनीयता नीति व्यक्तिगत रूप से पहचान योग्य जानकारी के हमारे उपचार
          को कवर करती है जिसे हम तब एकत्र करते हैं जब आप हमारी साइट पर होते हैं
          और जब आप हमारी सेवाओं का उपयोग करते हैं। यह नीति किसी भी व्यक्तिगत
          पहचान योग्य जानकारी के हमारे उपचार को भी कवर करती है जो तीसरे पक्ष
          हमारे साथ साझा करते हैं। यह नीति उन संगठनों की प्रथाओं पर लागू नहीं
          होती है जिनका स्वामित्व या नियंत्रण हमारे पास नहीं है या जिन लोगों को
          हम रोजगार नहीं देते हैं या प्रबंधित नहीं करते हैं।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          सूचना संग्रहण एवं उपयोग
        </h1>
        <p className="text-justify">
          जब आप हमारी वेबसाइट पर पंजीकरण करते हैं, जब आप हमारी सेवाओं का उपयोग
          करते हैं, और जब आप हमारे पृष्ठों पर जाते हैं तो हम व्यक्तिगत रूप से
          पहचान योग्य जानकारी एकत्र करते हैं। हम तीसरे पक्षों से व्यक्तिगत रूप
          से पहचान योग्य जानकारी भी प्राप्त कर सकते हैं। जब आप हमारे साथ पंजीकरण
          करते हैं, तो हम आपका नाम, ईमेल पता, ज़िप कोड, व्यवसाय, उद्योग और
          व्यक्तिगत रुचियां पूछते हैं। एक बार जब आप हमारे साथ पंजीकरण करते हैं
          और हमारी सेवाओं में साइन इन करते हैं, तो आप हमारे लिए गुमनाम नहीं होते
          हैं। हम इस जानकारी का उपयोग तीन सामान्य उद्देश्यों के लिए करते हैं:
          आपके द्वारा देखी जाने वाली सामग्री को अनुकूलित करने के लिए, कुछ सेवाओं
          के लिए आपके अनुरोधों को पूरा करने के लिए, और सेवाओं के बारे में आपसे
          संपर्क करने के लिए। हम आपके ब्राउज़र से आपके आईपी पते, कुकी जानकारी और
          आपके द्वारा अनुरोधित पृष्ठ सहित हमारे सर्वर लॉग पर स्वचालित रूप से
          जानकारी प्राप्त और रिकॉर्ड करते हैं। यह जानकारी आपके खाते से जुड़ी
          नहीं है और आपकी पहचान के लिए इसका उपयोग नहीं किया जाएगा।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          इस गोपनीयता नीति में परिवर्तन
        </h1>
        <p className="text-justify">
          गोपनीयता नीति समय-समय पर संशोधन के अधीन है। यदि हम अपनी गोपनीयता नीति
          को बदलने का निर्णय लेते हैं, तो हम उन परिवर्तनों को यहां पोस्ट करेंगे
          ताकि आप हमेशा जान सकें कि हम कौन सी जानकारी एकत्र करते हैं, हम उस
          जानकारी का उपयोग कैसे कर सकते हैं, और क्या हम इसे किसी के सामने प्रकट
          करेंगे। हमारी गोपनीयता नीति में किसी भी महत्वपूर्ण बदलाव की घोषणा
          हमारे होम पेज पर की जाएगी। यदि आप हमारी नीति में बदलावों से सहमत नहीं
          हैं, तो आप हमारी वेबसाइट पर जाना बंद कर सकते हैं।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          प्रश्न या सुझाव
        </h1>
        <p className="text-justify">
          यदि आपके पास कोई प्रश्न या सुझाव है तो हमें एक ईमेल भेजें :{" "}
          <a
            href="mailto:contact@dhaikadikiramleela.com"
            className="text-red-500 leading-relaxed underline"
          >
            contact@dhaikadikiramleela.com
          </a>
        </p>
      </div>
    </section>
  );
}
