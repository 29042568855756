import React from "react";

export default function Disclamer() {
  return (
    <section className="text-gray-600 body-font relative py-10 md:py-14">
      <div className="flex flex-col text-center w-full mb-10 md:mb-18">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          अस्वीकरण
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
          ढाई कड़ी की रामलीला वेबसाइट को उपयोग करने के लिए अस्वीकरण
        </p>
      </div>
      <div className="container px-5 py-0 md:py-12 mx-auto">
        <p className="text-justify py-2 md:my-4">
          परियोजना विवरण के संबंध में वेबसाइट पर दी गई जानकारी पूरी तरह से
          वैचारिक है और कोई कानूनी पेशकश नहीं है। इसके अलावा, प्रमोटरों के पास
          उल्लिखित किसी भी विवरण, विशिष्टताओं या उन्नयनों को जोड़ने या हटाने का
          अधिकार सुरक्षित है, यदि परिस्थितियों के अनुसार ऐसा आवश्यक हो। इस
          वेबसाइट पर उपयोग की गई सभी छवियां, चित्र, सामग्री, आइकन और वीडियो
          कॉपीराइट संरक्षित होंगे। यदि किसी को इस संबंध में कोई आपत्ति है, तो
          कृपया हमसे संपर्क करें या सूचित करें ताकि हम उचित समय के भीतर वेबसाइट
          से आपत्तिजनक तस्वीरों को हटा सकें।
        </p>

        <p className="text-justify">
          यदि आपके पास कोई प्रश्न या सुझाव है तो हमें एक ईमेल भेजें :{" "}
          <a
            href="mailto:contact@dhaikadikiramleela.com"
            className="text-red-500 leading-relaxed underline"
          >
            contact@dhaikadikiramleela.com
          </a>
        </p>
      </div>
    </section>
  );
}
