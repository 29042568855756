import React from "react";

export default function VideoGallery() {
  const youtubeVideos = [
    {
      youtubeVideoId: "pjDcKkIlnnM",
      title:
        "अहिरावण वध, नारंतक वध, रावण वध, राज्य अभिषेक, महाआरती, रामलीला समापन | ढाई कड़ी की रामलीला | पाटोन्दा रामलीला 2024 | Day #10",
      release: "28 अप्रैल 2024 (रविवार) [वैशाख कृष्ण पंचमी]",
    },
    {
      youtubeVideoId: "8JZeBt58Px4",
      title:
        "लक्ष्मण शक्ति, कुम्भकरण वध, मेघनाथ वध, सुलोचना सती | ढाई कड़ी की रामलीला | पाटोन्दा रामलीला 2024 | Day #9",
      release: "27 अप्रैल 2024 (शनिवार) [वैशाख कृष्ण चतुर्थी]",
    },
    {
      youtubeVideoId: "4-qD5psiE4c",
      title:
        "लंका की बढ़ाई, विभीषण शरणागत, लंका की चढाई | पाटोन्दा रामलीला 2024 | Day #8 | Part 2",
      release: "26 अप्रैल 2024 (शुक्रवार) [वैशाख कृष्ण तृतीया]",
    },
    {
      youtubeVideoId: "--Pq11_Hczk",
      title:
        "लंका की बढ़ाई, विभीषण शरणागत, लंका की चढाई| पाटोन्दा रामलीला 2024 | Day #8 | Part 1",
      release: "26 अप्रैल 2024 (शुक्रवार) [वैशाख कृष्ण तृतीया]",
    },
    {
      youtubeVideoId: "nF58gu-UvwM",
      title:
        "हनुमान - सीता संवाद, अशोक वाटिका उजाड़ना, अक्षय कुमार वध, लंका दहन | पाटोन्दा रामलीला 2024 | Day #7",
      release: "25 अप्रैल 2024 (गुरुवार) [वैशाख कृष्ण द्वितीया]",
    },
    {
      youtubeVideoId: "zYh6tLakkLs",
      title:
        "सुग्रीव राज्याभिषेक,सुग्रीव द्वारा सीता की खोज,हनुमान-लंकिनी संवाद | पाटोन्दा रामलीला 2024 | Day #6",
      release: "24 अप्रैल 2024 (बुधवार) [वैशाख कृष्ण प्रतिपदा]",
    },
    {
      youtubeVideoId: "l9Gu-IKmXLo",
      title:
        "बाली द्वारा दुंदुभी वध, शबरी प्रसंग, राम - सुग्रीव मित्रता, बाली वध | पाटोन्दा रामलीला 2024 | Day #5",
      release: "23 अप्रैल 2024 (मंगलवार) [चैत्र शुक्ल पूर्णिमा]",
    },
    {
      youtubeVideoId: "iJ4lusTkxxQ",
      title:
        "सीता हरण,जटायु उद्धार | पाटोन्दा रामलीला 2024 | ढाई कड़ी की रामलीला पाटोन्दा | Day #4 | Part 2",
      release: "22 अप्रैल 2024 (सोमवार) [चैत्र शुक्ल चतुर्दशी]",
    },
    {
      youtubeVideoId: "DxD6GrAD9hw",
      title:
        "सीता हरण,जटायु उद्धार | पाटोन्दा रामलीला 2024 | ढाई कड़ी की रामलीला पाटोन्दा | Day #4 | Part 1",
      release: "22 अप्रैल 2024 (सोमवार) [चैत्र शुक्ल चतुर्दशी]",
    },
    {
      youtubeVideoId: "ni-A_fRYJxk",
      title: "राज तिलक की तैयारी, राम वनवास | पाटोन्दा रामलीला 2024 | Day #2",
      release: "20 अप्रैल 2024 (शनिवार) [चैत्र शुक्ल द्वादशी]",
    },
    {
      youtubeVideoId: "5_rPgmO9dio",
      title:
        "सीता स्वयंवर, लक्ष्मण - परशुराम संवाद | पाटोन्दा रामलीला 2024 | Day #1",
      release: "19 अप्रैल 2024 (शुक्रवार) [चैत्र शुक्ल एकादशी]",
    },
    // {
    //   youtubeVideoId: "acKJhZ-dJDU",
    //   title:
    //     "महोत्सव - हिंदू कॉलेज की 125वीं वर्षगांठ समारोह | हिंदू कॉलेज दिल्ली",
    //   release: "12 फरवरी 2024 (सोमवार)",
    // },
    // {
    //   youtubeVideoId: "Ys36zAF004I",
    //   title: "अंगद रावण संवाद, लक्ष्मण शक्ति, मेघनाद वध, कुम्भकर्ण वध - Day 9",
    //   release: "12 फरवरी 2024 (सोमवार)",
    // },
  ];

  return (
    <section className="bg-[#ffedd5] flex flex-col items-center justify-center py-10">
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-5 mx-auto flex flex-wrap">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="glow text-3xl md:text-5xl font-medium mb-1 md:mb-4 text-blue-900">
              वीडियो गेलरी
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              ढाई कड़ी की रामलीला मंचन के वीडियो
            </p>
          </div>
          <div className="flex flex-wrap justify-center w-full md:-m-2 -m-1">
            {youtubeVideos.map((video, idx) => {
              return (
                <div className="py-5 md:p-5" key={idx}>
                  <div
                    className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300"
                    key={idx}
                  >
                    <iframe
                      className="w-full rounded-lg h-[225px] md:h-[250px]"
                      src={
                        "https://www.youtube.com/embed/" +
                        video["youtubeVideoId"]
                      }
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      loading="lazy"
                    ></iframe>
                    <div className="mt-3 px-1">
                      <h1 className="font-bold text-black text-lg">
                        {video["title"]}
                      </h1>
                      <p className="text-sm font-medium text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis">
                        {video["release"]}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </section>
  );
}
