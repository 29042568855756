import React from "react";
import Paper from "../../../components/Paper/Paper";
import AkharaTaanComponent from "../../../components/ManuScript/AkharaTaan/AkharaTaan";

function AkharaTaan() {
  return (
    <section className="bg-pink-50 px-5 py-16 mx-auto">
      <div className="flex flex-col text-center w-full mb-10 md:mb-20">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          अखाड़ा तान
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px] mb-4">
          यह रामलीला निर्विघ्न रूप से चलती रहे इसीलिए हमारी रामलीला में
          मंत्रस्वरूप अखाड़ा बांधने की परंपरा हैं।
        </p>
      </div>
      <div className="relative md:grid md:grid-cols-2 my-[3%] md:px-32">
        <div className="h-full hidden md:flex">
          <Paper>
            <AkharaTaanComponent />
          </Paper>
        </div>
        <div className="block md:hidden bg-orange-100 p-4 rounded mb-8">
          <p class="leading-relaxed mb-6 text-center">
            प्रथम गुरु आद अखाड़ा आर लगादयूं चारों खूंटां कार ॥
          </p>

          <p class="leading-relaxed mb-6 text-center">
            प्रथम अखड़ा बीच चोक मं बराज श्री रघुनाथ शम्भु की कर नांक दो चीर ॥
            <br />
            राम र लक्ष्मण भरत शत्रुघन टाढे हनुमत वीर ॥१॥
          </p>

          <p class="leading-relaxed mb-6 text-center">
            बावन भेरु चौसठ योगिनी आवो अखड़ा मांही दाव दुश्मन का लगता नांही ॥
            <br />
            जंतर मंतर नजर मुठ या चले किसी की नांही ॥२॥
          </p>

          <p class="leading-relaxed mb-6 text-center">
            एक खूंट प दुर्गा बिराज दूजा भवानी माई खूंट तीजां प शारदा माई ॥<br />
            ईर अखड़ा मं कर बखड़ो जीन खाव कालक्या माई ॥ ३ ॥
          </p>

          <p class="leading-relaxed mb-6 text-center">
            धरती बाँध आकाश बाँधू बाँधू चौदह लोक बाँध कर इनको करूं मजंबूत्त ॥
            <br />
            ईर अखड़ा माहिन नहीं आव डाकन भूत ॥ ४ ॥
          </p>
        </div>
        <div className="">
          {/* <h1 className="text-2xl md:text-3xl text-blue-900 font-medium mb-4">
            रामलीला मैं अखाडा क्यों बांधा जाता है
          </h1> */}
          <p className="text-gray-600 text-justify text-lg mb-8">
            यह रामलीला निर्विघ्न रूप में चलती रहे किसी बुरे विचारो या नकारात्मक
            शक्तियो, क्रियाओ नष्ट करने के लिए मंत्रस्वरूप हमारी रामलीला में
            अखाड़ा बांधने की परंपरा हैं। इसीलिए इसमें रामलीला शुरू करने से पहले
            अखाड़े की तान गायी जाती हैं।
            <br />
            <br />
            एक बार अकस्मात रामलीला का मंचन करते समय लक्ष्मण शक्ति का संवाद था
            मेघनाथ द्वारा लक्ष्मण जी के ऊपर ब्रह्म शक्ति प्रहार किया गया लक्ष्मण
            के पात्र पर किसी जादुई शक्ति के प्रहार करने से लक्ष्मण के पात्र की
            उसी समय मृत्यु हो गयी ऐसी स्थिति में भी कथा प्रेमियों ने रामलीला
            मंचन बंद नहीं किया गया सुबह रावण वध के पश्चात ही अंतिम संस्कार किया
            गया हैं।
          </p>
        </div>
      </div>
    </section>
  );
}

export default AkharaTaan;
