import React from "react";
import { baseCDNUrl } from "../../../utils/endpoints";
import "./Intro.css";

export default function Intro() {
  return (
    <section className="bg-pink-50 pb-10">
      <div className="flex flex-col justify-center items-center py-10">
        <img
          src="/assets/icons/favicon-32x32.png"
          height="15"
          width="15"
          alt="heart"
        />
        <p className="text-lg text-gray-600 my-3 text-center px-10 md:px-0">
          पाटोन्दा की ऐतिहासिक 160 साल पुरानी धरोहर अंतर्राष्ट्रीय ढाई कड़ी की
          रामलीला
        </p>
      </div>
      <div className="relative md:grid md:grid-cols-2 my-[3%] px-4  md:px-32">
        <div className="flex justify-center">
          <img
            className="rounded-xl shadow-lg mb-5 md:mb-0"
            src={baseCDNUrl + "imgs/49aa8a3b-b9cc-4079-b679-4c6e260e24d9.webp"}
            alt="img"
          />
          <img
            className="hidden md:block absolute -bottom-10 left-[180px] rounded-xl shadow-lg"
            src={baseCDNUrl + "imgs/img2.jpg"}
            width="300"
            height="400"
            alt="img"
          />
        </div>
        <div className="">
          <h1 className="ramleelaTitle text-3xl md:text-6xl text-blue-900 font-medium mb-8">
            dhai kadi ki ramleela
          </h1>
          <p className="text-gray-600 text-justify text-lg mb-8">
            इस ढाई कड़ी की रामलीला की शुरुआत आज से लगभग 160 वर्ष पहले गुरु साहब
            गणपतलाल दाधीच ने की थी उन्होंने रामलीला की भाषा को हाथ से लिखा था
            रामलीला की विशेषता है कि रामलीला में संवाद बोलने के लिए किसी भी गायक
            गायिका का सहयोग नहीं लिया जाता बल्कि कलाकार ही संवाद बोलते हैं। जिसे
            उन्होंने वाल्मीकि की रामायण और तुलसी की रामचरितमानस के आधार पर लिखा
            था।
            <br />
            <br />
            इस रामलीला ने भारतीय रामायण मेला भोपाल अंतरराष्ट्रीय रामायण मेला
            चित्रकूट में दो बार और अंतरराष्ट्रीय रामायण मेला अयोध्या में एक बार
            प्रदर्शन किया है सन 1950 में कोटा में आयोजित अखिल भारतीय साहित्य
            सम्मेलन में भी इस रामलीला ने प्रथम स्थान हासिल किया था क्वींसलैंड
            विश्वविद्यालय की ज्यूडिह मैरी पर्वों पर आधारित पुस्तक में एक अध्याय
            पाटोन्दा की ढाई कड़ी की रामलीला पर आधारित हैं |
          </p>
          <button
            href=""
            className="bg-[#ffa61a] px-5 md:px-10 py-2 md:py-3 rounded-3xl text-white font-semibold text-lg cursor-pointor"
            onClick={() => {
              window.location.href = "/history-of-ramleela";
            }}
          >
            Read More
          </button>
        </div>
      </div>
    </section>
  );
}
