import React from "react";
import { TypeAnimation } from "react-type-animation";

const TextSwitcher = () => {
  const sequence = [
    "हिंदू कॉलेज के 125 वें स्थापना दिवस पर आयोजित महोत्सव शृंखला में पाटोंदा की प्रसिद्ध अन्तर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन हुआ।",
    3000,
    "क्वींसलैंड विश्वविद्यालय ऑस्ट्रेलिया की ज्यूडिह मैरी पर्वों पर आधारित पुस्तक में एक अध्याय पाटोन्दा की ढाई कड़ी की रामलीला पर आधारित हैं।",
    3000,
    "यह देश की पहली ऐसी रामलीला है जिसका अपना संविधान है।",
    3000,
    "इस रामलीला ने भारतीय रामायण मेला भोपाल, अंतरराष्ट्रीय रामायण मेला चित्रकूट में दो बार प्रदर्शन किया था।",
    3000,
    "यह रामलीला भारतवर्ष की उन तीन हिंदी-भाषाई अंतर्राष्ट्रीय रामलीलाओं में से एक है, जो खासतौर से गाकर की जाती हैं।",
    3000,
    "यह रामलीला राजस्थानी भाषा की हाड़ौती उपभाषा में लिखी गई है। हाड़ौती पर ऊंचे सुर वाली प्राचीन डिंगल भाषा का प्रभाव है।",
    3000,
    "यह रामलीला ने सन 1950 में कोटा में आयोजित अखिल भारतीय साहित्य सम्मेलन में प्रथम स्थान हासिल किया था।",
    3000,
    "पुराने समय में रामलीला में रोशनी के लिए मशालों और पेट्रोमेक्स लैम्प का उपयोग किया जाता था। अब बिजली की रोशनी का उपयोग किया जाता है।",
    3000,
    "रामलीला में पहले संगीत के लिए सारंगी से मिलता-जुलता एक यन्त्र चिकारा प्रयोग में लाया जाता था। अब चिकारा की जगह वायलिन और हारमोनियम का प्रयोग किया जाता है।",
    3000,
  ];

  return (
    <TypeAnimation
      sequence={sequence}
      speed={50}
      deletionSpeed={99}
      wrapper="p"
      repeat={Infinity}
      className="text-[14px]"
    />
  );
};

export default TextSwitcher;
