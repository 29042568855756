import React from "react";
import GaneshVandana from "../../components/ManuScript/GaneshVandana/GaneshVandana";
import BharatMataVandana from "../../components/ManuScript/BharatMataVandana/BharatMataVandana";
import AkharaTaan from "../../components/ManuScript/AkharaTaan/AkharaTaan";
import LaxminathVandana from "../../components/ManuScript/LaxminathVandana/LaxminathVandana";
import "./ManuScript.css";

function ManuScript() {
  return (
    <section className="bg-pink-50 px-5 py-16 mx-auto">
      <div className="flex flex-col text-center w-full mb-10 md:mb-20">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          रामलीला की पांडूलिपिया
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px] mb-4">
          लगभग दो दर्जन हस्तलिखित पांडुलिपियों में लिपिबद्ध इस रामलीला की समस्त
          विषयवस्तु निहित है।
        </p>
        <p className="glow text-[#FF0000]">
          सावधान :: यह पांडूलिपिया ढाई कड़ी की रामलीला की कॉपीराइट के अधीन हैं।
          इसे बिना अनुमति के कॉपी, अपडेट, या उपयोग करना कानूनी अपराध है।
        </p>
      </div>

      <section className="text-gray-600 body-font">
        <div className="container px-5 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="py-4 md:p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <BharatMataVandana />
              </div>
            </div>
            <div className="py-4 md:p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <GaneshVandana />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -m-4">
            <div className="py-4 md:p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <AkharaTaan />
              </div>
            </div>
            <div className="py-4 md:p-4 md:w-1/2 w-full">
              <div className="h-full bg-orange-100 p-8 rounded">
                <LaxminathVandana />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default ManuScript;
