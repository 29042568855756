import React from "react";
import TextSwitcher from "./TextSwitcher";
import "./Spinner.css";

function SubHeader() {
  return (
    <>
      <div className="hidden md:block">
        <div className="bg-[#ff5528] text-white flex flex-row justify-between items-center px-20 2xl:px-44 py-2 shadow-md">
          <div className="flex justify-between items-center">
            <div className="loadingio-spinner-ripple-j3h99vrjz6h">
              <div className="ldio-d2hh18csizh">
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="mr-1"></div>
            <TextSwitcher />
          </div>
          <div>
            <div className="flex flex-row justify-between items-center gap-3">
              <div>
                <a
                  href="/patoonda-ramleela-2024"
                  className="transition hover:opacity-75 text-sm"
                >
                  पाटोन्दा रामलीला #2024
                </a>
              </div>
              <div
                style={{ width: "2px", height: "15px", background: "white" }}
              ></div>
              <div>
                <a
                  href="/125-years-celebration-mahotsav-hindu-college"
                  className="transition hover:opacity-75 text-sm"
                >
                  हिन्दू कॉलेज 125<sup>th</sup> वर्षगाँठ
                </a>
              </div>
              <div
                style={{ width: "2px", height: "15px", background: "white" }}
              ></div>
              {/* <div>
                <a
                  href="/assetstore"
                  className="transition hover:opacity-75 text-sm"
                >
                  परिसंपत्ति भंडार
                </a>
              </div>
              <div
                style={{ width: "2px", height: "15px", background: "white" }}
              ></div> */}
              <div>
                <a
                  href="/manuscript"
                  className="transition hover:opacity-75 text-sm"
                >
                  रामलीला की पांडूलिपिया
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="bg-[#ff5528] text-white text-center mx-auto py-2 shadow-md">
          <a href="/patoonda-ramleela-2024">
            <p className="text-[13px]">
              रामलीला कार्यक्रम : 28 अप्रैल (रविवार), रात्रि में 10:30 बजे से
              <br /> अहिरावण वध, नारंतक वध, रावण वध, राज्य अभिषेक, महाआरती,
              रामलीला समापन
              {/* हिंदू कॉलेज दिल्ली में पाटोन्दा की अंतर्राष्ट्रीय <br />
              ढाई कड़ी की रामलीला का मंचन हुआ। */}
            </p>
          </a>
        </div>
      </div>
    </>
  );
}

export default SubHeader;
