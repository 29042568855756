/*
   TODO :: Committee Provider provide the all commitee information under the hood.
*/
export const committeeMembers = [
  {
    _id: "454e511c-b881-45aa-a066-1c868f1ead03",
    avatar: "persons/pic80.webp",
    fullName: "श्री नरेंद्र नंदवाना",
    designation: "संरक्षक",
    contactNumber: "+91 94141 90769",
  },
  {
    _id: "ca215b65-a57b-46e0-b47c-2c217c915d1d",
    avatar: "persons/pic1.webp",
    fullName: "श्री दुर्गा शंकर गोप",
    designation: "अध्यक्ष",
    contactNumber: "+91 99299 74219",
  },
  {
    _id: "da154656-95e1-4d36-a367-7182dfbb7347",
    avatar: "persons/pic33.webp",
    fullName: "श्री राजकुमार नंदवाना",
    designation: "उपाध्यक्ष",
    contactNumber: "+91 98291 17934",
  },
  {
    _id: "2ad45cbb-ffc2-4fc7-b861-e6444773b676",
    avatar: "persons/pic26.webp",
    fullName: "श्री बृजराज नंदवाना",
    designation: "उपाध्यक्ष",
    contactNumber: "+91 99508 09299",
  },
  {
    _id: "b101d079-4a9f-4c80-b3f2-9ecfc7127d40",
    avatar: "persons/pic47.webp",
    fullName: "श्री राम प्रसाद सेन",
    designation: "कोषाध्यक्ष (सेवानिवृत्त वरिष्ठ लिपिक)",
    contactNumber: "+91 99293 24083",
  },
  {
    _id: "b966ea51-63ed-4939-92e8-86295118ebe1",
    avatar: "persons/pic28.webp",
    fullName: "श्री अमरलाल मालव",
    designation: "महासचिव",
    contactNumber: "+9179767 51542",
  },
  {
    _id: "452a4486-9495-4d32-aacb-913e3a3d158d",
    avatar: "persons/pic16.webp",
    fullName: "श्री लोकेश दाधीच",
    designation: "सयोजक",
    contactNumber: "+9198291 22761",
  },
];

/*
    TODO :: Commitee Admins
*/

export const committeeAdmins = [
  {
    _id: "2291d2b3-3199-4f61-9d7b-d4c3d6949837",
    avatar: "persons/pic20.webp",
    fullName: "श्री चौथमल गोचर",
    designation: "व्यवस्थापक",
  },
  {
    _id: "8202bdf5-7212-4439-b534-0a17a719d3b7",
    avatar: "persons/pic30.webp",
    fullName: "श्री बद्रीलाल नागर (सेवानिवृत्त प्रधानाध्यापक)",
    designation: "व्यवस्थापक",
  },
];

/*
    TODO :: Commitee Consultants
*/

export const committeeConsultants = [
  {
    _id: "78319d1b-8256-4065-a7e0-0676d00fc2ff",
    avatar: "persons/pic22.webp",
    fullName: "श्री कैलाशचंद नंदवाना",
    designation: "सलाहकार",
  },
  {
    _id: "2520e55a-ca17-4527-bbbb-38e60681654a",
    avatar: "persons/pic29.webp",
    fullName: "श्री हेमराज राठौर",
    designation: "सलाहकार",
  },
];

/*
    TODO :: Committee Store Keepers
*/

export const committeeStoreKeepers = [
  {
    _id: "4392c26d-889f-4c1d-8c06-09e597d3e6e6",
    avatar: "persons/pic88.webp",
    fullName: "श्री श्रवणलाल प्रजापति",
    designation: "स्टोर कीपर",
  },
  {
    _id: "3b037391-0eaa-49a8-a6dd-5ff2923909d3",
    avatar: "persons/pic43.webp",
    fullName: "श्री भंवरलाल नागर",
    designation: "स्टोर कीपर",
  },
];

/*
    TODO :: Commitee Media Manager
*/

export const committeeMediaManagers = [
  {
    _id: "1fcf53b0-75d7-4c13-84b3-12265c9bc48d",
    avatar: "persons/pic100.webp",
    fullName: "चेतन नागर",
    designation: "मीडिया और मैनेजमेंट प्रभारी (सॉफ्टवेयर इंजीनियर)",
    contactNumber: "+9182094 46178",
  },
];

/*
    TODO :: Commitee Members
*/

export const members = [
  {
    _id: "804cfde0-7a7f-4b92-a94b-afafaa7167c6",
    avatar: "persons/pic24.webp",
    fullName: "श्री धनराज भील",
    designation: "सदस्य",
  },
  {
    _id: "ef9bfdc2-ec0c-4901-895e-7f76ae350cad",
    avatar: "persons/pic34.webp",
    fullName: "श्री हेमराज नागर",
    designation: "सदस्य",
  },
  {
    _id: "2f93f96f-7e8d-4195-890a-5da37c886a3b",
    avatar: "persons/pic21.webp",
    fullName: "श्री हंसराज मालव",
    designation: "सदस्य",
  },
  {
    _id: "2d91f2a7-f19b-469d-921a-50415778f8c1",
    avatar: "persons/pic40.webp",
    fullName: "श्री नाथूलाल कारपेंटर",
    designation: "सदस्य",
  },
  {
    _id: "dcd148cc-60fb-487d-8eed-0226a4920fea",
    avatar: "persons/pic3.webp",
    fullName: "श्री बृजसुंदर केवट",
    designation: "सदस्य",
  },
  {
    _id: "69c02778-2e2e-4f9c-a233-098c1e585b21",
    avatar: "persons/pic18.webp",
    fullName: "श्री वेदप्रकाश शर्मा",
    designation: "सदस्य",
  },
  {
    _id: "2ed9eb17-331e-44de-bada-7a2e9f54705b",
    avatar: "persons/pic8.webp",
    fullName: "श्री सुशिल पांचाल",
    designation: "सदस्य",
  },
  {
    _id: "62da31c1-d3a9-4dc9-8ddb-5e1eb52f9754",
    avatar: "persons/pic17.webp",
    fullName: "श्री मुकुट बिहारी गोचर",
    designation: "सदस्य",
  },
  {
    _id: "8d7e67cc-efc5-493a-8e03-885a2ed01607",
    avatar: "persons/pic19.webp",
    fullName: "श्री इन्द्रजीत केवट",
    designation: "सदस्य",
  },
  {
    _id: "66f1121c-1b3b-4e7d-a14e-72c243cb518b",
    avatar: "persons/pic45.webp",
    fullName: "श्री रामभरोस केवट",
    designation: "सदस्य",
  },
  {
    _id: "43af38da-ee6e-4beb-afa3-4a96621db04e",
    avatar: "persons/avatarplaceholder.webp",
    fullName: "श्री रमेशचंद्र केवट",
    designation: "सदस्य",
  },
  {
    _id: "7cab9a33-c886-4b35-bade-37da9ebc0d01",
    avatar: "persons/pic96.webp",
    fullName: "श्री गौरव नंदवाना",
    designation: "सदस्य",
  },
];
