import React, { useState } from "react";
import HomePageSlider from "../Home/HomePageSlider/HomePageSlider";
import LiveTV from "../Home/LiveTV/LiveTV";
import classNames from "classnames";

export default function CustomTabs() {
  const [currentTab, setTabIndex] = useState(0);
  const [height, setHeight] = useState(0);

  function handleTabChange(index) {
    setTabIndex(index);
  }

  const customClass = {
    writingMode: "tb-rl",
    transform: "rotate(-180deg)",
    color: "white",
    textAlign: "center",
  };

  return (
    <div>
      <div className="relative">
        <div
          className={classNames(
            "bg-[#fb923c] absolute w-[35px] h-auto py-4 px-2 cursor-pointer top-[75px] transform -translate-y-1/2 left-[-35px] text-center rounded-l-2xl"
          )}
          onClick={() => handleTabChange(0)}
        >
          <div style={customClass}>फोटो एलबम</div>
        </div>
        <div
          className={classNames(
            "bg-[#ff5528] absolute w-[35px] h-auto py-6 px-2 text-center cursor-pointer top-[200px] transform -translate-y-1/2 left-[-35px] rounded-l-2xl"
          )}
          onClick={() => handleTabChange(1)}
        >
          <div style={customClass}>लाइव टीवी</div>
        </div>
      </div>
      <div
        className={classNames("md:border-[5px]", {
          "md:border-[#fb923c]": currentTab === 0,
          "md:border-[#ff5528]": currentTab === 1,
        })}
      >
        {currentTab === 0 && (
          <>
            <div className="hidden md:block">
              <HomePageSlider setHeight={setHeight} />
            </div>

            <div className="block md:hidden">
              <LiveTV height="250" />
            </div>
          </>
        )}
        {currentTab === 1 && <LiveTV height={height} />}
      </div>
    </div>
  );
}
