import React from "react";
import { baseCDNUrl } from "../../utils/endpoints";

function NewsAndArticles() {
  const articles = [
    {
      paperCutting: "articles/2de150f8-8440-4afd-bdb8-26f090cff9a4.webp",
      heading: "हिंदू कॉलेज में ढाई कड़ी की रामलीला पाटोंदा का मंचन",
      description:
        "हिंदू कॉलेज के 125 वें स्थापना दिवस पर आयोजित महोत्सव शृंखला में सोमवार को राजस्थान के पाटोंदा की प्रसिद्ध अन्तर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन हुआ। रामलीला का आयोजन विभिन्न दृश्यों में किया गया जिसकी शुरुआत गणेश वंदना के साथ हुई।",
      publishedDate: "13 फरवरी 2024",
    },
    {
      paperCutting: "articles/f899e837-d173-4a9d-a48c-297716ac8560.webp",
      heading:
        "हिंदू कॉलेज दिल्ली में हुई ढाई कड़ी की रामलीला, पाटोंदा के कलाकारों ने दिखाया जलवा",
      description:
        "दिल्ली स्थित हिंदू कॉलेज के 125वें स्थापना दिवस पर आयोजित महोत्सव शृंखला में सोमवार को नगर के समीप स्थित पाटोंदा गावं की प्रसिद्ध अन्तर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन हुआ। रामलीला के पात्रों ने सोमवार को कॉलेज में मंचन किया।",
      publishedDate: "14 फरवरी 2024",
    },
    {
      paperCutting: "articles/10fc6765-a185-4b96-83db-e09fee3193c8.webp",
      heading:
        "पाटोंदा की अन्तर्राष्ट्रीय ढाई कड़ी की रामलीला का हिंदू कॉलेज दिल्ली में मंचन हुआ।",
      description:
        "हिंदू कॉलेज के 125 वें स्थापना दिवस पर आयोजित महोत्सव शृंखला में सोमवार को राजस्थान के पाटोंदा की प्रसिद्ध अन्तर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन हुआ। रामलीला का आयोजन विभिन्न दृश्यों में किया गया जिसकी शुरुआत गणेश वंदना के साथ हुई।",
      publishedDate: "14 फरवरी 2024",
    },
    {
      paperCutting: "articles/img23.webp",
      heading: "पाटोन्दा में होती है ढाई कड़ी की रामलीला",
      description:
        "आज से लगभग 160 वर्ष पूर्व एक दाधिच ब्राम्हण गुरु गणपतलाल जी दाधिच ने इस सांगीतिक रामलीला को प्रारंभ किया था। जिसे उन्होनें वाल्मीकि रामायण व तुलसीकृत रामचरिमानस के आधार पर लिखा था।",
      publishedDate: "22 जनवरी 2024",
    },
    {
      paperCutting: "articles/img21.webp",
      heading: "हाड़ौती की धरोहर है ढाई कड़ी की रामलीला",
      description:
        "आज से लगभग 160 वर्ष पूर्व एक दाधिच ब्राम्हण गुरु गणपतलाल जी दाधिच ने इस सांगीतिक रामलीला को प्रारंभ किया था। जिसे उन्होनें वाल्मीकि रामायण व तुलसीकृत रामचरिमानस के आधार पर लिखा था।",
      publishedDate: "13 जनवरी 2024",
    },
    {
      paperCutting: "articles/img15.jpg",
      heading: "पाटोन्दा मैं 160 साल से हो रही ढाई कड़ी की रामलीला",
      description:
        "आज से लगभग 160 वर्ष पूर्व एक दाधिच ब्राम्हण गुरु गणपतलाल जी दाधिच ने इस सांगीतिक रामलीला को प्रारंभ किया था। जिसे उन्होनें वाल्मीकि रामायण व तुलसीकृत रामचरिमानस के आधार पर लिखा था।",
      publishedDate: "13 अप्रैल 2023",
    },
    {
      paperCutting: "articles/img16.jpg",
      heading: "ढाई कड़ी की रामलीला में विष्णु भगवान की सजीव झांकी सजाई",
      description:
        "पाटोन्दा में ढाई कड़ी की रामलीला का मंचन किया जा रहा है । इस अवसर पर भगवान की झांकी सजाई गयी ।",
      publishedDate: "11 अप्रैल 2023",
    },
    {
      paperCutting: "articles/img11.jpg",
      heading: "पाटोन्दा में रामलीला में सजाई नृसिंह भगवान की झांकी",
      description:
        "रामलीला में गुरुवार को नृसिंह भगवान की झांकी सजाई गयी। साथ ही केवट द्वारा राम जी गंगा पार करवाना, दशरथजी का राम वियोग में प्राण त्यागना, चित्रकूट में राम-भरत मिलाप आदि का मंचन किया ।",
      publishedDate: "8 अप्रैल 2023",
    },
    {
      paperCutting: "articles/img12.jpg",
      heading: "ढाई कड़ी की रामलीला के संगीतमय संगीत में डूबा पाटोन्दा",
      description:
        "हमारे आराध्य देव भगवन श्री राम के सम्पूर्ण जीवन चरित्र पर आधारित जो जनमानस प्रेम, त्याग, कर्म पथ पर मानव को अग्रसर करने वाली रामलीला का मंचन गाव पाटोन्दा में किया जा रहा है ।",
      publishedDate: "6 अप्रैल 2023",
    },
    {
      paperCutting: "articles/img2.jpg",
      heading:
        "देश भर मैं प्रसिद्व है बारां जिले के पाटोन्दा की ढाई कड़ी रामलीला",
      description:
        "मनोरंजन के ढेरो साधनो के बावजूद हाड़ौती बोली मैं रचित ढाई कड़ी के दोहे की रामलीला की धाक अभी भी देशभर मैं बनी हुई है ।",
      publishedDate: "22 अप्रैल 2022",
    },
    {
      paperCutting: "articles/img3.jpg",
      heading: "देश विदेश भर मैं प्रसिद्व है पाटोन्दा की रामलीला",
      description:
        " रामलीला मैं सर्वप्रथम भारत माता की वंदना की जाती है। जिसमे धन धन भारत सोभा धाम, प्रथम जननी के करू प्रणाम.. दोहा बोला जाता है। तुलसीकृत रामायण से हाड़ौती भाषा व डिंगल भाषा का समावेश.....",
      publishedDate: "23 अप्रैल 2022",
    },
    {
      paperCutting: "articles/img1.jpg",
      heading: "पाटोन्दा मैं रावण वध से हुआ ढाई की रामलीला का समापन",
      description:
        "गाव मैं स्थित श्री लक्ष्मीनाथ जी महाराज के मंदिर पर 15 दिनों से मंचित की जा रही इस रामलीला को देखने आसपास के गांवो से भी कई श्रद्धालु पहुंच रहे है ।",
      publishedDate: "28 अप्रैल 2022",
    },
    {
      paperCutting: "articles/img4.jpg",
      heading: "पाटोन्दा मैं रामलीला मैं सीता हरण का मंचन",
      description:
        "पाटोन्दा गांव मैं लक्ष्मीनाथ जी के प्रांगण मैं बने रंगमंच पर चल रही ख्याति प्राप्त रामलीला मैं शुकरवार को खर दूषण वध और रावण द्वारा सीता का हरण आदि प्रसंगो का मंचन किया",
      publishedDate: "25 अप्रैल 2022",
    },
    {
      paperCutting: "articles/img5.jpg",
      heading: "देश भर मैं प्रसिद्व है बारां जिले के पाटोन्दा की रामलीला",
      description:
        "पाटोन्दा गांव मैं लक्ष्मीनाथ जी के प्रांगण मैं बने रंगमंच पर चल रही ख्याति प्राप्त रामलीला मैं शुकरवार को खर दूषण वध और रावण द्वारा सीता का हरण आदि प्रसंगो का मंचन किया",
      publishedDate: "25 अप्रैल 2022",
    },
    // {
    //   paperCutting: "articles/img6.jpg",
    //   heading: "पाटोन्दा की ढाई कड़ी रामलीला मैं रामनवमी पर सांगीतिक उत्सव",
    //   description:
    //     " रामलीला मैं सर्वप्रथम भारत माता की वंदना की जाती है। जिसमे धन धन भारत सोभा धाम, प्रथम जननी के करू प्रणाम.. दोहा बोला जाता है।",
    //   publishedDate: "31 मार्च 2017",
    // },
  ];

  return (
    <section className="bg-pink-50 py-10 md:py-14">
      <div className="flex flex-col items-center justify-center md:px-20">
        <div className="flex flex-col text-center w-full mb-10 md:mb-18">
          <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            समाचार और लेख
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            ढाई कड़ी की रामलीला के समाचार और लेख
          </p>
        </div>
        <section className="text-gray-600 body-font">
          <div class="container px-5 mx-auto">
            <div class="flex flex-wrap -m-4">
              {articles.map((article) => {
                return (
                  <div class="p-4 w-full md:w-1/2">
                    <div className="md:p-4">
                      <div className="h-full border-2 bg-white border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                        <div className="overflow-hidden h-[250px] w-full">
                          <div
                            className="group-hover:scale-125 h-[250px] w-full transition-all duration-500 mb-3 bg-cover bg-no-repeat"
                            style={{
                              backgroundImage:
                                "url('" +
                                baseCDNUrl +
                                article["paperCutting"] +
                                "')",
                            }}
                          ></div>
                        </div>
                        <div className="p-6">
                          <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                            News
                          </h2>
                          <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                            {article["heading"]}
                          </h1>
                          <p className="leading-relaxed mb-3">
                            {article["description"]}
                          </p>
                          <div>
                            <span className="text-gray-400  inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm py-1">
                              <svg
                                className="w-4 h-4 mr-1"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                viewBox="0 0 24 24"
                              >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                              {article["publishedDate"]}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default NewsAndArticles;
