import React, { useEffect, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { baseCDNUrl } from "../../utils/endpoints";
import { photoEventsNames } from "../../utils/utils";

const AshtamiKiSawariPhotos = [
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0815.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0819.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0820.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0822.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0823.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0825.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0826.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0827.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0828.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0832.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0840.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0843.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0844.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0845.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0846.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0848.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0850.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0852.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0855.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0856.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0857.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0858.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0859.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0860.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0861.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0862.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0867.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0868.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0869.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0870.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0871.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0909.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0873.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0876.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0877.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0878.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0879.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0880.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0881.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0885.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0888.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0889.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0890.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0891.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0892.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0894.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0895.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0896.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0897.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0899.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0900.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0901.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0902.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0903.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0904.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0905.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0906.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0907.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0908.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0913.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0914.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_0916.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_9805.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_9806.JPG",
  "ramleela/2k24/patoonda/16-April-Ashtami-Ki-Sawari/IMG_9808.JPG",
];

const photos = [
  {
    imgUrl:
      "ramleela/2k24/patoonda/13-April/6ea9bc40-a576-4f67-a6ad-9011da1ee231.webp",
    title: "गणेश जी की सवारी",
    desc: "गणेश जी की सवारी",
  },
  {
    imgUrl:
      "ramleela/2k24/patoonda/13-April/6cd870c3-5054-43ac-acb3-9faeb473a875.webp",
    title: "गणेश जी की सवारी",
    desc: "गणेश जी की सवारी",
  },
  {
    imgUrl: "ramleela/2k24/hindu-college-delhi/DSC00800.webp",
    title:
      "हिंदू कॉलेज में पाटोन्दा की अंतर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन",
    desc: "हिंदू कॉलेज के 125 वें स्थापना दिवस पर आयोजित महोत्सव शृंखला में दिनांक: 12 फरवरी 2024, सोमवार को राजस्थान के पाटोंदा की प्रसिद्ध अन्तर्राष्ट्रीय ढाई कड़ी की रामलीला का मंचन हुआ।",
  },
  {
    imgUrl: "imgs/pic1.webp",
    title: "भारत माता, लक्ष्मण, हनुमान, राम, धरती माता",
    desc: "ढाई कड़ी की रामलीला मंचन के फोटो",
  },
  {
    imgUrl: "imgs/pic2.webp",
    title: "अशोक वाटिका के माली और मालन",
    desc: "ढाई कड़ी की रामलीला मंचन के फोटो",
  },
  {
    imgUrl: "imgs/pic3.webp",
    title: "हनुमान जी",
    desc: "ढाई कड़ी की रामलीला मंचन के फोटो",
  },
  {
    imgUrl: "imgs/pic4.webp",
    title: "दशरथ जी- कौशल्या , प्रधानमंत्री सुमंतनामा",
    desc: "ढाई कड़ी की रामलीला मंचन के फोटो",
  },
  {
    imgUrl: "imgs/pic6.webp",
    title: "मेघनाथ, रावण",
    desc: "ढाई कड़ी की रामलीला मंचन के फोटो",
  },
  {
    imgUrl: "imgs/pic7.webp",
    title: "हनुमान जी, राम",
    desc: "ढाई कड़ी की रामलीला मंचन के फोटो",
  },
];

export default function PhotoGallery() {
  const [selectedType, setSelectedType] = useState(0);

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        display: {
          right: [
            "iterateZoom",
            "slideshow",
            "download",
            "fullscreen",
            "thumbs",
            "close",
          ],
        },
      },
    });
  }, []);

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 md:py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-10 md:mb-20">
          <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            फ़ोटो गेलरी
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            ढाई कड़ी की रामलीला मंचन के फोटो
          </p>
          {/* <div className="tabs flex mx-2 md:mx-auto border-2 border-[#ff5528] rounded overflow-x-scroll overflow-y-hidden mt-6 scrollbar-none">
            {photoEventsNames.map((type) => {
              return (
                <>
                  {selectedType === photoEventsNames.indexOf(type) ? (
                    <button className="py-2 px-4 bg-[#ff5528] text-white focus:outline-none whitespace-nowrap">
                      {type}
                    </button>
                  ) : (
                    <button
                      className="py-2 px-4 whitespace-nowrap"
                      onClick={() => {
                        setSelectedType(photoEventsNames.indexOf(type));
                      }}
                    >
                      {type}
                    </button>
                  )}
                </>
              );
            })}
          </div> */}
        </div>

        <div className="flex flex-wrap -m-4">
          {AshtamiKiSawariPhotos.map((item, idx) => {
            return (
              <div className="p-5 md:w-1/3">
                <div
                  key={idx}
                  className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300"
                >
                  <img
                    className="object-cover w-full h-80 2xl:h-96 rounded-lg shadow-md aspect-auto cursor-pointer"
                    src={baseCDNUrl + item}
                    alt="श्री राम बारात (अष्टमी की सवारी)"
                    data-fancybox={baseCDNUrl + item}
                    data-src={baseCDNUrl + item}
                    data-caption="श्री राम बारात (अष्टमी की सवारी)"
                    data-download-src={baseCDNUrl + item}
                  />
                  <div className="mt-3 px-1">
                    <h1 className="font-bold text-black text-lg">
                      श्री राम बारात (अष्टमी की सवारी)
                    </h1>
                    <p className="text-sm font-medium text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis">
                      श्री राम बारात (अष्टमी की सवारी)
                    </p>
                  </div>
                </div>
              </div>
            );
          })}

          {photos.map((item, idx) => {
            return (
              <div className="p-5 md:w-1/3">
                <div
                  key={idx}
                  className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300"
                >
                  <img
                    className="object-cover w-full h-80 2xl:h-96 rounded-lg shadow-md aspect-auto cursor-pointer"
                    src={baseCDNUrl + item.imgUrl}
                    alt={item.title}
                    data-fancybox="gallery"
                    data-src={baseCDNUrl + item.imgUrl}
                    data-caption={item.title}
                    data-download-src={baseCDNUrl + item.imgUrl}
                  />
                  <div className="mt-3 px-1">
                    <h1 className="font-bold text-black text-lg">
                      {item.title}
                    </h1>
                    <p className="text-sm font-medium text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis">
                      {item.desc}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
