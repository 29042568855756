import React from "react";

function LaxminathVandana() {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="block w-5 h-5 text-gray-400 mb-4"
        viewBox="0 0 975.036 975.036"
      >
        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
      </svg> */}
      <h2 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900 rozha-one-regular text-center">
        लक्ष्मीनाथ जी की वंदना
      </h2>
      <p class="leading-relaxed mb-6 text-center font-semibold">
        अजी सवरूँ लक्ष्मीनाथ अवतार हमारा बेड़ा लगाओ प्रभु पार ॥
      </p>
      <p class="leading-relaxed mb-6 text-center">
        लक्ष्मीनाथ का मंदिर ऊपर उगन्ता प्रभात नाथ क पड़ पछेरयाँ ज्वार ॥<br />
        मोर मोरड़ी सवा कबूतर पंछी चुग हज़ार ॥१॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        सन्मुख हस्ती घूम बारण दरवाजा क बीच नाथ की कुंज बणी एफ भारी ॥<br />
        सारी परजा दर्शन आयी होया गरुड़ असवारी ॥२॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        बागो सोह केसरियाँ प्रभु दुपटो फूल गुलाब जिन्होंक सुन्दर लग रही कोर ॥
        <br />
        लक्ष्मीनाथ का कर्ण पेच मं लाखों रूपया को मोल ॥३॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        काना बीच कुंडल हद खुल जी मोर मुकट सर पेच नाथ की झांकी लगती प्यारी ॥
        <br />
        राम नवमी को जनम उछव छ दशमी की असवारी ॥४॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        बड़ा बाग मं गजानंद छ गढ़ मं बिहारी नाथ शहर मं लक्ष्मीनाथ की झांकी ॥<br />
        घर छ साढ़ा तीन सौ ब्रह्मपुरी छ बांकी ॥५॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        लक्ष्मीनाथ का चोक बीच मं बजता ताल मृदंग नाथ की गहरी होव सत्संग ॥<br />
        लक्ष्मीनाथ की महर सु ध्वजा उड़ पचरंग ॥६॥
      </p>
    </>
  );
}

export default LaxminathVandana;
