import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import { baseCDNUrl } from "../../utils/endpoints";
import {
  committeeMembers,
  committeeConsultants,
  committeeAdmins,
  committeeStoreKeepers,
  committeeMediaManagers,
  members,
} from "../../core/committeeProvider";

export default function Committee() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-10 md:mb-20">
          <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            कमिटी मेंबर्स
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            रामलीला कार्यकारणी के पदाधिकारी, व्यवस्थापक, सलाहकार, स्टोर कीपर,
            मीडिया प्रभारी और सदस्य गण
          </p>
        </div>
        <div className="flex flex-wrap -m-4 justify-center">
          {committeeMembers.map((member) => {
            return (
              <div className="p-4 lg:w-1/4 md:w-1/2" key={member._id}>
                <div className="h-full bg-white flex flex-col items-center text-center py-6 px-10 md:px-0 border border-indigo-300 rounded-2xl">
                  <LazyLoadImage
                    alt={member.fullName}
                    className="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                    src={baseCDNUrl + member.avatar}
                    effect="black-and-white"
                    placeholderSrc="/assets/persons/avatarplaceholder.webp"
                    wrapperClassName="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                  />
                  <div className="w-full">
                    <h2 className="title-font font-medium text-lg text-gray-900">
                      {member.fullName}
                    </h2>
                    <h3 className="text-gray-500 mb-1">{member.designation}</h3>
                    <h4 className="text-gray-700">
                      <a href={"tel:" + member.contactNumber}>
                        {member.contactNumber}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col text-center w-full mt-14 mb-8 md:mb-8">
          <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            व्यवस्थापक
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            रामलीला कार्यकारणी के व्यवस्थापक
          </p>
        </div>

        <div className="flex flex-wrap -m-4 justify-center">
          {committeeAdmins.map((admin) => {
            return (
              <div className="p-4 lg:w-1/4 md:w-1/2" key={admin._id}>
                <div className="h-full bg-white flex flex-col items-center text-center py-6 px-10 md:px-0 border border-indigo-300 rounded-2xl">
                  <LazyLoadImage
                    alt={admin.fullName}
                    className="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                    src={baseCDNUrl + admin.avatar}
                    effect="black-and-white"
                    placeholderSrc="/assets/persons/avatarplaceholder.webp"
                    wrapperClassName="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                  />
                  <div className="w-full">
                    <h2 className="title-font font-medium text-lg text-gray-900">
                      {admin.fullName}
                    </h2>
                    <h3 className="text-gray-500 mb-1">{admin.designation}</h3>
                    <h4 className="text-gray-700">
                      <a href={"tel:" + admin.contactNumber}>
                        {admin.contactNumber}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col text-center w-full mt-14 mb-8 md:mb-8">
          <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            सलाहकार
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            रामलीला कार्यकारणी के सलाहकार
          </p>
        </div>

        <div className="flex flex-wrap -m-4 justify-center">
          {committeeConsultants.map((consultant) => {
            return (
              <div className="p-4 lg:w-1/4 md:w-1/2" key={consultant._id}>
                <div className="h-full bg-white flex flex-col items-center text-center py-6 px-10 md:px-0 border border-indigo-300 rounded-2xl">
                  <LazyLoadImage
                    alt={consultant.fullName}
                    className="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                    src={baseCDNUrl + consultant.avatar}
                    effect="black-and-white"
                    placeholderSrc="/assets/persons/avatarplaceholder.webp"
                    wrapperClassName="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                  />
                  <div className="w-full">
                    <h2 className="title-font font-medium text-lg text-gray-900">
                      {consultant.fullName}
                    </h2>
                    <h3 className="text-gray-500 mb-1">
                      {consultant.designation}
                    </h3>
                    <h4 className="text-gray-700">
                      <a href={"tel:" + consultant.contactNumber}>
                        {consultant.contactNumber}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col text-center w-full mt-14 mb-8 md:mb-8">
          <h1 className="text-2xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            स्टोर कीपर
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            रामलीला कार्यकारणी के स्टोर कीपर
          </p>
        </div>

        <div className="flex flex-wrap -m-4 justify-center">
          {committeeStoreKeepers.map((storeKeeper) => {
            return (
              <div className="p-4 lg:w-1/4 md:w-1/2" key={storeKeeper._id}>
                <div className="h-full bg-white flex flex-col items-center text-center py-6 px-10 md:px-0 border border-indigo-300 rounded-2xl">
                  <LazyLoadImage
                    alt={storeKeeper.fullName}
                    className="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                    src={baseCDNUrl + storeKeeper.avatar}
                    effect="black-and-white"
                    placeholderSrc="/assets/persons/avatarplaceholder.webp"
                    wrapperClassName="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                  />
                  <div className="w-full">
                    <h2 className="title-font font-medium text-lg text-gray-900">
                      {storeKeeper.fullName}
                    </h2>
                    <h3 className="text-gray-500 mb-1">
                      {storeKeeper.designation}
                    </h3>
                    <h4 className="text-gray-700">
                      <a href={"tel:" + storeKeeper.contactNumber}>
                        {storeKeeper.contactNumber}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col text-center w-full mt-14 mb-8 md:mb-8">
          <h1 className="text-2xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            मीडिया और मैनेजमेंट प्रभारी
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            रामलीला कार्यकारणी के मीडिया और मैनेजमेंट प्रभारी
          </p>
        </div>

        <div className="flex flex-wrap -m-4 justify-center">
          {committeeMediaManagers.map((mediaManager) => {
            return (
              <div className="p-4 lg:w-1/4 md:w-1/2" key={mediaManager._id}>
                <div className="h-full bg-white flex flex-col items-center text-center py-6 px-10 md:px-0 border border-indigo-300 rounded-2xl">
                  <LazyLoadImage
                    alt={mediaManager.fullName}
                    className="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                    src={baseCDNUrl + mediaManager.avatar}
                    effect="black-and-white"
                    placeholderSrc="/assets/persons/avatarplaceholder.webp"
                    wrapperClassName="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                  />
                  <div className="w-full">
                    <h2 className="title-font font-medium text-lg text-gray-900">
                      {mediaManager.fullName}
                    </h2>
                    <h3 className="text-gray-500 mb-1">
                      {mediaManager.designation}
                    </h3>
                    <h4 className="text-gray-700">
                      <a href={"tel:" + mediaManager.contactNumber}>
                        {mediaManager.contactNumber}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col text-center w-full mt-14 mb-8 md:mb-8">
          <h1 className="text-2xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            सदस्य गण
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            रामलीला कार्यकारणी के सदस्य गण
          </p>
        </div>

        <div className="flex flex-wrap -m-4 justify-center">
          {members.map((member) => {
            return (
              <div className="p-4 lg:w-1/4 md:w-1/2" key={member._id}>
                <div className="h-full bg-white flex flex-col items-center text-center py-6 px-10 md:px-0 border border-indigo-300 rounded-2xl">
                  <LazyLoadImage
                    alt={member.fullName}
                    className="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                    src={baseCDNUrl + member.avatar}
                    effect="black-and-white"
                    placeholderSrc="/assets/persons/avatarplaceholder.webp"
                    wrapperClassName="shadow-lg flex-shrink-0 w-200 h-200 w-48 h-48 md:w-56 md:h-56 object-cover object-center mb-4 rounded-lg"
                  />
                  <div className="w-full">
                    <h2 className="title-font font-medium text-lg text-gray-900">
                      {member.fullName}
                    </h2>
                    <h3 className="text-gray-500 mb-1">{member.designation}</h3>
                    <h4 className="text-gray-700">
                      <a href={"tel:" + member.contactNumber}>
                        {member.contactNumber}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
