import React from "react";

export default function TermsAndConditions() {
  return (
    <section className="text-gray-600 body-font relative py-10 md:py-14">
      <div className="flex flex-col text-center w-full mb-10 md:mb-18">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          नियम और शर्तें
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
          ढाई कड़ी की रामलीला वेबसाइट को उपयोग करने के लिए नियम और शर्ते
        </p>
      </div>
      <div className="container px-5 py-0 md:py-12 mx-auto">
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          क्षतिपूर्ति
        </h1>
        <p className="text-justify">
          आप हमें और हमारे साझेदारों, वकीलों, कर्मचारियों और सहयोगियों (सामूहिक
          रूप से, "संबद्ध पक्ष") को आपके उल्लंघन से संबंधित उचित वकील की फीस
          सहित किसी भी दायित्व, हानि, दावे या व्यय से हानिरहित रखने, बचाव करने
          और क्षतिपूर्ति करने के लिए सहमत हैं। यह समझौता या साइट का उपयोग।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          अहस्तांतरणीय
        </h1>
        <p className="text-justify">
          साइट का उपयोग करने का आपका अधिकार हस्तांतरणीय नहीं है। जानकारी या
          दस्तावेज़ प्राप्त करने के लिए आपको दिया गया कोई भी पासवर्ड या अधिकार
          हस्तांतरणीय नहीं है।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          अस्वीकरण
        </h1>
        <p className="text-justify">
          जानकारी और सेवाओं में बग, त्रुटियाँ, समस्याएँ या अन्य सीमाएँ हो सकती
          हैं। किसी भी जानकारी या सेवा के आपके उपयोग के लिए हमारा और हमारे
          संबद्ध दलों का कोई दायित्व नहीं है। विशेष रूप से, लेकिन इसकी सीमा के
          रूप में नहीं, हम और हमारे संबद्ध पक्ष किसी भी अप्रत्यक्ष, विशेष,
          आकस्मिक, या परिणामी क्षति (व्यवसाय की हानि, लाभ की हानि, मुकदमेबाजी,
          या इसी तरह के नुकसान सहित) के लिए उत्तरदायी नहीं हैं, चाहे वह आधारित
          हो अनुबंध के उल्लंघन, वारंटी के उल्लंघन, अपकृत्य (लापरवाही सहित),
          उत्पाद दायित्व, या अन्यथा, भले ही ऐसे नुकसान की संभावना की सलाह दी गई
          हो। ऊपर बताए गए नुकसान की अस्वीकृति हमारे और आपके बीच सौदेबाजी के आधार
          के मूलभूत तत्वों में से एक है। ऐसी सीमाओं के बिना यह साइट और जानकारी
          प्रदान नहीं की जाएगी। साइट के माध्यम से हमसे प्राप्त कोई भी सलाह या
          जानकारी, चाहे वह मौखिक हो या लिखित, इस समझौते में स्पष्ट रूप से नहीं
          बताई गई कोई वारंटी, प्रतिनिधित्व या गारंटी नहीं देगी।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          सूचना का उपयोग
        </h1>
        <p className="text-justify">
          हम अधिकार सुरक्षित रखते हैं, और आप हमें हमारी गोपनीयता नीति के अनुरूप
          किसी भी तरीके से आपके द्वारा साइट के उपयोग से संबंधित सभी जानकारी और
          आपके द्वारा प्रदान की गई सभी जानकारी के उपयोग और असाइनमेंट के लिए
          अधिकृत करते हैं।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          गोपनीयता नीति
        </h1>
        <p className="text-justify">
          हमारी गोपनीयता नीति, समय-समय पर बदल सकती है, इस समझौते का एक हिस्सा
          है।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          अन्य वेब साइटों के लिंक
        </h1>
        <p className="text-justify">
          इस साइट में अन्य वेबसाइटों के लिंक हैं। हम ऐसी वेब साइटों में व्यक्त
          की गई सामग्री, सटीकता या राय के लिए जिम्मेदार नहीं हैं, और ऐसी वेब
          साइटों की हमारे द्वारा सटीकता या पूर्णता के लिए जांच, निगरानी या जांच
          नहीं की जाती है। हमारी साइट पर किसी भी लिंक की गई वेब साइट को शामिल
          करने का अर्थ हमारे द्वारा लिंक की गई वेब साइट की स्वीकृति या समर्थन
          नहीं है। यदि आप हमारी साइट छोड़ने और इन तृतीय-पक्ष साइटों तक पहुंचने
          का निर्णय लेते हैं, तो आप ऐसा अपने जोखिम पर करते हैं।
        </p>
        <h1 className="text-lg md:text-xl font-semibold py-2 md:my-4">
          प्रश्न या सुझाव
        </h1>
        <p className="text-justify">
          यदि आपके पास कोई प्रश्न या सुझाव है तो हमें एक ईमेल भेजें :{" "}
          <a
            href="mailto:contact@dhaikadikiramleela.com"
            className="text-red-500 leading-relaxed underline"
          >
            contact@dhaikadikiramleela.com
          </a>
        </p>
      </div>
    </section>
  );
}
