import React, { useState } from "react";
import "./Accordion.css";
import { whatIsFaqs } from "../../core/globalProvider";

function AccordionItem({ title, desc }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item">
      <input
        type="checkbox"
        id={title}
        checked={isOpen}
        onChange={() => setIsOpen(!isOpen)}
      />
      <label htmlFor={title} className="accordion-item-title">
        <span className="icon"></span>
        <div>
          <span className="q-mark mr-2 inline-block">Q.</span>
          <div
            className="inline-block"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      </label>
      <div
        className="accordion-item-desc"
        style={{ display: isOpen ? "block" : "none" }}
      >
        {desc}
      </div>
    </div>
  );
}

function Accordion({ title, description }) {
  return <AccordionItem title={title} desc={description} />;
}

export default Accordion;
