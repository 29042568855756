/*
    TODO :: Artists Provider provide the all artists information under the hood.
*/

export const artists = [
  {
    _id: "d0aaf599-9720-4949-9acf-2948523b1e69",
    avatar: "persons/pic3.webp",
    fullName: "बृजसुंदर जी केवट",
    designation: "राम, माली",
    description:
      "बृजसुंदर जी केवट रामलीला मैं भगवन श्री राम का और लंका की अशोक वाटिका के माली का किरदार निभाते है । इनका मुख्य किरदार श्री राम का है ।",
  },
  {
    _id: "60a351dc-0e4a-4316-9cff-9327090757ee",
    avatar: "persons/pic47.webp",
    fullName: "रामप्रसाद जी सेन",
    designation: "रावण, केवट, नरान्तक, कौशल्या",
    description:
      "रामप्रसाद जी सेन रामलीला मैं लंकापति रावण का, केवट का, नरान्तक और कौशल्या का किरदार निभाते है । इनका मुख्य किरदार लंकापति रावण और नरान्तक का है ।",
  },
  {
    _id: "a7b5ab73-e544-4f0d-9ebc-35fe026b53ee",
    avatar: "persons/pic16.webp",
    fullName: "लोकेश जी दाधीच",
    designation: "मेघनाथ, अक्षय कुमार",
    description:
      "लोकेश जी दाधीच रामलीला मैं मेघनाथ का और अक्षय कुमार का किरदार निभाते है । इनका मुख्य किरदार मेघनाथ और अक्षय कुमार का है ।",
  },
  {
    _id: "aabbf178-b492-4763-ba8c-e6a54cc2f7e3",
    avatar: "persons/pic21.webp",
    fullName: "हंसराज जी मालव",
    designation: "मेघनाथ, त्रिजटा, परशुराम, लक्ष्मण, सुमित्रा",
    description:
      "हंसराज जी मालव रामलीला मैं मेघनाथ का, त्रिजटा का, परशुराम, लक्ष्मण और सुमित्रा का किरदार निभाते है । इनका मुख्य किरदार लक्ष्मण , त्रिजटा और मेघनाथ का है ।",
  },
  {
    _id: "26cd0dcd-7fde-42cd-a031-693a3c823853",
    avatar: "persons/pic5.webp",
    fullName: "रामेश्वर जी कटारिया",
    designation: "सीता, सुलोचना, तारा, शत्रुघ्न",
    description:
      "रामेश्वर जी कटारिया रामलीला मैं सीता, सुलोचना का, तारा और शत्रुघ्न का किरदार निभाते है । इनका मुख्य किरदार सीता और सुलोचना का है ।",
  },
  {
    _id: "21f1dcc4-35e9-4b5b-a95f-8a8a0ac3b311",
    avatar: "persons/pic24.webp",
    fullName: "धनराज जी भील",
    designation: "शबरी, केकेय, शूर्पणखा, मालन, लंकिनी, सुरसा, निषादराज, सखी",
    description:
      "धनराज जी भील रामलीला मैं शबरी, केकेय का, शूर्पणखा, मालन, लंकिनी, सुरसा, निषादराज और सखी का किरदार निभाते है । इनका मुख्य किरदार निषादराज , शूर्पणखा, शबरी और लंकिनी का है ।",
  },
  {
    _id: "9b0b62a0-8d7c-49a6-89ab-68b6c544e112",
    avatar: "persons/pic20.webp",
    fullName: "चोथमल जी गोचर",
    designation: "मन्थरा, वैद्य जी, दुंदुभी, खर",
    description:
      "चोथमल जी गोचर रामलीला मैं मन्थरा, वैद्य जी का, दुंदुभी और खर का किरदार निभाते है । इनका मुख्य किरदार मन्थरा, वैद्य जी का, दुंदुभी और खर का है ।",
  },
  {
    _id: "1bc20792-0019-41ad-b035-cf3082ca7cb2",
    avatar: "persons/pic8.webp",
    fullName: "सुशिल जी पांचाल",
    designation: "सुग्रीव, मंत्री सुमंत, हनुमान्",
    description:
      "सुशिल जी पांचाल रामलीला मैं सुग्रीव का, मंत्री सुमंत का और हनुमान् का किरदार निभाते है । इनका मुख्य किरदार सुग्रीव और सुमंत का है ।",
  },
  {
    _id: "efe34959-175f-457c-9279-dc44b98093d3",
    avatar: "persons/pic18.webp",
    fullName: "वेद प्रकाश जी शर्मा",
    designation: "हनुमान जी, बालि, भरत, जोगी रावण",
    description:
      "वेद प्रकाश जी शर्मा रामलीला मैं हनुमान जी, बालि का, भरत और जोगी रावण का किरदार निभाते है । इनका मुख्य किरदार हनुमान जी, बालि और भरत का है ।",
  },
  {
    _id: "aa44cceb-2d93-4d1c-ba48-e94921b58eda",
    avatar: "persons/pic17.webp",
    fullName: "मुकुट गुर्जर",
    designation: "अंगद, दूषण, दूत, विराट",
    description:
      "मुकुट गुर्जर रामलीला मैं अंगद, दूषण, दूत और विराट का किरदार निभाते है । इनका मुख्य किरदार अंगद, दूषण, दूत और विराट का है ।",
  },
  {
    _id: "37f858bb-e340-4781-8fb6-a62bdb763c53",
    avatar: "persons/pic23.webp",
    fullName: "रामसिंह जी केवट",
    designation: "मन्दोदरी, केकेय, कौशल्या",
    description:
      "रामसिंह जी केवट रामलीला मैं मन्दोदरी, केकेय का और कौशल्या का किरदार निभाते है । इनका मुख्य किरदार मन्दोदरी, केकेय और कौशल्या का है ।",
  },
  {
    _id: "cc9fb9f0-14ce-4c19-80fe-141e4e20a755",
    avatar: "persons/avatarplaceholder.webp",
    fullName: "राधेश्याम जी सेन",
    designation: "कुम्भकर्ण, मालन, विराट",
    description:
      "राधेश्याम जी सेन रामलीला मैं कुम्भकर्ण, मालन का और विराट का किरदार निभाते है । इनका मुख्य किरदार कुम्भकर्ण, मालन का और विराट का है ।",
  },
  {
    _id: "15c66fd3-e940-4c61-94d2-fcf3f87e3330",
    avatar: "persons/pic42.webp",
    fullName: "रामप्रसाद जी मेहरा",
    designation: "मृग, जोगी, नृत्यीका",
    description:
      "रामप्रसाद जी मेहरा रामलीला मैं मृग, जोगी का और नृत्यीका का किरदार निभाते है । इनका मुख्य किरदार मृग, जोगी और नृत्यीका का है ।",
  },
  {
    _id: "b14444f4-ab5b-49f7-ad43-ce38babd2f38",
    avatar: "persons/pic46.webp",
    fullName: "देवीशंकर मालव",
    designation: "जामवंत, मारीच, सेनापति, दूत",
    description:
      "देवीशंकर मालव रामलीला मैं जामवंत, मारीच का, सेनापति और दूत का किरदार निभाते है । इनका मुख्य किरदार जामवंत, मारीच और सेनापति का है ।",
  },
];

/*
    TODO :: CoArtists
*/

export const coArtists = [
  {
    _id: "5c00ee9b-060b-42e8-b6df-01b824566350",
    avatar: "persons/pic15.webp",
    fullName: "दीनबंधु केवट",
    designation: "सहकलाकार",
    description:
      "दीनबंधु केवट रामलीला मैं कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "a2a20253-503e-4fe6-916f-6d47303672df",
    avatar: "persons/pic13.webp",
    fullName: "हेमंत सेन",
    designation: "सहकलाकार",
    description:
      "हेमंत सेन रामलीला मैं कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "b6e8b50e-99f4-420a-930d-f5721060ebe4",
    avatar: "persons/pic2.webp",
    fullName: "कृष्ण मुरारी जी मालव",
    designation: "मेघनाथ",
    description:
      "कृष्ण मुरारी मालव रामलीला मैं कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "f303833b-38da-4f7c-824c-b39cff8a551e",
    avatar: "persons/pic14.webp",
    fullName: "अमरलाल जी मालव",
    designation: "मंत्री, द्वारपाल, माली के साथ",
    description:
      "अमरलाल मालव रामलीला मैं कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "f592fe81-a4e1-4b6a-9cfc-9ec8d91b9691",
    avatar: "persons/pic4.webp",
    fullName: "धर्मवीर कटारिया",
    designation: "सहकलाकार",
    description:
      "धर्मवीर कटारिया रामलीला में झाकिया, कलाकारों का मेक-अप, रंगमंच पर कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "e41d7a0f-21d8-481d-994b-3357c40f50d7",
    avatar: "persons/pic32.webp",
    fullName: "रविन्द्र कुमार नागर",
    designation: "सहकलाकार",
    description:
      "रविन्द्र कुमार नागर रामलीला में झाकिया, कलाकारों का मेक-अप, रंगमंच पर कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "a10eaf88-dee0-4849-a70e-32c02e6e7734",
    avatar: "persons/pic39.webp",
    fullName: "भारत नागर",
    designation: "सहकलाकार",
    description:
      "भारत नागर रामलीला में झाकिया, कलाकारों का मेक-अप, रंगमंच पर कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "5742d01f-25ca-481a-82ad-bf4f7879ed08",
    avatar: "persons/pic44.webp",
    fullName: "अनिल नागर",
    designation: "सहकलाकार",
    description:
      "अनिल नागर रामलीला में झाकिया, कलाकारों का मेक-अप, रंगमंच पर कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "596531a9-501e-4745-a855-1a65ffe1d225",
    avatar: "persons/pic41.webp",
    fullName: "विनोद जी मालव",
    designation: "सहकलाकार",
    description:
      "विनोद जी मालव रामलीला में झाकिया, कलाकारों का मेक-अप, रंगमंच पर कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "c2743ecc-e76a-4260-af86-3a009b3b4426",
    avatar: "persons/pic37.webp",
    fullName: "मोहित वैष्णव",
    designation: "सहकलाकार",
    description:
      "मोहित वैष्णव रामलीला में झाकिया, कलाकारों का मेक-अप, रंगमंच पर कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
  {
    _id: "d41bd8f3-9959-454a-a505-7fe61f6bcd25",
    avatar: "persons/pic38.webp",
    fullName: "हेमंत नंदवाना",
    designation: "सहकलाकार",
    description:
      "हेमंत नंदवाना रामलीला में झाकिया, कलाकारों का मेक-अप, रंगमंच पर कलाकारों का सहयोग, कलाकारों के गायन एवं बजाने में साथ देते है एवं सहयोग करते है ।",
  },
];

/*
    TODO :: Harmonium Artists
*/

export const harmoniumArtists = [
  {
    _id: "9a3f4181-f61e-4fb1-a5ae-be3a329edbc4",
    avatar: "persons/pic90.webp",
    fullName: "श्री देवकी नंदन शर्मा",
    designation: "हारमोनियम वादक",
    description:
      "श्री देवकी नंदन शर्मा रामलीला मैं हारमोनियम वादक है। यह रामलीला मैं लगभग 25 सालो से हारमोनियम वादक है।",
  },
  {
    _id: "9ae22de7-7a22-4524-aa8f-8625b11e1424",
    avatar: "persons/pic19.webp",
    fullName: "श्री इन्द्रजीत केवट",
    designation: "हारमोनियम वादक",
    description:
      "श्री इन्द्रजीत केवट रामलीला मैं हारमोनियम वादक है। यह रामलीला मैं लगभग 2 सालो से हारमोनियम वादक है।",
  },
  {
    _id: "82cc4378-9888-49b4-816d-0b5244190ac4",
    avatar: "persons/pic3.webp",
    fullName: "श्री बृजसुंदर केवट",
    designation: "हारमोनियम वादक",
    description:
      "श्री बृजसुंदर केवट रामलीला मैं हारमोनियम वादक है। यह रामलीला मैं लगभग 2 सालो से हारमोनियम वादक है।",
  },
  // {
  //   _id: "82cc4378-9888-49b4-816d-0b5244190ac4",
  //   avatar: "persons/avatarplaceholder.webp",
  //   fullName: "श्री नवल पांचाल",
  //   designation: "हारमोनियम वादक",
  //   description:
  //     "श्री नवल पांचाल रामलीला मैं हारमोनियम वादक थे। इन्होने कही सालो तक रामलीला मैं हारमोनियम वादक का कार्य किया।",
  // },
  // {
  //   _id: "82cc4378-9888-49b4-816d-0b5244190ac4",
  //   avatar: "persons/avatarplaceholder.webp",
  //   fullName: "श्री रामधन धन्वन्तरि",
  //   designation: "हारमोनियम वादक",
  //   description:
  //     "श्री रामधन धन्वन्तरि रामलीला मैं हारमोनियम वादक थे। इन्होने कही सालो तक रामलीला मैं हारमोनियम वादक का कार्य किया।",
  // },
  // {
  //   _id: "82cc4378-9888-49b4-816d-0b5244190ac4",
  //   avatar: "persons/avatarplaceholder.webp",
  //   fullName: "श्री जगनाथ मीणा",
  //   designation: "हारमोनियम वादक",
  //   description:
  //     "श्री जगनाथ मीणा रामलीला मैं हारमोनियम वादक थे। इन्होने कही सालो तक रामलीला मैं हारमोनियम वादक का कार्य किया।",
  // },
  // {
  //   _id: "82cc4378-9888-49b4-816d-0b5244190ac4",
  //   avatar: "persons/avatarplaceholder.webp",
  //   fullName: "श्री कर्मचन्द जांगिड़",
  //   designation: "हारमोनियम वादक",
  //   description:
  //     "श्री कर्मचन्द जांगिड़ रामलीला मैं हारमोनियम वादक थे। इन्होने कही सालो तक रामलीला मैं हारमोनियम वादक का कार्य किया।",
  // },
];

/*
    TODO :: Tabla Artists
*/

export const tablaArtists = [
  {
    _id: "e42f068a-85bd-40fa-801c-1a12137255e8",
    avatar: "persons/pic35.webp",
    fullName: "श्री अमृतलाल रावल",
    designation: "तबला वादक",
    description:
      "श्री अमृतलाल रावल रामलीला मैं तबला वादक है। इन्होने रामलीला मैं लगभग 15 सालो तक तबला बजाय था। अभी उनके भाई और पुत्र तबला वादक है।",
  },
  {
    _id: "e853bbba-a635-4987-a343-cc44ec6fa6eb",
    avatar: "persons/pic6.webp",
    fullName: "श्री सज्जन रावल",
    designation: "तबला वादक",
    description:
      "श्री सज्जन रावल रामलीला मैं तबला वादक है। यह अमृतलाल जी रावल के सबसे छोटे भाई है। यह रामलीला मैं लगभग 7 सालो से तबला वादक है।",
  },
  {
    _id: "7cdb8025-a079-4ee7-9dc2-55769dfe86a0",
    avatar: "persons/pic65.webp",
    fullName: "श्री कपिल रावल",
    designation: "तबला वादक",
    description:
      "श्री कपिल रावल रामलीला मैं तबला वादक है। यह अमृतलाल जी रावल के पुत्र है। यह रामलीला मैं लगभग 5 सालो से तबला वादक है।",
  },
  {
    _id: "e42f068a-85bd-40fa-801c-1a12137255e8",
    avatar: "persons/avatarplaceholder.webp",
    fullName: "श्री मदन रावल",
    designation: "तबला वादक",
    description:
      "श्री मदन रावल रामलीला मैं पहले तबला वादक थे। इन्होने कही सालो तक रामलीला मैं तबला वादक का कार्य किया।",
  },
];

/*
    TODO :: Manjira Artists
*/

export const manjiraArtists = [
  {
    _id: "33dc5678-daad-4ac7-8471-001b9628ae89",
    avatar: "persons/pic88.webp",
    fullName: "श्री श्रवणलाल प्रजापत",
    designation: "मंजीरा वादक",
    description:
      "श्री श्रवणलाल प्रजापत रामलीला मैं मंजीरा वादक है। यह रामलीला मैं लगभग 15 सालो से मंजीरा वादक है।",
  },
  {
    _id: "2c3514da-71e2-49ca-91df-521bccb04365",
    avatar: "persons/pic25.webp",
    fullName: "श्री नरेन्र्द केवट",
    designation: "मंजीरा वादक",
    description:
      "श्री नरेन्र्द केवट रामलीला मैं मंजीरा वादक है। यह रामलीला मैं लगभग 5 सालो से मंजीरा वादक है।",
  },
  {
    _id: "2c3514da-71e2-49ca-91df-521bccb04365",
    avatar: "persons/pic45.webp",
    fullName: "श्री राम भरोस केवट",
    designation: "मंजीरा वादक",
    description:
      "श्री राम भरोस केवट रामलीला मैं मंजीरा वादक है। यह रामलीला मैं लगभग 5 सालो से मंजीरा वादक है।",
  },
  // {
  //   _id: "98637575-30b2-4b14-95e4-6c89d7418f8c",
  //   avatar: "persons/avatarplaceholder.webp",
  //   fullName: "श्री राधेश्याम सेन",
  //   designation: "मंजीरा वादक",
  //   description:
  //     "श्री राधेश्याम सेन रामलीला मैं मंजीरा वादक है। यह रामलीला मैं लगभग 10 सालो से मंजीरा वादक है।",
  // },
  // {
  //   _id: "98637575-30b2-4b14-95e4-6c89d7418f8c",
  //   avatar: "persons/avatarplaceholder.webp",
  //   fullName: "श्री नैमीचंद शर्मा",
  //   designation: "मंजीरा वादक",
  //   description:
  //     "श्री नैमीचंद शर्मा रामलीला मैं मंजीरा वादक है। इन्होने कही सालो तक रामलीला मैं मंजीरा वादक का कार्य किया।",
  // },
  // {
  //   _id: "98637575-30b2-4b14-95e4-6c89d7418f8c",
  //   avatar: "persons/avatarplaceholder.webp",
  //   fullName: "श्री भवानी शंकर सेन",
  //   designation: "मंजीरा वादक",
  //   description:
  //     "श्री भवानी शंकर सेन रामलीला मैं मंजीरा वादक है। इन्होने कही सालो तक रामलीला मैं मंजीरा वादक का कार्य किया।",
  // },
];

/*
    TODO :: Violin Artists
*/

export const violinArtists = [
  {
    _id: "8d2a01bb-f345-44fb-87a9-210cbc69416a",
    avatar: "persons/avatarplaceholder.webp",
    fullName: "श्री दानमल रावल",
    designation: "चिकारा / वायलिन वादक",
    description:
      "श्री दानमल रावल रामलीला मैं मंजीरा वादक थे। इन्होने कही सालो तक रामलीला मैं चिकारा / वायलिन वादक का कार्य किया है।",
  },
];

/*
    TODO :: Keelalet Artists
*/

export const keelaletArtists = [
  {
    _id: "8d2a01bb-f345-44fb-87a9-210cbc69416a",
    avatar: "persons/avatarplaceholder.webp",
    fullName: "श्री मदन मेघवाल",
    designation: "कीलालेट वादक",
    description:
      "श्री मदन मेघवाल रामलीला मैं कीलालेट वादक थे। इन्होने कही सालो तक रामलीला मैं कीलालेट वादक का कार्य किया है।",
  },
];

/*
    TODO :: Naqareh Artists
*/

export const naqarehArtists = [
  {
    _id: "8d2a01bb-f345-44fb-87a9-210cbc69416a",
    avatar: "persons/avatarplaceholder.webp",
    fullName: "श्री नवल रावल",
    designation: "नगाड़ा वादक",
    description:
      "श्री नवल रावल रामलीला मैं नगाड़ा वादक है। इन्होने कही सालो तक रामलीला मैं नगाड़ा वादक का कार्य किया है।",
  },
];
