import React from "react";

function DhanrajBheel() {
  return (
    <div>
      <p>धनराज जी भील</p>
    </div>
  );
}

export default DhanrajBheel;
