import React, { useState } from "react";
import { baseCDNUrl } from "../../utils/endpoints";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { artistTypes } from "../../utils/utils";
import {
  artists,
  coArtists,
  harmoniumArtists,
  tablaArtists,
  manjiraArtists,
  violinArtists,
  keelaletArtists,
  naqarehArtists,
} from "../../core/artistsProvider";

export default function Artists() {
  const [selectedType, setSelectedType] = useState(0);

  return (
    <section className="text-gray-600 body-font md:mb-20">
      <div className="container px-5 py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-10 md:mb-20">
          <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
            कलाकार
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
            रामलीला कार्यकारणी के कलाकार, वाद्य यंत्र वादक और सहयोगी सदस्य गण
          </p>
          <div className="tabs flex mx-2 md:mx-auto border-2 border-[#ff5528] rounded overflow-x-scroll overflow-y-hidden mt-6 scrollbar-none">
            {artistTypes.map((type) => {
              return (
                <>
                  {selectedType === artistTypes.indexOf(type) ? (
                    <button className="py-2 px-4 bg-[#ff5528] text-white focus:outline-none whitespace-nowrap">
                      {type}
                    </button>
                  ) : (
                    <button
                      className="py-2 px-4 whitespace-nowrap"
                      onClick={() => {
                        setSelectedType(artistTypes.indexOf(type));
                      }}
                    >
                      {type}
                    </button>
                  )}
                </>
              );
            })}
          </div>
        </div>
        <div className="flex flex-wrap -m-4">
          {selectedType === 0 ? (
            artists.map((artist) => {
              return (
                <div className="p-4 md:pb-10 lg:w-1/2" key={artist._id}>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <LazyLoadImage
                      alt={artist.fullName}
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={baseCDNUrl + artist.avatar}
                      effect="black-and-white"
                      placeholderSrc="/assets/persons/avatarplaceholder.webp"
                      wrapperClassName="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl text-gray-900">
                        {artist.fullName}
                      </h2>
                      <h3 className="text-gray-500 mb-3">
                        {artist.designation}
                      </h3>
                      <p className="mb-4">{artist.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}

          {selectedType === 1 ? (
            coArtists.map((artist) => {
              return (
                <div className="p-4 md:pb-10 lg:w-1/2" key={artist._id}>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <LazyLoadImage
                      alt={artist.fullName}
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={baseCDNUrl + artist.avatar}
                      effect="black-and-white"
                      placeholderSrc="/assets/persons/avatarplaceholder.webp"
                      wrapperClassName="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl text-gray-900">
                        {artist.fullName}
                      </h2>
                      <h3 className="text-gray-500 mb-3">
                        {artist.designation}
                      </h3>
                      <p className="mb-4">{artist.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}

          {selectedType === 2 ? (
            harmoniumArtists.map((artist) => {
              return (
                <div className="p-4 md:pb-10 lg:w-1/2" key={artist._id}>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <LazyLoadImage
                      alt={artist.fullName}
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={baseCDNUrl + artist.avatar}
                      effect="black-and-white"
                      placeholderSrc="/assets/persons/avatarplaceholder.webp"
                      wrapperClassName="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl text-gray-900">
                        {artist.fullName}
                      </h2>
                      <h3 className="text-gray-500 mb-3">
                        {artist.designation}
                      </h3>
                      <p className="mb-4">{artist.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}

          {selectedType === 3 ? (
            tablaArtists.map((artist) => {
              return (
                <div className="p-4 md:pb-10 lg:w-1/2" key={artist._id}>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <LazyLoadImage
                      alt={artist.fullName}
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={baseCDNUrl + artist.avatar}
                      effect="black-and-white"
                      placeholderSrc="/assets/persons/avatarplaceholder.webp"
                      wrapperClassName="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl text-gray-900">
                        {artist.fullName}
                      </h2>
                      <h3 className="text-gray-500 mb-3">
                        {artist.designation}
                      </h3>
                      <p className="mb-4">{artist.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}

          {selectedType === 4 ? (
            manjiraArtists.map((artist) => {
              return (
                <div className="p-4 md:pb-10 lg:w-1/2" key={artist._id}>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <LazyLoadImage
                      alt={artist.fullName}
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={baseCDNUrl + artist.avatar}
                      effect="black-and-white"
                      placeholderSrc="/assets/persons/avatarplaceholder.webp"
                      wrapperClassName="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl text-gray-900">
                        {artist.fullName}
                      </h2>
                      <h3 className="text-gray-500 mb-3">
                        {artist.designation}
                      </h3>
                      <p className="mb-4">{artist.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}

          {selectedType === 5 ? (
            violinArtists.map((artist) => {
              return (
                <div className="p-4 md:pb-10 lg:w-1/2" key={artist._id}>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <LazyLoadImage
                      alt={artist.fullName}
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={baseCDNUrl + artist.avatar}
                      effect="black-and-white"
                      placeholderSrc="/assets/persons/avatarplaceholder.webp"
                      wrapperClassName="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl text-gray-900">
                        {artist.fullName}
                      </h2>
                      <h3 className="text-gray-500 mb-3">
                        {artist.designation}
                      </h3>
                      <p className="mb-4">{artist.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}

          {selectedType === 6 ? (
            keelaletArtists.map((artist) => {
              return (
                <div className="p-4 md:pb-10 lg:w-1/2" key={artist._id}>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <LazyLoadImage
                      alt={artist.fullName}
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={baseCDNUrl + artist.avatar}
                      effect="black-and-white"
                      placeholderSrc="/assets/persons/avatarplaceholder.webp"
                      wrapperClassName="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl text-gray-900">
                        {artist.fullName}
                      </h2>
                      <h3 className="text-gray-500 mb-3">
                        {artist.designation}
                      </h3>
                      <p className="mb-4">{artist.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}

          {selectedType === 7 ? (
            naqarehArtists.map((artist) => {
              return (
                <div className="p-4 md:pb-10 lg:w-1/2" key={artist._id}>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <LazyLoadImage
                      alt={artist.fullName}
                      className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={baseCDNUrl + artist.avatar}
                      effect="black-and-white"
                      placeholderSrc="/assets/persons/avatarplaceholder.webp"
                      wrapperClassName="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl text-gray-900">
                        {artist.fullName}
                      </h2>
                      <h3 className="text-gray-500 mb-3">
                        {artist.designation}
                      </h3>
                      <p className="mb-4">{artist.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </section>
  );
}
