import React from "react";
import Slider from "react-slick";
// import "./VerticalSlider.css";

const VerticalSlider = ({ children }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 2300,
    vertical: true,
    verticalSwiping: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="slider-container px-4">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default VerticalSlider;
