import React, { useState, useEffect } from "react";
import Drawer from "./Drawer";

function Header(props) {
  const { openModal } = props;
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "scroll");
  }, [open]);
  return (
    <>
      <header
        className="sticky hidden md:sticky top-0 md:flex md:flex-row md:justify-between md:items-center bg-white px-16 2xl:px-44 z-20"
        style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 5px 0px" }}
      >
        <div>
          <a href="/" title="Dhai Kadi Ki Ramleela Official Logo">
            <img
              src="/assets/imgs/logo.jpg"
              height="70"
              width="70"
              alt="Dhai Kadi Ki Ramleela Official Logo"
            />
          </a>
        </div>
        <div className="hidden lg:block h-full">
          <ul className="flex flex-row justify-between gap-12 xl:gap-14 h-full">
            <li className="py-10 text-gray-500 font-medium px-2 border-t-[3px] border-transparent hover:border-[#ff5528] hover:text-[#ff5528] transition-all duration-500">
              <a href="/">होम</a>
            </li>
            <li className="group relative py-10 text-gray-500 font-medium px-2 border-t-[3px] border-transparent hover:border-[#ff5528] hover:text-[#ff5528] transition-all duration-500">
              <div className="cursor-pointer">हमारे बारे में जाने</div>
              <div className="hidden absolute -bottom-[190px] bg-white shadow-sm rounded-bl-lg rounded-br-lg pl-3 pr-14 whitespace-nowrap py-2 group-hover:block transition-all duration-500 ">
                <ul>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/history-of-ramleela">रामलीला का इतिहास</a>
                  </li>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/artists">कलाकार</a>
                  </li>
                  {/* <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/committee">कमिटी मेंबर्स</a>
                  </li> */}
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/ramleela-ka-manchan">रामलीला का मंचन</a>
                  </li>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/contact-us">संपर्क करें</a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="group relative py-10 text-gray-500 font-medium px-2 border-t-[3px] border-transparent hover:border-[#ff5528] hover:text-[#ff5528] transition-all duration-500">
              <div className="cursor-pointer">रामलीला मंचन</div>
              <div className="hidden absolute -bottom-[250px] bg-white shadow-sm rounded-bl-lg rounded-br-lg pl-3 pr-14 whitespace-nowrap py-2 group-hover:block transition-all duration-500 ">
                <ul>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/125-years-celebration-mahotsav-hindu-college">
                      दिल्ली, भारत
                    </a>
                  </li>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/">दसलानां, राजस्थान</a>
                  </li>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/">अयोध्या, उत्तरप्रदेश</a>
                  </li>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/">चित्रकूट, मध्य प्रदेश</a>
                  </li>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/">भोपाल, मध्य प्रदेश</a>
                  </li>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/">कोटा, राजस्थान</a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="group relative py-10 text-gray-500 font-medium px-2 border-t-[3px] border-transparent hover:border-[#ff5528] hover:text-[#ff5528] transition-all duration-500">
              <div className="cursor-pointer">गेलरी</div>
              <div className="hidden absolute -bottom-[95px] bg-white shadow-sm rounded-bl-lg rounded-br-lg pl-3 pr-14 whitespace-nowrap py-2 group-hover:block transition-all duration-500 ">
                <ul>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/photo-gallery">फ़ोटो गेलरी</a>
                  </li>
                  <li className="py-2 text-gray-500 transition-all duration-500 hover:text-[#ff5528]">
                    <a href="/video-gallery">वीडियो गेलरी</a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="py-10 text-gray-500 font-medium px-2 border-t-[3px] border-transparent hover:border-[#ff5528] hover:text-[#ff5528] transition-all duration-500">
              <a href="/programs">वार्षिक कार्यक्रम</a>
            </li>
            <li className="py-10 text-gray-500 font-medium px-2 border-t-[3px] border-transparent hover:border-[#ff5528] hover:text-[#ff5528] transition-all duration-500">
              <a href="/news-and-articles">समाचार और लेख</a>
            </li>
            <li className="hidden 2xl:block py-10 text-gray-500 font-medium px-2 border-t-[3px] border-transparent hover:border-[#ff5528] hover:text-[#ff5528] transition-all duration-500">
              <a href="/faqs">पूछे जाने वाले प्रश्न</a>
            </li>
            <li className="py-10 text-gray-500 font-medium px-2 border-t-[3px] border-transparent hover:border-[#ff5528] hover:text-[#ff5528] transition-all duration-500">
              <a href="/contact-us">संपर्क करें</a>
            </li>
          </ul>
        </div>
        <div className="hidden lg:block">
          <button
            onClick={openModal}
            className="group bg-[#ff5528] pl-1 pr-5 font-semibold py-1 rounded-3xl text-white hover:bg-black hover:text-white transition-all duration-500"
            role="button"
            aria-labelledby="donateBtn"
          >
            <i className="fa-solid fa-heart mr-5 rounded-full bg-white text-[#ff5528] p-2 group-hover:text-white group-hover:bg-[#ff5528] transition-all duration-500"></i>
            Donate Now
          </button>
        </div>
      </header>

      {/* 
        TODO :: Mobile Header 
      */}

      <header
        className="md:hidden sticky top-0 grid grid-cols-4 items-center bg-white py-2 px-4 z-20"
        style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 5px 0px" }}
      >
        <div className="col-span-1">
          <button
            onClick={handleToggle}
            className="px-3 py-1 border-[1px] border-gray-300 rounded-md  text-gray-500"
            role="button"
            aria-labelledby="drawerBtn"
          >
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        <div className="col-span-2 flex justify-center">
          <a href="/" title="Ramleela Official Logo">
            <img
              src="/assets/imgs/logo.jpg"
              height="80"
              width="80"
              alt="Ramleela Official Logo"
            />
          </a>
        </div>
        <div className="col-span-1 flex justify-end">
          <button
            onClick={openModal}
            className="group px-1 py-1 text-sm font-semibold rounded-3xl text-white hover:bg-black hover:text-white transition-all duration-500 border-[1px] border-gray-300"
            role="button"
            aria-labelledby="donateBtn"
          >
            <i className="fa-solid fa-circle-dollar-to-slot text-[15px] rounded-full bg-[#ff5528] text-white px-2 py-[8px] group-hover:text-white group-hover:bg-[#ff5528] transition-all duration-500"></i>
          </button>
        </div>
      </header>
      <Drawer open={open} handleToggle={handleToggle} />
    </>
  );
}

export default Header;
