import React from "react";
import "./AnimatedUnderline.css";

const AnimatedUnderline = ({ children }) => {
  return (
    <span class="underline-animation">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 32.61">
        <path
          d="M5 14.11s54-8 125-9 120 5 120 5-200.5-5.5-239.5 17.5"
          className="stroke"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="10"
        />
      </svg>
      {children}
    </span>
  );
};

export default AnimatedUnderline;
