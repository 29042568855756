import React, { useEffect } from "react";
import { baseCDNUrl } from "../../../utils/endpoints";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function PhotoGallery() {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        display: {
          right: [
            "iterateZoom",
            "slideshow",
            "download",
            "fullscreen",
            "thumbs",
            "close",
          ],
        },
      },
    });
  }, []);

  return (
    <section className="bg-[#f2f0ec] flex flex-col items-center justify-center py-10">
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-5 mx-auto flex flex-wrap">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="glow text-3xl md:text-5xl font-medium mb-1 md:mb-4 text-blue-900">
              फ़ोटो गेलरी
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              ढाई कड़ी की रामलीला मंचन के फोटो
            </p>
          </div>
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="flex flex-wrap w-1/2">
              <div className="md:p-2 p-1 w-1/2">
                <a data-gallery="manual" href={baseCDNUrl + "imgs/pic6.webp"}>
                  <LazyLoadImage
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={baseCDNUrl + "imgs/pic6.webp"}
                    effect="blur"
                    placeholderSrc={baseCDNUrl + "imgs/thumb/pic6.webp"}
                    wrapperClassName="w-full object-cover h-full object-center block"
                    data-fancybox="मेघनाथ, रावण"
                    data-src={baseCDNUrl + "imgs/pic6.webp"}
                    data-caption="मेघनाथ, रावण"
                    data-download-src={baseCDNUrl + "imgs/pic6.webp"}
                  />
                </a>
              </div>
              <div className="md:p-2 p-1 w-1/2">
                <a data-gallery="manual" href={baseCDNUrl + "imgs/pic7.webp"}>
                  <LazyLoadImage
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={baseCDNUrl + "imgs/pic7.webp"}
                    effect="blur"
                    placeholderSrc={baseCDNUrl + "imgs/thumb/pic7.webp"}
                    wrapperClassName="aspect-w-289 aspect-h-217 object-cover h-full object-center block"
                    data-fancybox="हनुमान जी, राम"
                    data-src={baseCDNUrl + "imgs/pic7.webp"}
                    data-caption="हनुमान जी, राम"
                    data-download-src={baseCDNUrl + "imgs/pic7.webp"}
                  />
                </a>
              </div>
              <div className="md:p-2 p-1 w-full">
                <a data-gallery="manual" href={baseCDNUrl + "imgs/pic1.webp"}>
                  <LazyLoadImage
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={baseCDNUrl + "imgs/pic1.webp"}
                    effect="blur"
                    placeholderSrc={baseCDNUrl + "imgs/thumb/pic1.webp"}
                    wrapperClassName="w-full object-cover h-full object-center block"
                    data-fancybox="अशोक वाटिका के माली और मालन"
                    data-src={baseCDNUrl + "imgs/pic1.webp"}
                    data-caption="अशोक वाटिका के माली और मालन"
                    data-download-src={baseCDNUrl + "imgs/pic1.webp"}
                  />
                </a>
              </div>
            </div>
            <div className="flex flex-wrap w-1/2">
              <div className="md:p-2 p-1 w-full">
                <a data-gallery="manual" href={baseCDNUrl + "imgs/pic2.webp"}>
                  <LazyLoadImage
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={baseCDNUrl + "imgs/pic2.webp"}
                    effect="blur"
                    placeholderSrc={baseCDNUrl + "imgs/thumb/pic2.webp"}
                    wrapperClassName="aspect-w-20 aspect-h-9 object-cover h-full object-center block"
                    data-fancybox="भारत माता, लक्ष्मण, हनुमान, राम, धरती माता"
                    data-src={baseCDNUrl + "imgs/pic2.webp"}
                    data-caption="भारत माता, लक्ष्मण, हनुमान, राम, धरती माता"
                    data-download-src={baseCDNUrl + "imgs/pic2.webp"}
                  />
                </a>
              </div>
              <div className="md:p-2 p-1 w-1/2">
                <a data-gallery="manual" href={baseCDNUrl + "imgs/pic3.webp"}>
                  <LazyLoadImage
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={baseCDNUrl + "imgs/pic3.webp"}
                    effect="blur"
                    placeholderSrc={baseCDNUrl + "imgs/thumb/pic3.webp"}
                    wrapperClassName="w-full object-cover h-full object-center block"
                    data-fancybox="हनुमान जी"
                    data-src={baseCDNUrl + "imgs/pic3.webp"}
                    data-caption="हनुमान जी"
                    data-download-src={baseCDNUrl + "imgs/pic3.webp"}
                  />
                </a>
              </div>
              <div className="md:p-2 p-1 w-1/2 relative cursor-pointer">
                <a data-gallery="manual" href={baseCDNUrl + "imgs/pic4.webp"}>
                  <LazyLoadImage
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={baseCDNUrl + "imgs/pic4.webp"}
                    effect="blur"
                    placeholderSrc={baseCDNUrl + "imgs/thumb/pic4.webp"}
                    wrapperClassName="w-full object-cover h-full object-center block"
                    data-fancybox="दशरथ जी- कौशल्या , प्रधानमंत्री सुमंतनामा"
                    data-src={baseCDNUrl + "imgs/pic4.webp"}
                    data-caption="दशरथ जी- कौशल्या , प्रधानमंत्री सुमंतनामा"
                    data-download-src={baseCDNUrl + "imgs/pic4.webp"}
                  />
                </a>
                {/* <div className="absolute h-full w-full bg-[rgba(0,0,0,0.4)] text-white hidden">
                  <h1 className="opacity-100 text-2xl absolute top-1/2 left-28">
                    30 More...
                  </h1>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default PhotoGallery;
