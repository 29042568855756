import React from "react";

function BharatMataVandana() {
  return (
    <section className="bg-pink-50 px-5 py-16 mx-auto">
      <div className="flex flex-col text-center w-full mb-10 md:mb-20">
        <h1 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          भारत माता की वंदना
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px] mb-4">
          लगभग दो दर्जन हस्तलिखित पांडुलिपियों में लिपिबद्ध इस रामलीला की समस्त
          विषयवस्तु निहित है।
        </p>
      </div>
      <div className="relative md:grid md:grid-cols-2 my-[3%] px-4  md:px-32">
        <div className="h-full">kjhkj</div>
        <div className="">
          <h1 className="text-2xl md:text-3xl text-blue-900 font-medium mb-4">
            रामलीला मैं अखाडा क्यों बांधा जाता है
          </h1>
          <p className="text-gray-600 text-justify text-lg mb-8">
            लगभग 4000 जनसंख्या वाले पाटौन्दा गांव कोटा से 65 km की दूरी पर चम्बल
            की सहायक नदी कालीसिंध के किनारे पर ऊबड़ खाबड़ चट्टानों वाली जमीन पर
            स्थित है। आज से लगभग 160 वर्ष पूर्व एक दाधिच ब्राम्हण गुरु गणपतलाल
            जी दाधिच ने इस सांगीतिक रामलीला को प्रारंभ किया था। जिसे उन्होनें
            वाल्मीकि रामायण व तुलसीकृत रामचरिमानस के आधार पर लिखा था। यह रामलीला
            राजस्थानी भाषा की हाड़ौती उपभाषा में लिखी गई है। हाड़ौती पर ऊंचे स्वर
            वाली प्राचीन डिंगल भाषा का प्रभाव है। लगभग दो दर्जन हस्तलिखित
            पांडुलिपियों में लिपिबद्ध इस रामलीला की समस्त विषयवस्तु निहित है।
            संगीत इस गायन रामलीला का महत्वपूर्ण पक्ष है। लौक शैली ढाई कड़ी में
            लिखी गई चौपाइयां प्रमुख खड़ी बोली और रैला तर्ज में संगीतबद्ध किया गया
            है। यह रामलीला चैत्र शुक्ल पक्ष की पंचमी से वैशाख पंचमी तक पन्द्रह
            दिनों तक आयोजित की जाती है। गांव के सामान्य नागरिक ही इसमें गाने,
            बजाने, और अभिनय का कार्य करते है। यह कलाकर गांव के ही कृषक वर्ग के
            सामान्य नागरिक है, जो बिना किसी वेतन के भगवान के प्रति श्रद्धाभाव से
            अपना समय देते है। और इस रामलीला में अभिनय करते है। यह कलाकार कोई
            पेशेवर अभिनेता नही होते, सब अपनी मेहनत और अभ्यास तथा पूर्व कलाकारों
            से प्राप्त प्रशिक्षण से ही अभिनय करते है। पहले गायन के साथ संगीत के
            लिये सारंगी से मिलता जुलता एक वाद्ययंत्र चितारा का प्रयोग करते थे।अब
            चितारा की जगह वायलिन व हारमोनियम का उपयोग किया जाता है। प्रकाश के
            लिये पहले अलसी के तेल की मशालों का प्रयोग होता था। फिर बाद में
            मिट्टी के तेल के पेट्रोमैक्स उपयोग में लाने लगे, आजकल अब बिजली का
            प्रयोग होने लगा है। यह रामलीला गांव के अंदर बने लगभग 11 वी शताब्दी
            के प्राचीन लक्ष्मीनाथ जी के मंदिर के सामने खुले प्रांगण में होती है,
            अभिनय स्थल पर पक्की छत वाला रंगमंच बनाया गया है। जिसमें एक स्थायी
            पक्का सिंहासन, दरबार, वन, महल इत्यादि दिखाने के लिए परदों का प्रयोग
            किया गया है। पन्द्रह दिनों तक चलने वाली इस रामलीला में तीन सवारियां
            निकाली जाती है, इसमें रामलीला के पहले दिन श्रीगणेश जी को लीला में
            आमंत्रित करने के लिए, लीला के चौथे दिन अर्थात स्वयंवर वाले दिन
            रामचंद्र जी की बारात और लीला के छठे दिन अर्थात दशमी वाले दिन तीसरी
            सवारी निकाली जाती है। इस दिन रावण दहन किया जाता है।
          </p>
        </div>
      </div>
    </section>
  );
}

export default BharatMataVandana;
