import { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import ScrollIntoView from "./ScrollIntoView";
import Home from "./pages/Home/Home";
import ContactUs from "./pages/ContactUs/ContactUs";
import History from "./pages/History/History";
import Constitution from "./pages/Constitution/Constitution";
import ManuScript from "./pages/ManuScript/ManuScript";
import Committee from "./pages/Committee/Committee";
import Artists from "./pages/Artists/Artists";
import Programs from "./pages/Programs/Programs";
import NewsAndArticles from "./pages/NewsAndArticles/NewsAndArticles";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Disclamer from "./pages/Disclamer/Disclamer";
import PhotoGallery from "./pages/PhotoGallery/PhotoGallery";
import VideoGallery from "./pages/VideoGallery/VideoGallery";
import Cookies from "./pages/Cookies/Cookies";
import Faqs from "./pages/Faqs/Faqs";
import GaneshVandana from "./pages/ManuScript/GaneshVandana/GaneshVandana";
import AkharaTaan from "./pages/ManuScript/AkharaTaan/AkharaTaan";
import MahotsavHinduCollege from "./pages/MahotsavHinduCollege/MahotsavHinduCollege";
import Ramleela2k24 from "./pages/Ramleela2k24/Ramleela2k24";
import AssetStore from "./pages/AssetStore/AssetStore";
import RamleelaKaManchan from "./pages/RamleelaKaManchan/RamleelaKaManchan";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import SubHeader from "./components/SubHeader/SubHeader";
import Modal from "./components/Modal/Modal";
import InquiryForRamleela from "./components/InquiryForRamleela/InquiryForRamleela";
import BharatMataVandana from "./pages/ManuScript/BharatMataVandana/BharatMataVandana";
import LaxminathVandana from "./pages/ManuScript/LaxminathVandana/LaxminathVandana";
import DhanrajBheel from "./pages/DhanrajBheel/DhanrajBheel";

function App() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {}, []);

  return (
    <Router>
      <ScrollIntoView>
        <SubHeader />
        <Header openModal={openModal} />
        <Modal isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/ramleela-ka-manchan" component={RamleelaKaManchan} />
          <Route path="/patoonda-ramleela-2024" component={Ramleela2k24} />
          <Route
            path="/125-years-celebration-mahotsav-hindu-college"
            component={MahotsavHinduCollege}
          />
          <Route path="/ramleela-2k24">
            <Redirect to="/patoonda-ramleela-2024" />
          </Route>
          <Route path="/history-of-ramleela" component={History} />
          <Route path="/constitution" component={Constitution} />
          <Route path="/assetstore" component={AssetStore} />
          <Route path="/committee" component={Committee} />
          <Route path="/artists" component={Artists} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/programs" component={Programs} />
          <Route path="/photo-gallery" component={PhotoGallery} />
          <Route path="/video-gallery" component={VideoGallery} />
          <Route path="/news-and-articles" component={NewsAndArticles} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/disclamer" component={Disclamer} />
          <Route path="/cookies" component={Cookies} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/manuscript" exact component={ManuScript} />
          <Route path="/manuscript/ganesh-vandana" component={GaneshVandana} />
          <Route path="/manuscript/akhara-taan" component={AkharaTaan} />
          <Route path="/dhanraj-bheel" component={DhanrajBheel} />
          <Route
            path="/manuscript/bharat-mata-ki-vandana"
            component={BharatMataVandana}
          />
          <Route
            path="/manuscript/laxminath-vandana"
            component={LaxminathVandana}
          />
        </Switch>
        <div className="hidden md:block">
          <SocialMedia />
          {/* <CookieConsentBox /> */}
        </div>
        <InquiryForRamleela />
        <Footer openModal={openModal} />
      </ScrollIntoView>
    </Router>
  );
}

export default App;
