import React, { useEffect } from "react";
import "./TagScroller.css"; // Import CSS file

function TagScroller() {
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    if (mediaQuery && !mediaQuery.matches) {
      const tagScroller = document.querySelector(".tag-scroller");

      function addScrolling() {
        tagScroller.classList.add("scrolling");
        const tagList = document.querySelector(".tag-list");
        const scrollContent = Array.from(tagList.children);
        scrollContent.forEach((listItem) => {
          const clonedItem = listItem.cloneNode(true);
          clonedItem.setAttribute("aria-hidden", true);
          tagList.appendChild(clonedItem);
        });
        tagList.style.setProperty(
          "--duration",
          tagList.clientWidth / 150 + "s"
        );
      }
      addScrolling();
    }
  }, []);

  return (
    <div className="tag-scrollers py-2">
      <div className="tag-scroller">
        <ul className="tag-list">
          <a href="/manuscript/akhara-taan">
            <li>अखाडा तान</li>
          </a>
          <a href="/manuscript/laxminath-vandana">
            <li>लक्ष्मीनाथ जी की वंदना</li>
          </a>
          <a href="/patoonda-ramleela-2024">
            <li>पाटोन्दा रामलीला 2024</li>
          </a>
          <a href="/manuscript/ganesh-vandana">
            <li>गणेश वंदना</li>
          </a>
          <a href="/manuscript/bharat-mata-ki-vandana">
            <li>भारत माता की वंदना</li>
          </a>
          <li>हिन्दू कॉलेज 125th वर्षगाँठ समारोह</li>
          <li>दसलानां में रामलीला मंचन</li>
          <li>तिसाया में रामलीला मंचन</li>
          <li>पाटोन्दा रामलीला 2023</li>
          <li>पाटोन्दा रामलीला 2022</li>
          <li>कवि प्रेमजी प्रेम</li>
          <li>डॉ. इन्दुजा अवस्थी</li>
          <li>डॉ. अनुकृति शर्मा</li>
          <li>अयोध्या में रामलीला मंचन</li>
          <li>चित्रकूट में रामलीला मंचन</li>
          <li>भोपाल में रामलीला मंचन</li>
          <li>कोटा में रामलीला मंचन</li>
          <a href="/dhanraj-bheel">
            <li>धनराज जी भील</li>
          </a>
          <li>आकाशवाणी कोटा में रामलीला</li>
          <li>श्री गुरु गणपतलाल जी दाधिच</li>
          <li>श्री गुरु राधाकृष्ण जी दाधिच</li>
          {/* <li>Front-end</li>
          <li>Product Design</li>
          <li>Omnichannel Strategy</li>
          <li>Offline / Print</li>
          <li>Audio</li>
          <li>Media Strategy</li>
          <li>POS</li>
          <li>SoMe</li>
          <li>CRM</li>
          <li>Motion Graphics</li>
          <li>OOH / DOOH</li>
          <li>Film Production</li>
          <li>Campaign Development</li>
          <li>Brand Strategy</li> */}
        </ul>
      </div>
    </div>
  );
}

export default TagScroller;
