import React from "react";

function AkharaTaan() {
  return (
    <>
      <h2 className="text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900 rozha-one-regular text-center">
        अखाड़ा तान
      </h2>

      <p class="leading-relaxed mb-6 text-center font-semibold">
        प्रथम गुरु आद अखाड़ा आर लगादयूं चारों खूंटां कार ॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        प्रथम अखड़ा बीच चोक मं बराज श्री रघुनाथ शम्भु की कर नांक दो चीर ॥<br />
        राम र लक्ष्मण भरत शत्रुघन टाढे हनुमत वीर ॥१॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        बावन भेरु चौसठ योगिनी आवो अखड़ा मांही दाव दुश्मन का लगता नांही ॥<br />
        जंतर मंतर नजर मुठ या चले किसी की नांही ॥२॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        एक खूंट प दुर्गा बिराज दूजा भवानी माई खूंट तीजां प शारदा माई ॥<br />
        ईर अखड़ा मं कर बखड़ो जीन खाव कालक्या माई ॥ ३ ॥
      </p>

      <p class="leading-relaxed mb-6 text-center">
        धरती बाँध आकाश बाँधू बाँधू चौदह लोक बाँध कर इनको करूं मजंबूत्त ॥<br />
        ईर अखड़ा माहिन नहीं आव डाकन भूत ॥ ४ ॥
      </p>
    </>
  );
}

export default AkharaTaan;
