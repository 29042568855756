import React, { useEffect, useState } from "react";
import { ramleela2k24Programs } from "../../core/globalProvider";
import { baseCDNUrl } from "../../utils/endpoints";
import PictureFrame from "../../components/PictureFrame/PictureFrame";
import RibbonTitle from "../../components/RibbonTitle/RibbonTitle";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./Ramleela2k24.css";
import AnimatedUnderline from "../../components/AnimatedUnderline/AnimatedUnderline";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import classNames from "classnames";

function Ramleela2k24() {
  const [selectedType, setSelectedType] = useState(1);

  const youtubeVideos = [
    {
      youtubeVideoId: "pjDcKkIlnnM",
      title:
        "अहिरावण वध, नारंतक वध, रावण वध, राज्य अभिषेक, महाआरती, रामलीला समापन | ढाई कड़ी की रामलीला | पाटोन्दा रामलीला 2024 | Day #10",
      release: "28 अप्रैल 2024 (रविवार) [वैशाख कृष्ण पंचमी]",
    },
    {
      youtubeVideoId: "8JZeBt58Px4",
      title:
        "लक्ष्मण शक्ति, कुम्भकरण वध, मेघनाथ वध, सुलोचना सती | ढाई कड़ी की रामलीला | पाटोन्दा रामलीला 2024 | Day #9",
      release: "27 अप्रैल 2024 (शनिवार) [वैशाख कृष्ण चतुर्थी]",
    },
    {
      youtubeVideoId: "4-qD5psiE4c",
      title:
        "लंका की बढ़ाई, विभीषण शरणागत, लंका की चढाई | पाटोन्दा रामलीला 2024 | Day #8 | Part 2",
      release: "26 अप्रैल 2024 (शुक्रवार) [वैशाख कृष्ण तृतीया]",
    },
    {
      youtubeVideoId: "--Pq11_Hczk",
      title:
        "लंका की बढ़ाई, विभीषण शरणागत, लंका की चढाई| पाटोन्दा रामलीला 2024 | Day #8 | Part 1",
      release: "26 अप्रैल 2024 (शुक्रवार) [वैशाख कृष्ण तृतीया]",
    },
    {
      youtubeVideoId: "nF58gu-UvwM",
      title:
        "हनुमान - सीता संवाद, अशोक वाटिका उजाड़ना, अक्षय कुमार वध, लंका दहन | पाटोन्दा रामलीला 2024 | Day #7",
      release: "25 अप्रैल 2024 (गुरुवार) [वैशाख कृष्ण द्वितीया]",
    },
    {
      youtubeVideoId: "zYh6tLakkLs",
      title:
        "सुग्रीव राज्याभिषेक,सुग्रीव द्वारा सीता की खोज,हनुमान-लंकिनी संवाद | पाटोन्दा रामलीला 2024 | Day #6",
      release: "24 अप्रैल 2024 (बुधवार) [वैशाख कृष्ण प्रतिपदा]",
    },
    {
      youtubeVideoId: "l9Gu-IKmXLo",
      title:
        "बाली द्वारा दुंदुभी वध, शबरी प्रसंग, राम - सुग्रीव मित्रता, बाली वध | पाटोन्दा रामलीला 2024 | Day #5",
      release: "23 अप्रैल 2024 (मंगलवार) [चैत्र शुक्ल पूर्णिमा]",
    },
    {
      youtubeVideoId: "iJ4lusTkxxQ",
      title:
        "सीता हरण,जटायु उद्धार | पाटोन्दा रामलीला 2024 | ढाई कड़ी की रामलीला पाटोन्दा | Day #4 | Part 2",
      release: "22 अप्रैल 2024 (सोमवार) [चैत्र शुक्ल चतुर्दशी]",
    },
    {
      youtubeVideoId: "DxD6GrAD9hw",
      title:
        "सीता हरण,जटायु उद्धार | पाटोन्दा रामलीला 2024 | ढाई कड़ी की रामलीला पाटोन्दा | Day #4 | Part 1",
      release: "22 अप्रैल 2024 (सोमवार) [चैत्र शुक्ल चतुर्दशी]",
    },
    {
      youtubeVideoId: "ni-A_fRYJxk",
      title: "राज तिलक की तैयारी, राम वनवास | पाटोन्दा रामलीला 2024 | Day #2",
      release: "20 अप्रैल 2024 (शनिवार) [चैत्र शुक्ल द्वादशी]",
    },
    {
      youtubeVideoId: "5_rPgmO9dio",
      title:
        "सीता स्वयंवर, लक्ष्मण - परशुराम संवाद | पाटोन्दा रामलीला 2024 | Day #1",
      release: "19 अप्रैल 2024 (शुक्रवार) [चैत्र शुक्ल एकादशी]",
    },
  ];

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        display: {
          right: [
            "iterateZoom",
            "slideshow",
            "download",
            "fullscreen",
            "thumbs",
            "close",
          ],
        },
      },
    });
  }, []);

  return (
    <section className="bg-pink-50 px-5 py-16 mx-auto">
      <div className="flex flex-col text-center w-full mb-8 md:mb-14">
        <h1 className="kalam-bold text-3xl md:text-5xl font-medium title-font mb-1 md:mb-4 text-blue-900">
          पाटोन्दा रामलीला <AnimatedUnderline>#2024</AnimatedUnderline>
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px] mb-4 md:mb-20">
          ढाई कड़ी की रामलीला का मंचन चैत्र शुक्ल पंचमी से वैशाख पंचमी दिनांक :
          13 अप्रैल से 28 अप्रैल तक होने जा रहा है।
        </p>
      </div>
      <div className="md:grid md:grid-cols-2 my-[3%] px-4 md:px-32">
        <div className="flex justify-center">
          <div className="w-full h-full px-10 mb-12 md:mt-2">
            <LazyLoadImage
              className="cursor-pointer"
              src={
                baseCDNUrl +
                "ramleela/2k24/patoonda/Ramleela-2024-Pamphlet.webp"
              }
              alt="पाटोन्दा रामलीला #2024 पैम्फलेट"
              data-fancybox="patoonda-ramleela-2024-pamphlet"
              data-src={
                baseCDNUrl +
                "ramleela/2k24/patoonda/Ramleela-2024-Pamphlet.webp"
              }
              data-caption="पाटोन्दा रामलीला #2024 पैम्फलेट"
              data-download-src={
                baseCDNUrl +
                "ramleela/2k24/patoonda/Ramleela-2024-Pamphlet.webp"
              }
              effect="blur"
              placeholderSrc={
                baseCDNUrl +
                "ramleela/2k24/hindu-college-delhi/thumb/ramleela-pamphlet.jpg"
              }
              wrapperClassName="w-full object-cover h-full object-center block"
            />
          </div>
        </div>
        <div className="">
          <p className="text-gray-600 text-justify text-lg mb-5">
            <b>पाटोन्दा।</b> पाटोन्दा की ऐतिहासिक 160 साल से लगातार मंचित होती आ
            रही अंतर्राष्ट्रीय ढाई कड़ी की रामलीला का आयोजन चैत्र शुक्ल पंचमी से
            वैशाख पंचमी अर्थात दिनांक : 13 अप्रैल से 28 अप्रैल तक होने जा रहा
            है। यह रामलीला पन्द्रह दिनों तक आयोजित होती है।
          </p>
          <p className="text-gray-600 text-justify text-lg mb-5">
            इस रामलीला में तीन सवारियां निकाली जाती है। इसमें रामलीला के पहले
            दिन (13 अप्रैल 2024, शनिवार [चैत्र शुक्ल पंचमी]) को भगवान श्री
            लक्ष्मीनाथ जी के मंदिर से गणेश जी की छतरी तक लक्ष्मीनाथ जी के विमान
            के साथ सभी देवी देवता श्री गणेश जी को निमंत्रण पत्रिका देने जाते है
            और रामलीला में पधारने का आग्रह करते है।
          </p>
          <p className="text-gray-600 text-justify text-lg mb-5">
            रामलीला के चौथे दिन (16 अप्रैल 2024, मंगलवार [चैत्र शुक्ल अष्टमी])
            दूसरी सवारी निकाली जाती है। जिसमे की श्री राम जी की बारात भगवान श्री
            लक्ष्मीनाथ जी के प्रांगण से (रामलीला रंगमंच से) चलकर चलकर जानकी राय
            जी के मन्दिर (जनकपुरी) (मठ) तक जाती है ये परम्परा जो कि सालो से चली
            आ रही है ... तत्पश्चात श्री राम, लक्ष्मण, भरत एवम् शत्रुघ्न जी का
            विधि - विधान से विवाह संपन्न होता है।
          </p>
          <p className="text-gray-600 text-justify text-lg mb-5">
            रामलीला के छठे दिन (18 अप्रैल 2024, गुरुवार [चैत्र शुक्ल दशमी])
            तीसरी सवारी निकाली जाती है। जिसमे की श्री राम जी की सेना भगवान श्री
            लक्ष्मीनाथ जी के प्रांगण से (रामलीला रंगमंच से) चलकर लंका तक जाती है
            ये परम्परा जो कि सालो से चली आ रही है ... तत्पश्चात श्री राम द्वारा
            रावण दहन किया जाता है।
          </p>
          <p className="text-gray-600 text-justify text-lg mb-5">
            चैत्र शुक्ल पंचमी से दशमी तक रामलीला लीला का मंचन मुख्य रूप से नही
            होता है | अतः भगवान राम के जन्म के पश्चात यानी कि एकादशी से मुख्य
            रूप से रामलीला का मंचन होता है।
          </p>
        </div>
      </div>

      <div className="container px-4 md:px-36 py-10 md:py-14 mx-auto">
        <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
          <div className="flex justify-center w-full mb-4">
            <PictureFrame>
              <img
                src={baseCDNUrl + "persons/pic24.webp"}
                alt={"धनराज जी भील"}
                className="w-48 md:w-64 h-48 sm:mb-0 mb-4"
              />
            </PictureFrame>
          </div>
          <div className="flex-grow sm:pl-8">
            <h2 className="title-font font-medium text-2xl text-gray-900 mb-1">
              स्व. धनराज जी भील
            </h2>
            <h3 className="text-gray-500 mb-2">कलाकार</h3>
            <p className="mb-4 text-justify">
              ढाई कड़ी की रामलीला ने एक शानदार कलाकार को खो दिया। ढाई कड़ी की
              रामलीला के कर्मठ कर्तव्य निष्ठ प्रिय कलाकार सभी किरदारों (शबरी,
              केकेय, शूर्पणखा, मालन, लंकिनी, सुरसा, निषादराज, सखी) को सजीवता से
              मंचन करने वाले निस्वार्थ सेवाभावी से रामलीला के लिए काम करने वाले
              श्री धनराज जी भील का इस तरह इस उम्र में चले जाना, परिवार, समाज
              साथियों के लिए अपूरणीय क्षति, रामलीला के प्रति समर्पित, बहुत दुखद
              है। परलोक गमन पर भावपूर्ण श्रंद्धाजलि ईश्वर उनकी आत्मा को शान्ति
              प्रदान करे 🙏🙏।
            </p>
          </div>
        </div>
      </div>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-14 mx-auto">
          <div className="text-center mb-16 md:mb-24">
            <div className="mb-12">
              <RibbonTitle>
                <h1 className="text-2xl md:text-3xl font-medium  text-white text-center">
                  रामलीला के कार्यक्रम
                </h1>
              </RibbonTitle>
            </div>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
              चैत्र शुक्ल पंचमी से दशमी तक रामलीला लीला का मंचन मुख्य रूप से नही
              होता है | अतः भगवान राम के जन्म के पश्चात यानी कि एकादशी से मुख्य
              रूप से रामलीला का मंचन होता है।
            </p>
          </div>
          <div className="flex flex-wrap justify-center">
            {ramleela2k24Programs.map((program) => {
              return (
                <div
                  className="md:p-4 md:w-1/3 w-full mb-12 md:mb-8"
                  key={program._id}
                >
                  <div
                    className={classNames(
                      "relative h-full border border-orange-500 rounded-lg p-8",
                      {
                        "bg-orange-100":
                          ramleela2k24Programs.indexOf(program) ==
                          ramleela2k24Programs.length - 1,
                      }
                    )}
                  >
                    <div class="ramleelaProgram">
                      {program.date} {program.month} 2024, {program.day} [
                      {program.tithi}]
                    </div>
                    <p
                      className="leading-relaxed text-center"
                      dangerouslySetInnerHTML={{ __html: program.title }}
                    ></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-14 mx-auto">
          <RibbonTitle classes="mb-16">
            <h1 className="text-2xl md:text-3xl font-medium title-font text-center">
              रामलीला के कलाकार
            </h1>
          </RibbonTitle>
          <p className="text-center text-lg md:mx-[275px]">
            बृजसुंदर केवट, रामप्रसाद सेन, लोकेश दाधीच, हंसराज मालव, रामेश्वर
            कटारिया, नाथूलाल कारपेंटर, चोथमल गोचर, सुशिल पांचाल, वेद प्रकाश
            शर्मा, मुकुट गुर्जर, रामसिंह केवट, संदीप शर्मा, रामप्रसाद मेहरा,
            देवीशंकर मालव, दीनबंधु केवट, हेमंत सेन, कृष्ण मुरारी मालव, अमरलाल
            मालव, देवकी नंदन शर्मा, इन्द्रजीत केवट, अमृतलाल रावल, सज्जन रावल,
            कपिल रावल, श्रवणलाल प्रजापति,भंवरलाल नागर, धर्मवीर कटारिया, रविन्द्र
            नागर, चेतन नागर, भारत नागर, अनिल नागर, विनोद मालव, मोहित वैष्णव,
            हेमंत नंदवाना, गौरव नंदवाना, महेश केवट, नरेन्र्द केवट, रामभरोस केवट
          </p>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container md:px-5 py-20 mx-auto">
          <div className="text-center mb-16">
            <div className="mb-12">
              <RibbonTitle>
                <h1 className="text-2xl md:text-3xl font-medium  text-white text-center">
                  फ़ोटो और वीडियो
                </h1>
              </RibbonTitle>
            </div>
            {/* <p class="lg:w-2/3 mx-auto leading-relaxed text-[14px] md:text-[18px]">
              चैत्र शुक्ल पंचमी से दशमी तक रामलीला लीला का मंचन मुख्य रूप से नही
              होता है | अतः भगवान राम के जन्म के पश्चात यानी कि एकादशी से मुख्य
              रूप से रामलीला का मंचन होता है।
            </p> */}
            <div className="flex flex-col text-center hidden">
              <div className="tabs flex mx-2 md:mx-auto border-2 border-[#ff5528] rounded overflow-x-scroll overflow-y-hidden mt-6 scrollbar-none">
                {["फ़ोटो", "आगामी लाइव वीडियो"].map((type) => {
                  return (
                    <>
                      {selectedType ===
                      ["फ़ोटो", "आगामी लाइव वीडियो"].indexOf(type) ? (
                        <button className="py-2 px-4 bg-[#ff5528] text-white focus:outline-none whitespace-nowrap">
                          {type}
                        </button>
                      ) : (
                        <button
                          className="py-2 px-4 whitespace-nowrap"
                          onClick={() => {
                            setSelectedType(
                              ["फ़ोटो", "आगामी लाइव वीडियो"].indexOf(type)
                            );
                          }}
                        >
                          {type}
                        </button>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          {selectedType == 1 && (
            <div className="flex flex-wrap justify-center w-full">
              {youtubeVideos.map((video, idx) => {
                return (
                  <div className="py-5 md:p-5" key={idx}>
                    <div
                      className="col-span-1 bg-white px-3 py-3 rounded-lg shadow-lg shadow-slate-300"
                      key={idx}
                    >
                      <iframe
                        className="w-full rounded-lg h-[225px] md:h-[250px]"
                        src={
                          "https://www.youtube.com/embed/" +
                          video["youtubeVideoId"]
                        }
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                      <div className="mt-3 px-1">
                        <h1 className="font-bold text-black text-lg">
                          {video["title"]}
                        </h1>
                        <p className="text-sm font-medium text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis">
                          {video["release"]}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </section>
  );
}

export default Ramleela2k24;
